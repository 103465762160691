import { 
    ACTUALIZAR_USUARIO_P,
    VERIFICAR_USUARIO,
    ACTUALIZAR_ESTATE_USUARIO,
    MOSTRAR_USUARIO,
    ELIMINAR_USUARIO,
    REMOVER_ESTADO_USUARIO,
    EDITAR_USUARIO,
    VERIFICAR_USUARIO_ERROR,
    VERIFICAR_USUARIO_EXITO,
    RESTABLECER_ERROR,
    GUARDAR_PUNTAJE,
    ACTUALIZAR_TAB,
    authTypes,
    OBTENERN_NOTIFICACIONES
} from '../actions/types';

//Cada reducer tiene su propio state

const initialState = {
    usuario: [],
    detalleNivel: [],
    inediRank:0,
    notificaciones:[],
    currentTab:'modulos',
    estado: null,
    logueado: null,
    loading: null,
    error: null
}

export default function(state = initialState, action){

    switch (action.type) {
        case MOSTRAR_USUARIO:
            return{
                ...state,
                usuario: action.payload.respuesta.usuario,
                detalleNivel: action.payload.respuesta.detalleNivel,
                estado: action.payload.estado,
                logueado: true
            }
        case VERIFICAR_USUARIO:
            return{
                ...state,
                loading: true,
                error: null,
                logueado: null,
            }
        case VERIFICAR_USUARIO_EXITO:
            return {
                ...state,
                usuario: action.payload.usuario,
                detalleNivel: action.payload.detalleNivel,
                loading: null,
                logueado: true
            }
        case VERIFICAR_USUARIO_ERROR:
        case authTypes.DATOS_NECESARIOS:
            return {
                ...state,
                loading: null,
                error: action.payload,
                logueado: false
            }
        case RESTABLECER_ERROR:
            return {
                ...state,
                error: null
            }
        case ELIMINAR_USUARIO: 
        return{
            ...state,
            usuario: action.payload,
            detalleNivel: action.payload,
            estado: null
        }
        case REMOVER_ESTADO_USUARIO:
            return{
                ...state,
                estado: null
            }
        case EDITAR_USUARIO:
            return{
                ...state,
                usuario: action.payload
            }
        case GUARDAR_PUNTAJE:
            return{
                ...state,
                inediRank: action.payload
            }
        case OBTENERN_NOTIFICACIONES:
            return{
                ...state,
                notificaciones: action.payload
            }
        case ACTUALIZAR_TAB:
            return{
                ...state,
                currentTab: action.payload
            }
        case ACTUALIZAR_ESTATE_USUARIO:
            return{
                ...state,
                usuario: action.payload
            }
        case ACTUALIZAR_USUARIO_P: 
            return{
                ...state,
                usuario: action.payload
            }

        case authTypes.REMOVER_TODO:
            return {
                ...state,
                usuario: [],
                detalleNivel: [],
                estado: null,
                logueado: false
            }
        default:
            return state;
    }
}