import { Avatar } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import React from 'react';
import { Badge, Card } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { showDetalle } from '../../actions/alertaActions';
import { postularEmpleo } from '../../actions/cursosActions';
import { alertaTypes } from '../../actions/types';
import ReadMore from '../../components/ReadMore/ReadMore';
import * as moment from 'moment'
const Empleo = ({datos}) => {
    const dispatch=useDispatch();
    const usuario = useSelector(state=> state.usuario.usuario);
    const handleDetalle = (id) =>{
        dispatch({
            type:alertaTypes.SET_ID_EMPLEO,
            payload: id
        })
        dispatch(showDetalle())
    }
    return (
    <div className="col-md-4 p-1">
       <Card className="p-2">
           <div className="p-1">
          {  moment().diff(moment(datos.fecha_publicacion),'days')<9 ?  <span className='badge bg-success' style={{color:'#fff'}}>Nuevo!</span>:null}
             <div className="d-flex flex-column align-items-left p-1">
                 <img src="/images/bolsa-item.png" alt="" className="img img-fluid" style={{maxWidth:'70px'}}/>
                 
             </div>
             <h5 className="p-1"> {datos.nombre_puesto} </h5>
             <p className="p-1"> {datos.empresa} </p>
             
             <div className="d-flex flex-row flex-wrap p-1">
                <Badge className="bg-primary m-1 text-white p-2">
                 {datos.tiempo_empleo}
                </Badge>
                <Badge className="bg-primary m-1 text-white p-2">
                   {datos.experiencia} año/s Exp.
                </Badge>
                <Badge className="bg-primary m-1 text-white p-2">
                    {datos.categoria}
                </Badge>
             </div>

             <div className="row mt-2 mb-2 d-flex">
           
               { 
                datos.postulaciones.length>0 ?  <> <div className="col-12 col-md-4 d-flex"> 
                {
                    datos.postulaciones.map((discente, index) => {
                    if(index > 2) return null
                    
                    if(discente.img_usuario === null) return <Avatar
                        key={index}
                        alt=""
                        src='images/user.png'
                        style={{marginLeft:'-9px'}}
                        />
                    
                            return (
                            <Avatar
                                key={index}
                                alt=""
                                src={`${process.env.REACT_APP_API_URL}/${discente.img_usuario}`}
                                style={{marginLeft:'-9px'}}
                                />
                            )
                        })
                        
                       
                    }  { datos.postulaciones.length>2?<Avatar style={{marginLeft:'-9px', fontSize:'11px'}}> +{datos.postulaciones.length-3}</Avatar> :''  }   </div>
            <div className="col-12 col-md-8 d-flex align-items-center">  
               <p> {datos.postulaciones.length} amigos postularon</p>
            </div> </>:<div className="col-12 d-flex align-items-center">  <p className="text-center" sytle={{paddingLeft:'20px'}}><strong>Sé el primero en postular!</strong></p>  </div> 
                }
                
          
         
            </div>

             <div className="d-flex flex-row p-1">
                <button className="btn btn-primary m-1" style={{width:'50%'}} onClick={()=> {dispatch(postularEmpleo({id_usuario:usuario.id_usuario, id_trabajo:datos.id_trabajo}))
                   window.open(datos.link_contacto, '_blank').focus(); }} >Postular</button>
                <button className="btn btn-secondary m-1" style={{width:'50%'}} onClick={()=>handleDetalle(datos.id_trabajo)}>Detalles</button>
             </div>

           </div>
       </Card>
       </div>
    );
};

export default Empleo;