import React from 'react';
import Respuesta from './Respuesta';

const Respuestas = ({respuestas, index , id_video, id_sesion}) => {
    return (
        <>
            <p>
            <button className="btn btn-primary" type="button" data-toggle="collapse" data-target={`#comentario-${index}`} aria-expanded="false" aria-controls={`comentario-${index}`}>
             ({respuestas.length}) Respuestas
            </button>
            </p>
            <div className="collapse col-12" id={`comentario-${index}`}  style={{marginTop:'10px'}}>
            <div className="card card-body">
            {
                respuestas.length>0 ? respuestas.map((respuesta, i)=>(
                    <Respuesta key={i} respuesta={respuesta} id_video={id_video} id_sesion={id_sesion} />
                  )) : 'No hay respuestas recientes...'
            }
            </div>
            </div>
        </>
    );
};

export default Respuestas;