import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom'; 
import PrivateRoute from './PrivateRoute';
import { getJWT } from '../components/Helpers/FunctionsHelpers';
import Login from '../pages/Login';
import Page404 from '../pages/Page404';
import DashboardRouter from './DashboardRouter';

import { useSelector, useDispatch } from 'react-redux';
import { validacionUsuarioToken } from '../actions/usuarioActions';

const AppRouter = () => {

  const { usuario } = useSelector(state => state.usuario);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getJWT() && Object.keys(usuario).length === 0) {
      dispatch(validacionUsuarioToken());
    }
  }, []);
  return ( 
      <Switch>
        <Route exact path='/login' component={Login} />
        <PrivateRoute path='/' component={DashboardRouter} />
        <Route path="*" component={Page404} />
      </Switch>
   );
}
 
export default AppRouter;
