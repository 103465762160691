import React from 'react';
import './TitlePagina.scss';

const TitlePagina = ({ children, clases }) => {
  return ( 
    <div className={`TitlePagina ${clases}`}>
      <h4>
        {children}
      </h4>
    </div>
  );
}
 
export default TitlePagina;