import React from 'react';
import Layout from '../../components/Layout/Layout';
import TitlePagina from '../../components/TitlePagina';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBuilding, faGraduationCap, faThList } from '@fortawesome/free-solid-svg-icons'; 
import { Col, Row } from 'react-bootstrap';
import { Accordion, Card } from 'react-bootstrap';
import FloatingWhatsAppButton from "../../components/WhatsappButton/FloatingWhatsAppButton"

import './InformacionInstitucional.scss';
import CardContador from '../../components/Cards/CardContador';

const InformacionInstitucional = () => {

  const dataContadores = [
    { 
      numero: 9,
      titulo: "Años de experiencia",
      icono: faGraduationCap,
      color: "primary"
    },
    {
      numero: 45000,
      titulo: "Estudiantes capacitados",
      icono: faUser,
      color: "success",
    },
    {
      numero: 150,
      titulo: "Instituciones capacitadas",
      icono: faBuilding,
      color: "info",
    }
];

  return ( 
    <Layout>
      <div className="container InformacionInstitucional mt-5">
        <TitlePagina clases='primary'>
          información institucional
        </TitlePagina>
        <div className="InformacionInstitucional__body">
          <Row>
            {dataContadores.map((item, index) => (
              <Col sm={4} key={index}>
                <CardContador
                  {...item}
                />
              </Col>
            ))}
          </Row>
          <div className="frase">
            <blockquote>
              <p>
                <strong>El secreto de la felicidad </strong>
                no está en hacer siempre lo que se quiere,
                <strong> sino en querer </strong>
                siempre lo que se hace.
              </p>
              <cite>León Tolstoi.</cite>
            </blockquote>
          </div>
          <Accordion defaultActiveKey="0">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <FontAwesomeIcon icon={faThList} /> Sobre nosotros
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                <p>
                  INEDI Posgrados es un equipo de especialistas en capacitación y
                  consultoría que plantea las mejores propuestas para el
                  cumplimiento de tus metas de manera integral. Promovemos estudios
                  a nivel posgrado con docentes nivel élite en el Perú así como
                  docentes internacionales invitados de las mejores universidades
                  del mundo. Con tan sólo 9 años de funcionamiento hemos capacitado
                  a más de 45 mil estudiantes en sus distintos programas. Además
                  contamos con más de 150 instituciones capacitadas y consultadas en
                  todo el Perú mediante nuestros programas in house y de
                  consultoría, convirtiéndonos así en una entidad con experiencia en
                  la educación e investigación de las últimas reformas en el mercado
                  actual.
                </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                <FontAwesomeIcon icon={faThList} /> Misión
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    Promover el desarrollo y la modernización en la gestión pública y
                    privada, impulsando la investigación y el estudio como motores
                    fundamentales en el camino hacia la excelencia.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                <FontAwesomeIcon icon={faThList} /> Visión
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    Ser la plataforma líder, a nivel nacional en capacitación y
                    consultoría mediante una metodología enfocada en resultados y a la
                    medida de las necesidades profesionales y del mercado.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="3">
                <FontAwesomeIcon icon={faThList} /> Perfíl
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    <span className='font-weight-bold'>R.U.C.:</span> 20605393901 <br />
                    <span className='font-weight-bold'>Razon social:</span> Corporación TALES S.A.C. <br />
                    <span className='font-weight-bold'>Direccion: </span> Jirón Manuel del Pino N° 351. Edificio los
                              pinos Oficina 1103. Urbanización Sta. Beatriz - 
                          Cercado de Lima. <br/>
                    <span className='font-weight-bold'>Oficina Ica:</span> Calle Bolivar 244 - Ica - Ica - Ica
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
      <FloatingWhatsAppButton />
    </Layout>
  );
}
 
export default InformacionInstitucional;
