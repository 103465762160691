import {
    ruletaTypes
  } from "./types";

import authAxios from "../config/authAxios";
import Swal from "sweetalert2";


export const guardarPremioRuleta = (datos) => async dispatch => {
    console.log(datos);
    try {
     dispatch({
        type: ruletaTypes.OBTENER_CUPON,
    });
    const rpta = await authAxios.post(`/admin/guardarPremioRuleta`,datos)

    console.log(rpta.data.cupon)
    if(rpta.data.cupon){
        dispatch({
            type: ruletaTypes.OBTENER_CUPON_EXITO,
            payload: { cupon: rpta.data.cupon }
        });
    }  
  } catch (error) {
    console.log(error)
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: error.response.data.error,
    });
    dispatch({
      type: ruletaTypes.OBTENER_CUPON_ERROR,
    });
  }
  }