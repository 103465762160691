import React, {useState, useEffect} from 'react'
import SelectText from "../../plugins/SelectText";
import filterFactory, {
  textFilter,
  Comparator
} from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import CategoriasTabla from './CategoriasTabla';
import styled from '@emotion/styled';
import {connect} from 'react-redux';

const Label = styled.label`
  font-size: 11px;
  color: #757575;
  text-transform: uppercase;
  padding-left: 1px
`;
//Redux

const ModalContactos = (props) => {
  //States
  const { defaultSelect, guardarSeleccionTabla, guardarSeleccion, contactos } = props;
  
  const [lista, setLista] = useState([])
  const [seleccionaPestana, setSeleccionPestana] = useState('alumnos')

const products = [
  {
    id: 1,
    name: "Eduardo",
    price: 180,
    button: (
      <button
        type="button"
        onClick={() =>
          guardarSeleccionTabla({ label: "Eduardo", value: "1" })
        }
        className="btn btn-primary btn-sm"
      >
        +
      </button>
    )
  },
  {
    id: 1,
    name: "Melissa",
    price: 99999999999999
  }
];
const columns = [
  {
    dataField: "name",
    text: "Categoria",
    filter: textFilter()
  },

  {
    dataField: "button",
    text: " Ación"
  }
];

const llenarLista = dataBruto => {
  let dataInfo = []

  dataBruto.map(usuario => {
    dataInfo = [...dataInfo,
      {
        name: `${usuario.nombre_usuario} ${usuario.apellido_usuario}`,
        button: (
          <button
            type="button"
            onClick={e =>{
              e.preventDefault()
                guardarSeleccionTabla(
                  { label: `${usuario.nombre_usuario} ${usuario.apellido_usuario}`,
                   value: `${usuario.id_usuario}`,
                   id_receptor: usuario.id_usuario
                   })
              }
            }
            className="btn btn-primary btn-sm"
          >
            +
          </button>
        )
      }
    ]
  })
  setLista(dataInfo)
}

const dataLista = (seleccionPestana) => {
  if(seleccionPestana === 'alumnos'){
    llenarLista(contactos.estudiantes)
  }
  if(seleccionPestana === 'profesores'){
    llenarLista(contactos.docentes)
  }
  if(seleccionPestana === 'admin'){
    llenarLista(contactos.admins)
  }
}

useEffect(() => {

  if((contactos.estudiantes).length > 0){

    dataLista(seleccionaPestana)
  }
}, [seleccionaPestana, contactos])

    return (
      <div
        className="modal fade bd-example-modal-lg contac"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" style={{ minWidth: "1000px" }}>
          <div className="modal-content">
            <div class="modal-header bg-light py-1 text-white">
              <h5 class="text-uppercase modal-title text-default" style={{color: "#000", fontSize:"12px"}}>
                Seleccón de contactos
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="content-body-contacto px-2">
              <div className="row">
                <div className="col-12 mb-4 seleccion">
                  <Label className="mb-0">PARA</Label>
                  <hr className="mt-1 mb-2"/>
                  <SelectText
                    defaultSelect={defaultSelect}
                    guardarSeleccion={guardarSeleccion}
                  />
                </div>
                <div className="col-12 mb-2">
                  <hr />
                  <div class="row">
                    <div className="col-md-9 text-uppercase">
                      <Label>
                        Seleccione Contactos
                      </Label>
                    </div>
                    <div className="col-md-3">
                      <button type="button" className="btn btn-sm btn-primary btn-block">
                      <span className="bg-white rounded-circle mr-1" style={{padding: "0 1px 1px 1px"}}>
                        <i className="fas fa-plus text-primary" style={{fontSize: "9px"}}></i>
                      </span>
                      Seleccionar todos
                      </button>
                    </div>
                  </div>
                  <hr/>
                </div>
                <div className="col-md-5">
                <CategoriasTabla
                  setSeleccionPestana={setSeleccionPestana}
                />
                </div>
                <div className="col-md-7">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

const mapStateToProps = state => ({
  contactos: state.mensajes.contactos
})
 
export default connect(mapStateToProps, {})(ModalContactos);
