import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import './CountDownTime2.css';
import {examenesTypes} from '../../../actions/types'
const CountDownTime = (props) => {
    const {tiempo, quizId} = props;
    const[timerMinutes,setTimerMinutes] = useState('00');
    const[timerSeconds, SetTimerSeconds] = useState('00');

    let interval = useRef();
    const dispatch=useDispatch();
    const empezarContador = (minutos) => {
      
        let finalTime=null;

         // Usamos un identificador único para cada quiz en el localStorage
         const localStorageKey = `TiempoFinal_${quizId}`;
         const tiempoFinalAlmacenado = localStorage.getItem(localStorageKey);
 

        if(tiempoFinalAlmacenado !== undefined && tiempoFinalAlmacenado)
        {
             finalTime = tiempoFinalAlmacenado;
        }
        else
        {   
            finalTime = new Date().getTime()+(minutos*60000);
            localStorage.setItem(localStorageKey, finalTime);
        }

        interval.current = setInterval(() =>{

       
        const now = new Date().getTime();
        const currentTime =  finalTime - now;
    
 
        let mins = Math.floor((currentTime%(1000*60*60))/(1000*60));
        let secs = Math.floor((currentTime%(1000*60))/(1000));

      


        // Si el tiempo ha llegado a 0 o es negativo, detener el contador
        if (currentTime <= 0) {
            setTimerMinutes('00');
            SetTimerSeconds('00');
            clearInterval(interval.current);
            localStorage.removeItem(localStorageKey);
            dispatch({ type: examenesTypes.TIEMPO_EXAMEN_TERMINADO });
        } else {
            // Formatear minutos y segundos para que siempre tengan dos dígitos
            if (mins < 10) mins = '0' + mins;
            if (secs < 10) secs = '0' + secs;
            setTimerMinutes(mins);
            SetTimerSeconds(secs);
        }

        },1000) ;

   
    }

    useEffect(()=>{
        if(tiempo)
        empezarContador(parseInt(tiempo));
        return () =>{
            clearInterval(interval.current);
        };
    },[tiempo])

    return (      
    <div className="count-container">
          <div className="time">
           Tiempo restante: {`${timerMinutes}:${timerSeconds}`}
          </div>
    </div>

    );
};

export default CountDownTime;