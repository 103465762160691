import React from 'react';
import {Button} from "react-bootstrap";
const Opcion = ({id_opcion,opcion,id_pregunta, opcion_respuesta,opcion_correcta}) => {


  let color_r='';
  if(opcion_correcta==='0' && (id_opcion===opcion_respuesta))
  {
    color_r='#FCE8E7';
  }
  if(opcion_correcta==='1' && (id_opcion===opcion_respuesta))
  {
    color_r='#EAF2E9';
  }
  return ( 
    <>
    <div style={{backgroundColor:''+color_r, padding:'5px 30px', borderRadius:'5px'}}> <input id={'opcion'+id_opcion} className="form-check-input" type="radio" value={id_opcion} name={'pregunta'+id_pregunta} defaultChecked={id_opcion===opcion_respuesta ? true : false} /><label htmlFor={'opcion'+id_opcion}>{opcion} </label></div>
    </>
  );
}
 
export default Opcion;