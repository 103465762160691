import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import TablaReciboFracciones from './TablaReciboFracciones';
import TablaReciboMensualidad from './TablaReciboMensualidad';

const ExaminarPensiones = ({ onCloseModal, cuotaExaminar = {} }) => {
  
  const { mensualidad , fracciones = [] } = cuotaExaminar;

  const handleMostrarAviso = () => {
    if(!mensualidad && !fracciones.length) {
      return <p>No hay datos para mostrar</p>
    }
    if(mensualidad && !fracciones.length) return <TablaReciboMensualidad
      mensualidad={mensualidad}
    />
    if(fracciones.length > 0) return <TablaReciboFracciones
      fracciones={fracciones}
    />
  }

  return ( 
    <>
      <Modal.Header>
        <Modal.Title>Examinar pensión</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {handleMostrarAviso()}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='dark' onClick={onCloseModal}>Aceptar</Button>
      </Modal.Footer>
    </>
  );
}
 
export default ExaminarPensiones;