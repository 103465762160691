import React from 'react';
import { faComment, faTrash } from '@fortawesome/free-solid-svg-icons';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { eliminarTemaForo } from '../../../actions/cursosActions';

const Tema = ({tema}) => {
  const usuario = useSelector(state=> state.usuario.usuario);
  const params = useParams()
  const { idCapacitacion } = params;
  const dispatch = useDispatch()
  
  const eliminarTema = (id)=>{
    dispatch(eliminarTemaForo(id))
  }
    return (
    <div className="row" style={{padding:'15px', marginBottom:'10px', borderBottom:'1px solid #EEEEED'}}>
            <div className="col-md-2">
            {
                tema.img_usuario === null ? <img
               
                      alt=""
                      src='/images/user.png'
                     style={{width:'70px', height:'70px', borderRadius:'100%'}}
                    /> :
                  <img
                      alt=""
                      src={`${process.env.REACT_APP_API_URL}/${tema.img_usuario}`}
                      style={{width:'70px', height:'70px', borderRadius:'100%'}}
                    />
                
            }
            </div>
            <div className="col-md-6">
            <h4><Link to={'foro/tema/'+tema.slug} style={{color:'#1E2639'}}>{tema.titulo_tema}</Link></h4>
            <p>Por {tema.nombre_usuario + ' ' + tema.apellido_usuario}</p>
            <p><span style={{marginRight:'5px'}}><FontAwesomeIcon icon={faComment} /></span>{tema.cantidad_comentarios}</p>
            { tema.autor === usuario.id_usuario ? <button className="btn btn-danger float-right" onClick={()=>eliminarTema({id_tema:tema.id_tema,proyecto:idCapacitacion})}><FontAwesomeIcon icon={faTrash} /></button> : ''}
            </div>
            <div className="col-md-4">
            <p>Publicado el: {tema.fecha_registro}</p>
            <br/>
            { tema.ultimo_comentario ? <p><strong>último comentario por </strong>{ tema.ultimo_comentario.nombre_usuario}</p> : ''}
             </div>
    </div>
    );
};

export default Tema;