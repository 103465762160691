import React from 'react';
import { Badge, DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';
//import { getColorEstadoPago } from '../../../../helpers/functions';
//import RegistroFacturaOrdianria from '../../../../containers/FinanzasContainers/RegistroFacturaOrdinaria';
import * as moment from 'moment';

//Redux
import { useDispatch } from 'react-redux';
//import { updateStateModalBasic, updateStateModalCustom } from '../../../../actions/modalActions';
//import { eliminarCuotaFraccion, obtenerIDBoletaSiguiente, registrarDetallesPagos } from '../../../../actions/pagosActions';
//import Swal from 'sweetalert2';
//import FormularioAnularPago from '../FormularioAnularPago';

const FilaCuota = ({cuota,setFraccion,item}) => {

  const {
    id_fraccion,
    fecha_limite, 
    monto_establecido,
    nombre_tp_estado_pago,
  } = cuota 
  
  const dispatch = useDispatch();

  const handlePagar = (cuotab) => {
    //dispatch(registrarDetallesPagos('fraccion', id_fraccion))
    //No se le manda parámetro para que reestablesca a null
   // dispatch(obtenerIDBoletaSiguiente());
   // dispatch(updateStateModalCustom(<RegistroFacturaOrdianria /> , 'Registro de pago', 'modal-wl'));
  // console.log(cuotab)
   setFraccion(cuotab)
  }

  const getColorEstadoPago = (estado) => {
    switch (estado) {
      case 'PAGADO':
        return 'success';
      case 'PENDIENTE ':
        return 'warning';
      case 'REPORTADO':
        return 'warning';
      case 'MOROSO':
        return 'danger';
      default:
        return estado;
    }
  }
  

  const handleEliminarCuota = () => {
   /* if(nombre_tp_estado_pago === 'PAGADO') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'No se puede eliminar esta cuota porque ha sido pagada.',
      })
      return;
    }
    dispatch(eliminarCuotaFraccion(id_fraccion));*/
  }

  const handleAnularPago = () => {
   // dispatch(updateStateModalBasic(<FormularioAnularPago id={id_fraccion} tipo='fraccion'/>, 'Anular pago'));
  }

  return ( 
      <tr>
        <td align='center'>{item + 1}</td>
        <td align='center'>{monto_establecido}</td>
        <td align='center'>{fecha_limite && moment(fecha_limite).format('DD MMM YYYY')}</td>
        <td align='center'>
          <Badge variant={`${getColorEstadoPago(nombre_tp_estado_pago)} py-1`} >
            {nombre_tp_estado_pago}
          </Badge>
        </td>
        <td className='text-center'>
        <DropdownButton
          as={ButtonGroup}
          title="Opciones"
          variant='warning'
          size='xs'
        >
          <Dropdown.Item
            eventKey="1"
            onClick={()=>handlePagar(cuota)}
            disabled={nombre_tp_estado_pago === 'PAGADO' || nombre_tp_estado_pago === 'REPORTADO' ? true : false}
            className='btn-icon'
          >
            Reportar Pago
          </Dropdown.Item>

        </DropdownButton>
        </td>
      </tr>
  );
}
 
export default FilaCuota;