import React from "react";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";

//static icons
import micIcon from "../../static/icons/mic-icon.png";
import rankIcon from "../../static/icons/tag-icon.png";
import messageIcon from "../../static/icons/message-icon.png";
import logoutIcon from "../../static/icons/logout-icon.png";



//REDUX
import { useSelector, useDispatch } from "react-redux";
import { removerTodo } from "../../actions/authActions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPodcast, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import NotifyMe from "./Notificame.js";
import "./Header.scss";
const AccionesAvatar = ({
	nombre,
	apellido,
	imagenUsuario,
	puntos,
	notificaciones,
}) => {
	const mensajesNoVistos = useSelector(
		(state) => state.mensajes.mensajesNoVistos
	);

	const dispatch = useDispatch();
	const history = useHistory();

	const cerrarSesion = () => {
		Swal.fire({
			title: "¿Esta seguro de cerrar sesión?",
			text: "Tendra que logearse para entrar",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#1C56FF",
			cancelButtonColor: "#29d698",
			confirmButtonText: "Si, cerrar sesión",
			cancelButtonText: "Cancelar",
		}).then(async (result) => {
			if (result.value) {
				await dispatch(removerTodo());
				history.push("/login");
			}
		});
	};

	return (
		<ul className="AccionesAvatar">
			<li>
				<Link to="/listen-podcasts">
					<OverlayTrigger
						placement="bottom"
						overlay={
							<Tooltip id="tooltip-message">
								Escuchar Podcasts
							</Tooltip>
						}
					>
						<Badge
							variant="dot"
							color="primary"
							className="icon-message"
						>
							<img src={micIcon} className="img-icons" alt="Microphone icon" />
						</Badge>
					</OverlayTrigger>
				</Link>
			</li>

			<li>
				<NotifyMe
					data={notificaciones}
					storageKey="notific_key"
					notific_key="timestamp"
					notific_value="update"
					heading="Notificaciones"
					sortedByKey={false}
					showDate={true}
					size={24}
					color="#FFFFFF"
					notFoundMsg="No hay notificaciones"
				/>
			</li>
			<li>
				<OverlayTrigger
					placement="bottom"
					overlay={
						<Tooltip id="tooltip-message">Puntos GestoraRank</Tooltip>
					}
				>
					<div className="d-flex align-items-center" style={{marginLeft:'-10px'}}>
						<img src={rankIcon} className="img-icons" alt="INEDI Rank" />
						<p style={{ fontSize: "13px"}}>
							{puntos}
						</p>
					</div>
				</OverlayTrigger>
			</li>
			<li className="pl-md-1 pr-md-2 nav-item">
				<Link to="/mensajeria">
					<OverlayTrigger
						placement="bottom"
						overlay={
							<Tooltip id="tooltip-message">Mensajería</Tooltip>
						}
					>
						<Badge
							variant="dot"
							color="primary"
							className="icon-message"
						>
							<img src={messageIcon} className="img-icons" alt="Messages" />
						</Badge>
					</OverlayTrigger>
				</Link>
			</li>

			<li onClick={() => cerrarSesion()}>
				<OverlayTrigger
					placement="bottom"
					overlay={
						<Tooltip id="tooltip-message">Cerrar sesión</Tooltip>
					}
				>
					<img src={logoutIcon} className="img-icons" alt="Logout" />
				</OverlayTrigger>
			</li>

			{/*   <li className="info-header nav-item">
        <p
          className="px-md-4 mb-0 nav-link waves-effect d-none d-lg-block waves-light"
        >{`${nombre} ${apellido}`}</p>
      </li>*/}
			<li className="pl-md-2 pr-md-2 pl-2 nav-item avatar dropdown">
				<Link to="/perfil">
					<img
						src={
							imagenUsuario !== "null"
								? `${process.env.REACT_APP_API_URL}/${imagenUsuario}`
								: "/images/user.png"
						}
						className=" img_perfil  z-depth-0"
						alt=""
					/>
				</Link>
			</li>
		</ul>
	);
};

export default AccionesAvatar;
