import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { NumeroALetrasDinero } from './FunctionsHelpers';

export const generarPdf = (dataRecibo) => {
    var doc = new jsPDF();
    const {nombre_estudiante, apellido_estudiante, dni_estudiante,fecha, id_boleta,
      nombre_proyecto, nombre_modulo, importe_penalidad, importe_descuento,monto_pagado, lapso,direccion,monto_acuenta, monto_establecido} = dataRecibo

    var dataPago = [
      [1 , lapso.toUpperCase(), monto_establecido,importe_descuento, importe_penalidad, monto_pagado]
    ]
    //LOGO
    var logo = new Image();
    logo.src = '/images/logo_intra2.png';
    doc.setTextColor(39, 49, 74)
    doc.addImage(logo, "JPEG", 50,11 , 40, 20);
    //titulo
    doc.setFontSize(14);
    doc.setFont("helvetica");
    doc.setFontStyle("bold");
    doc.text("INSTITUTO NACIONAL DE LA EMPRESA", 23, 38);
    doc.text("Y EL DESARROLLO INTEGRAL", 31, 43);
    doc.setFontStyle("bold");
    doc.setFontSize(8);
    doc.text("CALLE BOLVAR N° 244 - ICA (A MEDIA CUADRA DE LA PLAZA DE ARMAS)", 20, 50);
    
    //Cuadrado para e RUC EMPRESA Y ID RECIBO
    doc.rect(135, 15, 70, 35);
    doc.setFontSize(11);
    doc.setFont("helvetica");
    doc.setFontStyle("bold");
    doc.text("R.U.C.:", 153, 21);
    doc.text("20605393901", 167, 21);
    doc.setFontSize(13);
    doc.text("RECIBO DE", 157, 31);
    doc.text("INGRESO", 158, 37);
    doc.setFontSize(11);
    doc.text(`Nº ${id_boleta}`, 165, 47);
    
    //INFORMACION DE COMPRADOR
    //COLMNA 1
    doc.setFontSize(8);
    doc.text("SEÑOR(ES)             :", 5, 70);
    doc.text("DNI                           :", 5, 75);
    doc.text("DIRECCIÓN             :", 5, 80);
    doc.text("EVENTO", 5, 90);
    if (nombre_modulo) {
      doc.text("MODULO", 5, 95);
    }
    doc.setFontStyle("normal");
    doc.text(`${nombre_estudiante.toUpperCase()} ${apellido_estudiante.toUpperCase()}`, 33, 70);
    doc.text(`${dni_estudiante}`, 33, 75);
    doc.text(`${direccion.toUpperCase()}`, 33, 80);
    doc.text(`: ${nombre_proyecto.toUpperCase().substr(0, 85)}`, 31, 90)
    doc.text(`  ${nombre_proyecto.toUpperCase().substr(86, 999)}`, 31, 93)
    if (nombre_modulo) {
      doc.text(`: ${nombre_modulo.toUpperCase()}`, 31, 95)
    }
    //COLUMNA 2
    doc.setFontStyle("bold");
    doc.text("FECHA             :", 140, 70);
    doc.text("MONEDA         :", 140, 75);
    
    doc.setFontStyle("normal");
    doc.text(`${fecha}`, 162, 70);
    doc.text("SOLES", 162, 75);
    //TABLA DE COMPRAS
    var columns = ["CANTIDAD", "DESCRIPCIÓN", "P.UNIT","DESCUENTO", "PENALIDAD","TOTAL"];
    var data = dataPago
    doc.autoTable(columns,data,
    { 
      margin:{top: 100, left: 5, right: 5 },
      headerStyles: {
        fillColor: [39, 49, 74],
        textColor: [255, 255, 255]
      }
    }
    );
   
    //TABLA DE ESPECIFICACIONES
    doc.autoTable({
        styles: { fillColor: [255, 255,255] },
        columnStyles: { 0: { halign: 'left', fillColor: [255, 255, 255] } }, // Cells in first column centered and green
        margin: { top: 200 , left: 100, right: 5},
        body: [
          ['TOTAL:',`S/ ${monto_pagado}`,],
          ['A Cta:', `S/ ${monto_acuenta}`],
          ['Saldo:', `S/ ${parseFloat(monto_pagado) - parseFloat(monto_acuenta)}`]
        ],
      })
    doc.text(`${NumeroALetrasDinero(monto_pagado)}`,10, 150);
    //GUARDAR PDF
    doc.save(`comprobante_${id_boleta}.pdf`);
    
}
