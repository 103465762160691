import React from 'react';
import './Pagina404.scss'
import { Link } from 'react-router-dom';

const Page404 = () => {
    return ( 

        <div className="container error-404">
            <img src="/images/error404.png" alt=""/>
            <Link to='/capacitaciones'>Volver a inicio</Link>
        </div>

    );
}
 
export default Page404;