import { combineReducers } from 'redux';
import usuarioReducers from './usuarioReducers';
import errorLoginReducer from './errorLoginReducer';
import cursosReducers from './cursosReducers';
import SesionesReducers from './SesionesReducers';
import MensajeReducer from './MensajeReducer';
import toastReducer from './toastReducer';
import alertaReducer from './alertaReducer';
import encuestaReducer from './encuestaReducer';
import foroReducer from './foroReducers';
import menuShowReducer from './menuShowReducer';
import detalleReducer from './detalleReducer';
import reproductorShowReducer from './reproductorShowReducer';
export default combineReducers({
    usuario: usuarioReducers,
    errorLogin: errorLoginReducer,
    cursos: cursosReducers,
    sesiones: SesionesReducers,
    mensajes: MensajeReducer,
    alerta: alertaReducer,
    toast: toastReducer,
    encuesta: encuestaReducer,
    foro: foroReducer,
    menuShow: menuShowReducer,
    detalleEmpleo : detalleReducer,
    reproductorShow: reproductorShowReducer
})