import React from 'react';
import { Table, Badge, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

const TablaReciboFracciones = ({ fracciones = []}) => {
  return ( 
    <Table>
      <thead>
        <tr>
          <th className='text-center'>#</th>
          <th className='text-center'>Concepto</th>
          <th className='text-center'>Monto</th>
          <th className='text-center'>Estado</th>
          <th className='text-center'>Descargar</th>
        </tr>
      </thead>
      <tbody>
        {
          fracciones.map((fraccion, index) => (
            <FilaTabla 
              key={index+1}
              index={index}
              {...fraccion}
            />
          ))
        }
      </tbody>
    </Table>
  );
}
 
export default TablaReciboFracciones;

const FilaTabla = ({ index, concepto, monto_establecido, id_registro_pago, estado }) => {
  return (
    <tr>
      <td align='center'>{index+1}</td>
      <td align='center'>{concepto}</td>
      <td align='center'>{monto_establecido}</td>
      <td align='center'>
        <Badge variant={`${estado === 'PAGADO' ? 'success' : 'warning'}`}>
          {estado}
        </Badge>
      </td>
      <td align='center'>
        <Button
          variant='link'
          href={`${process.env.REACT_APP_API_URL}/admin/pdf/recibo_ingreso/${id_registro_pago}`}
          disabled={estado !== 'PAGADO' ? true : false}
        >
          <FontAwesomeIcon icon={faFileDownload} />
        </Button>
      </td>
    </tr>
  );
}
