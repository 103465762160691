import { examenesTypes, ruletaTypes, MOSTRAR_CURSOS, LISTAR_PROYECTO, LISTAR_PROYECTOS, LISTAR_NOTAS, ELIMINAR_CURSOS, LISTAR_ASISTENCIA, MOSTRAR_PAGOS, LISTAR_CURSO, REMOVER_ESTADO_PAGOS, LISTAR_RECURSOS, LISTAR_PROYECTOS_ACTIVOS_ESTUDIANTE, LISTAR_CURSOS_ACTIVOS_RULETA, authTypes } from '../actions/types'

const initialState = {
    proyetos: [],
    proyectosActivos: [],
    proyecto: {},
    curso: {},
    examen: {},
    preguntas: [],
    calificaciones: [],
    idMatricula: null,
    modulos: [],
    notas: [],
    certificado: null,
    constancia: null,
    profesoresCurso: [],
    pagos: {},
    asistencias: [],
    estado: null,
    estadoPagos: 0,
    loading: false,
    respondido: false,
    tiempoTerminado: false,
    intentosRealizados: 0,
    cursosActivosRuleta: [],
    cupon: null,
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LISTAR_PROYECTOS:
            return {
                ...state,
                proyectos: action.payload.proyectos,
                estado: action.payload.estado
            }
        case LISTAR_PROYECTOS_ACTIVOS_ESTUDIANTE:
            return {
                ...state,
                proyectosActivos: action.payload.proyectosActivos,
            }
        case examenesTypes.OBTENER_EXAMEN_SESION:
            return {
                ...state,
                loading: true
            }
        case examenesTypes.OBTENER_EXAMEN_SESION_EXITO:
            return {
                ...state,
                loading: false,
                examen: action.payload.examen,
                preguntas: action.payload.preguntas
            }
        case examenesTypes.INSERTAR_EXAMEN_EXITO:
            return {
                ...state,
                respondido: true
            }
        case examenesTypes.INSERTAR_EXAMEN:
            return {
                ...state,
                respondido: false,
                tiempoTerminado: false
            }
        case examenesTypes.TIEMPO_EXAMEN_TERMINADO:
            return {
                ...state,
                tiempoTerminado: true
            }
        case examenesTypes.TIEMPO_EXAMEN_TERMINADO_EXITO:
            return {
                ...state,
                tiempoTerminado: false
            }
        case LISTAR_PROYECTO:
            return {
                ...state,
                proyecto: action.payload.proyecto,
                modulos: action.payload.modulos,
                calificaciones: action.payload.calificaciones,
                certificado: action.payload.certificado,
                constancia: action.payload.constancia,
                idMatricula: action.payload.idMatricula
            }
        case MOSTRAR_CURSOS:

            return {
                ...state,
                cursos: action.payload.cursos,
                pagos: action.payload.pagos,
                estado: action.payload.estado
            }
        case LISTAR_CURSOS_ACTIVOS_RULETA:
            return {
                ...state,
                cursosActivosRuleta: action.payload.cursosActivosRuleta,
            }
        case LISTAR_CURSO:
            return {
                ...state,
                curso: action.payload.curso,
                profesoresCurso: action.payload.profesores
            }
        case ELIMINAR_CURSOS:
            return {
                ...state,
                cursos: action.payload,
                pagos: action.payload,
                estado: null,
                curso: {},
                notas: [],
                profesoresCurso: [],
                asistencias: [],
                estado: null,
                estadoPagos: 0
            }
        case MOSTRAR_PAGOS:
            return {
                ...state,
                pagos: action.payload.pagos,
                estadoPagos: action.payload.estadoPagos
            }
        case REMOVER_ESTADO_PAGOS:
            return {
                ...state,
                cursos: state.cursos,
                pagos: state.pagos,
                estado: state.estado,
                estadoPagos: action.payload.estadoPagos
            }
        case LISTAR_NOTAS:
            return {
                ...state,
                notas: action.payload
            }
        case LISTAR_ASISTENCIA:
            return {
                ...state,
                asistencias: action.payload
            }
        case authTypes.REMOVER_TODO:
            return {
                ...state,
                proyetos: [],
                proyecto: {},
                curso: {},
                examen: {},
                preguntas: [],
                calificaciones: [],
                idMatricula: null,
                modulos: [],
                notas: [],
                certificado: null,
                constancia: null,
                profesoresCurso: [],
                pagos: {},
                asistencias: [],
                estado: null,
                estadoPagos: 0,
                loading: false,
                respondido: false,
                tiempoTerminado: false
            }
        case examenesTypes.OBTENER_INTENTOS_REALIZADOS:
            return {
                ...state,
                loading: true
            }
        case examenesTypes.OBTENER_INTENTOS_REALIZADOS_EXITO:
            return {
                ...state,
                loading: false,
                intentosRealizados: action.payload
            }
        case ruletaTypes.OBTENER_CUPON:
            return {
                ...state,
                loading: true,
            }
        case ruletaTypes.OBTENER_CUPON_EXITO:
            return {
                ...state,
                loading: false,
                cupon: action.payload.cupon
            }
        case ruletaTypes.OBTENER_CUPON_ERROR:
            return {
                ...state,
                loading: false,
            }
        default:
            return state
    }
}