import React from 'react';
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./CarouselButtons.scss";
/* props
carouselState:{
    containerWidth: 1374
    currentSlide: 0
    deviceType: "desktop"
    domLoaded: true
    itemWidth: 344
    slidesToShow: 4
    totalItems: 7
    transform: -0
}

goToSlide(index)
next()
previous()

*/

const CarouselButtons = (props) => {
    
    const {carouselState, next, previous} = props;
    
	return (
        <div className="carousel-btn-container pb-3">
            <FontAwesomeIcon icon={faChevronLeft} onClick={() => previous()} className="arrow-icons"/>
            <p>{Number(carouselState?.currentSlide)+1}</p>
            <FontAwesomeIcon icon={faChevronRight} onClick={() => next()} className="arrow-icons"/>
        </div>
        
    );
};

export default CarouselButtons;
