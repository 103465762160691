import React from "react";
import { Accordion, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThList } from '@fortawesome/free-solid-svg-icons'; 

const AcordeonReglas = ({ label = '', contenido = '', target = '', id, posicion, show = true}) => {
  return (
    <>
    <Accordion>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey={`#collapse-${id}`}>
          <FontAwesomeIcon icon={faThList} /> {label}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={`#collapse-${id}`}>
            <Card.Body>
              {Array.isArray(contenido) ? (
                <ul>
                  {contenido.map((item, itemKey) => (
                    <div key={itemKey}>
                      <li className="list-unstyled mb-2">
                        <div className="d-flex">
                          <span className="font-weight-bold mr-1">
                            {posicion + 1}.{itemKey + 1}.{" "}
                          </span>
                          <div className="text-justify">{item}</div>
                        </div>
                      </li>
                    </div>
                  ))}
                </ul>
              ) : (
                contenido
              )}
            </Card.Body>
          </Accordion.Collapse>
      </Card>
    </Accordion>
    </>
  );
};

export default AcordeonReglas;
