import { faGoogleDrive } from '@fortawesome/free-brands-svg-icons';
import { faCloud, faFileAudio, faFileExcel, faFilePdf, faFilePowerpoint, faFileWord } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'

const style = {
    icon: {
        fontSize: "20px"
    }
}

const Recurso = ({recurso, setDataPodcast}) => {

    if(recurso.aprobado!=="1") return null;
    return ( 
        <div className="container-recursos d-flex justify-content-between border-top">
            <div className="nombre-archivo p-3">
              { recurso.formato_archivo === 'M4A' || recurso.formato_archivo === 'MP4' || recurso.formato_archivo === 'MP3' ?  <span 
          target="_black"
          onClick={() => setDataPodcast(recurso)}
          data-toggle="modal"
          data-target="#modal-recurso"
          style={{cursor:'pointer'}}
          className="mb-0 p-nombre-video">
                    <p className="text-info">
                        <span><i className="fas text-dark fa-chevron-circle-right mr-2"></i></span>
                        Podcast - {recurso.nombre_archivo}
                    </p>
                </span> : 
                recurso.formato_archivo === 'Ninguno' ?   <a href={`${recurso.ruta_archivo}`} target='_black' className="mb-0 p-nombre-video">
                    <p className="text-info">
                        <span><i className="fas text-dark fa-chevron-circle-right mr-2"></i></span>
                        {recurso.nombre_archivo}
                    </p>
                </a> :  <a href={`${process.env.REACT_APP_API_URL}/${recurso.ruta_archivo}`} target='_black' className="mb-0 p-nombre-video">
                    <p className="text-info">
                        <span><i className="fas text-dark fa-chevron-circle-right mr-2"></i></span>
                        {recurso.nombre_archivo}
                    </p>
                </a>}
            </div>
            <div className="icon-archivo p-3" style={style.icon}>
           
                    {recurso.formato_archivo === 'Ninguno' ?   <a href={`${recurso.ruta_archivo}`} target='_black'>
                
                            <FontAwesomeIcon icon={faGoogleDrive} className='text-danger' /> </a>
                            :
                        recurso.formato_archivo === 'PPTX' ?   <a href={`${process.env.REACT_APP_API_URL}/${recurso.ruta_archivo}`} target='_black'>
                
                        <FontAwesomeIcon icon={faFilePowerpoint} className='text-danger' /> </a>
                        : recurso.formato_archivo === 'DOCX' || recurso.formato_archivo === 'DOC'  ?
                        <a href={`${process.env.REACT_APP_API_URL}/${recurso.ruta_archivo}`} target='_black'>
                
                            <FontAwesomeIcon icon={faFileWord} className='text-info' /> </a>
                        :recurso.formato_archivo === 'PDF' ?
                        <a href={`${process.env.REACT_APP_API_URL}/${recurso.ruta_archivo}`} target='_black'>
                
                            <FontAwesomeIcon icon={faFilePdf} className='text-danger' /> </a>
                        :recurso.formato_archivo === 'XLSX' || recurso.formato_archivo === 'XLTX' ?
                        <a href={`${process.env.REACT_APP_API_URL}/${recurso.ruta_archivo}`} target='_black'>
                
                            <FontAwesomeIcon icon={faFileExcel} className='text-success' /> </a>
                        : recurso.formato_archivo === 'M4A' || recurso.formato_archivo === 'MP4' || recurso.formato_archivo === 'MP3'?      <span 
          target="_black"
          onClick={() => setDataPodcast(recurso)}
          data-toggle="modal"
          data-target="#modal-recurso"
          style={{cursor:'pointer'}}
          >
                 <FontAwesomeIcon icon={faFileAudio} className='text-success' /> </span>
                        :null
                        }
        
             
            </div>
        </div>
     );
}
 
export default Recurso;