import React from 'react';
import Layout from '../Layout/Layout';
import { useSelector } from 'react-redux';
import './cupon.css';
import { Spinner } from 'react-bootstrap';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Cupon = ({ premio, tipo_premio }) => {
    //console.log(premio);
    //console.log(tipo_premio);
    const { cupon } = useSelector(state => state.cursos);
    const { usuario } = useSelector(state => state.usuario);

    const handleCopy = () => {
        navigator.clipboard.writeText(cupon)
            .then(() => {
                alert('Código copiado al portapapeles');
            })
            .catch(err => {
                console.error('Error al copiar el código:', err);
            });
    };

    return (
        <Layout>
            {premio && tipo_premio ?
                <div className="container-cupon">
                    {tipo_premio === 2 ?
                        <h4>Felicidades, {usuario.nombre_usuario} <span className="highlight">ganaste</span> el <span className="highlight">acceso a un curso en vivo</span></h4>
                        : tipo_premio === 1 ? <h4>Felicidades, {usuario.nombre_usuario} <span className="highlight">ganaste</span> el <span className="highlight">un descuento en tus mensualidades</span></h4>
                            : null}
                    <div className="ticket-container">
                        <img src="./images/assets/cupon.webp" alt="cupon" className="img-ticket" />
                        <div className="ticket-text">{premio.nombre}</div>
                    </div>
                    <div className="code-container">
                        <p className="subtitle mt-4">Copia el código para canjearlo con tu tutor de clase</p>
                        <div className='button-container-copy'>
                            <div className="code">{cupon}</div>
                            <button onClick={handleCopy} className="copy-button">
                                <FontAwesomeIcon icon={faCopy} />
                            </button>
                        </div>

                    </div>

                </div>
                :
                < Spinner animation="border" role="status" />
            }
        </Layout >
    );
};

export default Cupon;