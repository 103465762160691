import React from 'react';
import {Button, Card} from "react-bootstrap";
import ListarOpcionesExamen from "./ListarOpcionesExamen";
const Pregunta = ({pregunta, opciones, num_pregunta,id_opcion,opcion_correcta}) => {

  let color_r='';
  if(opcion_correcta==='0')
  {
    color_r='#BE4A46';
  }
  else
  {
    color_r='#5A926F';
  }
  return ( 
 <Card style={{marginBottom:'15px', borderColor:''+color_r, borderWidth:'2px'}}>
 
  <Card.Body >
        <div className="row" >
          <div className="col-md-10" style={{color:''+color_r}}><h6 className="form-label">{pregunta ? num_pregunta+'.- '+ pregunta.pregunta : 'no hay pregunta'}</h6></div>
          <div className="col-md-2" style={{textAlign:"center"}}><span style={{fontSize:'10px'}}> {/*pregunta.puntaje*/}</span></div>
        </div>
        {pregunta.descripcion!=='' ? <div className="form-text" style={{padding:"5px", marginBottom:"8px", fontSize:'14px'}}>{pregunta.descripcion}</div> : '' }

        <ListarOpcionesExamen opciones={opciones.opciones_obtenidas} opcion_marcada={id_opcion} explicacion={pregunta.explicacion_correcta} />  
  </Card.Body>
</Card>
  );
}
 
export default Pregunta;