import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Form, Spinner, Alert } from 'react-bootstrap';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { mostrarErrorLogin } from '../../../actions/authActions';
import { verificarUsuario } from '../../../actions/usuarioActions';

import './FormLogin.scss';
const FormLogin = () => {

  const dispatch = useDispatch();
  const { loading, error } = useSelector(state => state.usuario);
  const [datos, setDatos] = useState({
    usuario: '',
    password: ''
  });
  
  const { usuario, password } = datos;

  const handleSubmit = e => {
    e.preventDefault();
    if(usuario === '' || password === '') {
      dispatch(mostrarErrorLogin('Ambos campos son requeridos.'))
      return;
    }
    dispatch(verificarUsuario(datos))
  }

  const handleChange = e => {
    setDatos({
      ...datos,
      [e.target.name]: e.target.value
    })
  }

  return ( 
    <div className="FormLogin mb-5">
      {
        error && <Alert variant='danger'>
          <span className='font-weight-bold'>Error:</span> {error}
        </Alert>
      }
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Usuario</Form.Label>
          <Form.Control
            type='text'
            name='usuario'
            value={usuario}
            onChange={handleChange}
            disabled={loading}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Contraseña</Form.Label>
          <Form.Control
            type='password'
            name='password'
            value={password}
            onChange={handleChange}
            disabled={loading}
          />
        </Form.Group>
        <Form.Group>
          <Button
            className='btn-block d-flex align-items-center justify-content-center'
            type='submit'
            disabled={loading}
          >
            {
              loading && <Spinner
                as='span'
                size='sm'
                className='mr-2'
                animation="border"
                role="status"
              />
            }
            Ingresar
            <FontAwesomeIcon className='ml-2' icon={faArrowRight} />
          </Button>
        </Form.Group>
      </Form>
    </div>
   );
}
 
export default FormLogin;