import styled from '@emotion/styled';
import { PDFDownloadLink as AnchorLink} from '@react-pdf/renderer'


export const ContainerDocumentos = styled.div`
  height: 100%;
  margin-top: 30px;
  margin-bottom: 50px;
`

export const MensajeHead = styled.h3`
  font-size: 1.15rem;
  font-weight: bold;
`;

export const PDFDownloadLink = styled(AnchorLink)`
  font-size: .8rem;
  margin-left: 3px
`