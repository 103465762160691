import React from 'react';
import './FloatingWhatsAppButton.scss';

const FloatingWhatsAppButton = () => {
  const whatsappNumber = '51989812160';
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=Hola,%20quiero%20más%20información`;

  return (
    <a href={whatsappLink} className="floating-whatsapp-button" target="_blank" rel="noopener noreferrer">
      <img src="/images/login-sociales/Whatsx32.png" alt="WhatsApp" className="whatsapp-icon" />
      <span className="whatsapp-text">Escribenos</span>
    </a>
  );
};

export default FloatingWhatsAppButton;