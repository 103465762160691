import React from 'react'
import InfoProyecto from './InfoProyecto'
import Modulo from './Modulo'

//Redux
import {connect} from 'react-redux'

const Modulos = (props) => {

    const {proyecto, modulos} = props

    return (
      <div className="content-proyecto">
        <div className="content-info-proyecto shadow-sm bg-white p-3 m-1">
          <InfoProyecto
            proyecto={proyecto !== undefined ? proyecto : {}}
            />
        </div>
        <div className="content-modulos shadow-sm m-1">
          {modulos !== undefined ?
              modulos.map(modulo => (
              <Modulo
                key={modulo.id_modulo}
                modulo={modulo}
              />
          )) : null
          }
        </div>
      </div>
    );
}
 
const mapStateToProps = state => ({
    proyecto: state.cursos.proyecto,
    modulos: state.cursos.modulos
})

export default connect(mapStateToProps, {})(Modulos);