import React, { useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import Swal from "sweetalert2";

//REDUX
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import "./BasicMenu.scss";
import { Badge } from "react-bootstrap";
import { removerTodo } from "../../../actions/authActions";
import { Drawer } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPodcast, faTimes } from "@fortawesome/free-solid-svg-icons";
import { showMenu } from "../../../actions/alertaActions";

const SlideMenu = () => {
	const { usuario = {} } = useSelector((state) => state.usuario);
	const dispacth = useDispatch();
	const history = useHistory();
	const show = useSelector((state) => state.menuShow.show);

	const handleClose = () => {
		dispacth(showMenu());
	};

	const cerrarSesion = () => {
		handleClose();
		Swal.fire({
			title: "¿Esta seguro de cerrar sesión?",
			text: "Tendra que logearse para entrar",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#1C56FF",
			cancelButtonColor: "#29d698",
			cancelButtonText: "Cancelar",
			confirmButtonText: "Si, cerrar sesión",
		}).then((result) => {
			if (result.value) {
				dispacth(removerTodo());
				history.push("/login");
			}
		});
	};

	return (
		<Drawer anchor={"left"} open={show} onClose={() => handleClose()} ModalProps={{disableScrollLock: true}}>
			<div className="p-4 burger-menu-header">
				<div className="burger-menu-logo">
					<p>	INEDI </p>
					<span>POSTGRADOS &reg;</span>
				</div>
				<a className="menuButton" onClick={() => handleClose()}>
					<FontAwesomeIcon icon={faTimes} color="#fff" />
				</a>
			</div>
			<div className="basic-menu">
				<div className="img mt-4">
					<div className="d-none text-center">
						{usuario.img_usuario !== undefined ? (
							<img
								src={
									usuario.img_usuario !== null
										? `${process.env.REACT_APP_API_URL}/${usuario.img_usuario}`
										: "/images/user.png"
								}
								className="kimg_perfil imgPerfil border-white card-img-top my-3"
								alt=""
							/>
						) : (
							<div className="w-100 my-3 justify-content-center d-flex skeleton">
								<Skeleton
									variant="circle"
									margin={"auto"}
									width={120}
									height={120}
								/>
							</div>
						)}
					</div>
				</div>
				<div className="mt-4 ml-md-0" id="perfi">
					<ul className="list-group list-group-flush burger-list">
						<li className="list-group-item d-flex align-items-center burger-list-item hvr-sweep-to-right">
							<Link
								to="/capacitaciones"
								className="px-2 py-0 enlaceListGroup"
							>
								<span className="text-left">
									{" "}
									Mis capacitaciones
								</span>
							</Link>
							<img
								src="/images/burger-menu-icons/capacitacion-icon.png"
								alt=""
							/>
						</li>
						<li className="list-group-item d-flex align-items-center burger-list-item hvr-sweep-to-right">
							<Link
								to="/listen-podcasts"
								className="px-2 py-0 enlaceListGroup"
							>
								<span className="text-left">
									PodCasts{" "}
									<Badge variant="color-gestora-success">Nuevo</Badge>
								</span>
							</Link>
							<img
								src="/images/burger-menu-icons/podcast-icon.png"
								alt=""
							/>
						</li>
						<li className="list-group-item d-flex align-items-center burger-list-item hvr-sweep-to-right">
							<Link
								to="/bolsa-de-trabajo"
								className="px-2 py-0 enlaceListGroup"
							>
								<span className="text-left">
									Bolsa de trabajo{" "}
								</span>
							</Link>
							<img
								src="/images/burger-menu-icons/bolsa-icon.png"
								alt=""
							/>
						</li>
						<li className="list-group-item d-flex align-items-center burger-list-item hvr-sweep-to-right">
							<Link
								to="/perfil"
								className="px-2 py-0 enlaceListGroup"
							>
								<span className="text-left">
									Mis datos{" "}
								</span>
							</Link>
							<img
								src="/images/burger-menu-icons/datos-icon.png"
								alt=""
							/>
						</li>
						{/* <li className="list-group-item d-flex align-items-center">
                <img src="/images/institucion.png" alt="" />
                <Link
                  
                  to="/institucion"
                  className="px-2 py-0 enlaceListGroup"
                >
                  Información institucional
                </Link>
              </li>*/}
						<li className="list-group-item d-flex align-items-center burger-list-item hvr-sweep-to-right">
							<Link
								to="/reglas-normas"
								className="px-2 py-0 enlaceListGroup"
							>
								<span className="text-left">
									Reglas y normas{" "}
								</span>
							</Link>
							<img
								src="/images/burger-menu-icons/reglas-icon.png"
								alt=""
							/>
						</li>
						<li className="list-group-item d-flex align-items-center burger-list-item hvr-sweep-to-right">
							<a
								href="https://inedi.edu.pe/blog"
								target="_blank"
								className="px-2 py-0 enlaceListGroup"
							>
								<span className="text-left">
									Blog{" "}
								</span>
							</a>
							<img
								src="/images/burger-menu-icons/blog-icon.png"
								alt=""
							/>
						</li>
						<li className="list-group-item d-flex align-items-center burger-list-item hvr-sweep-to-right">
							<Link
								to="/cuenta"
								className="px-2 py-0 enlaceListGroup"
							>
								<span className="text-left">
									Cambiar contraseña{" "}
								</span>
							</Link>
							<img
								src="/images/burger-menu-icons/pass.png"
								alt=""
							/>
						</li>
						<li className="list-group-item  d-flex align-items-center burger-list-item-btn">
							<button onClick={cerrarSesion} className="btn">
								Cerrar sesión
							</button>
						</li>
					</ul>
				</div>
			</div>
		</Drawer>
	);
};

export default SlideMenu;
