import { encuestaTypes } from './types';
import authAxios from "../config/authAxios";
export const showEncuesta = () => {
  return dispatch => {
    dispatch({
      type: encuestaTypes.SHOW_ENCUESTA
    })
  }
}

export const guardarEncuesta = (dataEncuesta) => async dispatch => {
  try {
    let respuesta=  await authAxios.post(`/admin/guardarEncuesta/`, dataEncuesta)
     // console.log(respuesta)
      dispatch(showEncuesta());
      return false;
  } catch (error) {
    return false;
  }
  
}
