import React from "react";
import {Link, useParams } from "react-router-dom";

const Video = ({ video, setDataVideo }) => {
  const { idSesion, } = useParams()
  return (
    <div className="container-recursos videos d-flex justify-content-between border-top">
      <div className="nombre-archivo p-3">
      <Link to={`${idSesion}/videos/${video.id_archivo_sesion}`}><p
          href="#"
          className="mb-0 p-nombre-video"
        >
          <span>
            <i className="fas text-dark fa-chevron-circle-right mr-2"></i>
          </span>
          <span className="text-info">{video.nombre_archivo}</span>
        </p>
        </Link> 
      </div>
      <div className="icon-archivo p-3 mr-3">
        <span
          href="#"
          target="_black"
          onClick={() => setDataVideo(video)}
          data-toggle="modal"
          data-target="#modal-video"
          title='Ver video'
        >
            <img  style={{ width: "24px", cursor: 'pointer' }} src="/images/technology.png" alt=""/>
        </span>
      </div>
    </div>
  );
};

export default Video;
