import React from "react";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

//REDUX
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { removerEstadoPago } from "../../actions/cursosActions";
import { Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCalendarAlt,
	faCalendarWeek,
} from "@fortawesome/free-solid-svg-icons";

import "./Proyecto.scss";

//Enviar por post el id_alumno_curso

//VIENDO PARA HACE QUE CARGUE UN PRELOAD
// Linea
const Curso = ({ proyecto }) => {
	//console.log(proyecto.discentes)
	const rutaModulos = () => {
		return `/capacitaciones/${proyecto.id_proyecto}/modulos`;
	};
	
	return (
		<Card className="card-course">
			<Card.Img variant="top" src={`${proyecto.img?process.env.REACT_APP_API_URL+'/files_inedi/img_productos/'+proyecto.img:'images/course-img.png'}`} className="card-bg-img" />
			<Card.Body className="body-course">
				<Card.Title className="titulo_curso">
					{proyecto.nombre_proyecto}
				</Card.Title>
				<div className="date-container">
					<div className="calendar-container">
						<img
							src="images/icons/calendar-icon.png"
							alt="calendar"
							className="calendar-icon"
						/>
					</div>
					<p>
						<span className="text-capitalize">Duración</span>:{" "}
						{`${proyecto.cantidad_lapso} ${proyecto.nombre_tp_lapso}`}
					</p>
				</div>
				
			</Card.Body>
			<Card.Footer className="footer-course">
				<div className="avatars-container">
					<AvatarGroup className="group-avatar">
						{proyecto.discentes.length > 0
							? proyecto.discentes.map((discente, index) => {
									if (index > 6) return null;

									if (discente.image_estudiante === null)
										return (
											<Avatar
												key={index}
												alt=""
												src="images/user.png"
											/>
										);
										
									return (
										<Avatar
											key={index}
											alt=""
											src={`${process.env.REACT_APP_API_URL}/${discente.image_estudiante}`}
										/>
									);
							})
							: null}
					</AvatarGroup>
				</div>
				
				<div className="card-actions">
					<Link
						to={rutaModulos()}
						className="boton-responsive boton-curso btn mt-3 pull-right"
					>
						Ingresa aquí
					</Link>
				</div>
			</Card.Footer>
		</Card>
	);
};

const mapStateToProps = (state) => ({
	estadoPagos: state.cursos.estadoPagos,
});

export default connect(mapStateToProps, { removerEstadoPago })(Curso);
