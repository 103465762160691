import React from 'react';
//REDUX
import {Provider} from 'react-redux';
import store from './store';
//Router
import {BrowserRouter as Router} from 'react-router-dom';

import AppRouter from './routers/AppRouter';

function App() {

  return (
    <Provider store={store}>
        <Router>
          <AppRouter />
        </Router>
    </Provider>
  );
}

export default App;
