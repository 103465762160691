import React, { useEffect } from "react";
import "../contenidoProyecto.css";
import WOW from 'wowjs'

const CanalesPago = () => {

  useEffect(() => {
    new WOW.WOW().init();
  }, [])

  return (
    <React.Fragment>
      <div className="content-header header-vista-content border-header-primary-b py-2">
        <h4 className="mb-0 text-uppercase font-20">canales de inversión</h4>
      </div>
      <div className="content-body-canales">
        <div className="content-lista">
          <ul>
            <li className="titulo">Desde mi cuenta BCP:</li>
          </ul>
          <p className="definicion">
            Realiza tu inversión al instante. Rápido y seguro.
          </p>

          <div className="content-info">
            <div className="logo">
              <a
                href="https://www.viabcp.com/canales/banca-movil"
                target="__blanck"
              >
                <img className='img-logo wow animate__animated animate__heartBeat animate__slow animate__repeat' src="/images/banca-movil-bcp.svg" alt="" />
              </a>
            </div>
            <div className="descripcion">
              <ul>
                <li className="titulo-decrip">A través de Banca móvil BCP</li>
                <ul className="item-decrip">
                  <li>Ingresa a pago de servicios y busca Gestora.</li>
                  <li>Ingresa tu DNI para obtener el monto a pagar.</li>
                  <li>Confirma el pago y listo.</li>
                </ul>
              </ul>
            </div>
          </div>

          <div className="content-info">
            <div className="logo">
              <a
                href="https://www.viabcp.com/canales/banca-por-internet"
                target="__black"
              >
                <img style={{ width: "90px" }} className='img-logo wow animate__animated animate__slow animate__heartBeat animate__repeat' src="/images/bcp.jpg" alt="" />
              </a>
            </div>
            <div className="descripcion">
              <ul>
                <li className="titulo-decrip">A través de Banca web BCP</li>
                <ul className="item-decrip">
                  <li>Ingresa a pago de servicios y busca Gestora.</li>
                  <li>Ingresa tu DNI para obtener el monto a pagar.</li>
                  <li>Confirma el pago y listo.</li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
        <div className="content-lista">
          <ul>
            <li className="titulo">¿No tienes cuenta?</li>
          </ul>
          <p className="definicion">
            Abre tu cuenta al instante desde la web. Rápido y seguro.
          </p>

          <div className="content-info">
            <div className="logo">
              <a href="https://www.abretucuenta.viabcp.com/" target="__black">
                <img className='img-logo wow animate__animated animate__heartBeat animate__slow animate__repeat' src="/images/img-plataforma-digital.png" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="content-lista">
          <ul>
            <li className="titulo">¿Que otra opción tengo?</li>
          </ul>
          <p className="definicion">
            Acudir a un agente BCP:
            <a
              href="https://www.viabcp.com/canales-presenciales"
              target="__black"
            >
              Consulta aquí
            </a>
          </p>

          <div className="content-info">
            <div className="logo">
              <a
                href="https://www.viabcp.com/canales-presenciales"
                target="__black"
              >
                <img className='img-logo wow animate__animated animate__heartBeat animate__slow animate__repeat' src="/images/img-agentes.png" alt="" />
              </a>
            </div>
            <div className="descripcion">
              <ul>
                {/* <li  className='titulo-decrip'>A través de Banca móvil BCP</li> */}
                <ul className="item-decrip">
                  <li>Otorga nuestro código 23287.</li>
                  <li>Brinda tu DNI y realiza el pago correspondiente.</li>
                  <li>¡Listo! no te olvides recibir tu voucher.</li>
                </ul>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CanalesPago;
