import React, { useEffect, useState } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { disLikeComentarioVideo, eliminarRespuestaComentario, likeComentarioVideo } from '../../actions/cursosActions';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Favorite, FavoriteBorder } from '@material-ui/icons';
import ReadMore from '../../components/ReadMore/ReadMore';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Respuesta = ({respuesta, id_video, id_sesion}) => {
    const dispatch = useDispatch()
    const[likes,setLikes]=useState(respuesta.likes)
    const[liked,setLiked]=useState(respuesta.mi_like)
    const usuario = useSelector(state=> state.usuario.usuario);
    const eliminarRespuesta = (id)=>
    {
       dispatch(eliminarRespuestaComentario(id))
    }
    useEffect(()=>{
        if(respuesta)
        {
          setLikes(respuesta.likes)
          setLiked(respuesta.mi_like)
        }
      },[respuesta])
    return (
        <div className="row" style={{marginBottom:'10px', borderBottom:'1px solid #EEEEED'}}>
          <div className="col-md-1 d-flex flex-column" >
            <FormControlLabel
            control={<Checkbox checked={liked} icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="checkedH" onChange={(e)=> {
                if(e.target.checked)
                {
                setLikes(likes+1) 
                setLiked(!liked)
                dispatch(likeComentarioVideo({id_ref:respuesta.id_unique,video:id_video,sesion:id_sesion,id_usuario:usuario.id_usuario,propietario:respuesta.autor}))
                }
                else
                {
                setLikes(likes-1)
                setLiked(!liked)
                dispatch(disLikeComentarioVideo({id_ref:respuesta.id_unique,video:id_video,sesion:id_sesion,id_usuario:usuario.id_usuario}))
                }}} />}
            />
            { respuesta.whoslikes.length>0 ? <OverlayTrigger
                  placement='left'
                  overlay={
                    <Tooltip id='tooltip-message'>
                      <ul style={{textAlign:'left'}}>
                        {
                          respuesta.whoslikes ? respuesta.whoslikes.map((who,i)=>(
                            <li key={i}>{who.nombre_usuario}</li>
                          )):''
                        }
                      </ul>
                    </Tooltip>
                  }
                >
                  <label style={{marginLeft:'5px'}}>{likes}</label>
                </OverlayTrigger> : <label style={{marginLeft:'5px'}}>{likes}</label>}
        </div>
        <div className="col-md-2">
        {
            respuesta.img_usuario === null ? <img
           
                  alt=""
                  src='/images/user.png'
                 style={{width:'70px', height:'70px', borderRadius:'100%'}}
                /> :
              <img
                  alt=""
                  src={`${process.env.REACT_APP_API_URL}/${respuesta.img_usuario}`}
                  style={{width:'70px', height:'70px', borderRadius:'100%'}}
                />
            
        }
        </div>
        <div className="col-md-5">
        <h4>{respuesta.nombre_usuario + ' ' + respuesta.apellido_usuario}</h4>
        <p>Publicado el: {respuesta.fecha_registro}</p>
        </div>
        { respuesta.autor === usuario.id_usuario ? <div className="col-4 p-3 text-right"> <button className="btn btn-danger" onClick={()=>eliminarRespuesta({id_respuesta:respuesta.id_respuesta,video:id_video,sesion:id_sesion,id_usuario:usuario.id_usuario})}><FontAwesomeIcon icon={faTrash} /></button> </div> : ''}
        <div className="col-12 p-3 mt-2" style={{fontSize:'13px', borderTop:'1px solid #ebe'}} >  <ReadMore> {respuesta.respuesta}</ReadMore></div>
</div>
    );
};

export default Respuesta;