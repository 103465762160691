import React from "react";
import Swal from "sweetalert2";

import { connect } from "react-redux";
import {
  listarTareas,
  subirTareas,
  listarTareasEstudiante,
  eliminarTareas,
} from "../../../actions/cursosActions";
import { useState } from "react";
import ModalTareas from "./ModalTareas";

const TablaTareas = (props) => {
  //State
  const { tareas, usuario } = props;

  //Fuciones
  const {
    subirTareas,
    listarTareasEstudiante,
    listarTareas,
    eliminarTareas,
  } = props;

  const { detalleNivel, idSesion, idCurso } = props;
  const [subiendo, setSubiendo] = useState(false);
  const [carga, setCarga] = useState(null);
  const [tarea, setTarea] = useState({});
  const mensajeArchivoSubir = () => {
    return (
      <div className="alert alert-dismissible alert-light mt-3 py-2">
        Subiendo
      </div>
    );
  };

  const eliminarTarea = (tarea) => {
    const dataEliminar = {
      id_usuario: usuario.id_usuario,
      url_tarea: tarea.ruta_tarea,
    };
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger",
      },
      buttonsStyling: false,
    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "No se podra recuperar el archivo",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function () {
          //consultando para ver el mensaje de aprovación
          if (
            (await eliminarTareas(tarea.id_tareas_estudiante, dataEliminar))
              .mensaje
          ) {
            return true;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "La hubo un error al eliminar el archivo",
            });
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      })
      .then((result) => {
        //mensaje que se cambio la contraseña satisfactoriamente
        if (result.value === true) {
          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "El archivo se elimino exitosamente",
            "success"
          );
        }
      });
  };

  const listarTareasSubidas = async (tarea, idTarea) => {
    //Guardar tareas seleccionada
    setTarea(tarea);
    //indeicar el termino de la carga
    setCarga(null);

    const { mensaje } = await listarTareasEstudiante(
      idTarea,
      detalleNivel[0].id_estudiante
    );

    setCarga(mensaje);
  };

  return (
    <React.Fragment>
      <div className="d-flex align-items-center">
        {subiendo ? mensajeArchivoSubir() : null}
      </div>
      {tareas.length > 0 ? (
        <>
          <div className="alert alert-dismissible alert-light mensajes-info mt-1">
            Sube tu tarea en el plazo asignado por el docente aquí.
          </div>
          {tareas.map((tarea) => (
            <Tarea
              key={tarea.id_tarea}
              tarea={tarea}
              listarTareasSubidas={listarTareasSubidas}
            />
          ))}
        </>
      ) : (
        <div className="alert alert-dismissible alert-light mensajes-info mt-1">
        Sube tu tarea en el plazo asignado por el docente aquí.
        </div>
      )}
      <ModalTareas carga={carga} tarea={tarea} idSesion={idSesion} />
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  detalleNivel: state.usuario.detalleNivel,
  tareas: state.sesiones.tareas,
  usuario: state.usuario.usuario,
});

const mapDispatchToProps = {
  listarTareasEstudiante,
  subirTareas,
  listarTareas,
  eliminarTareas,
};

export default connect(mapStateToProps, mapDispatchToProps)(TablaTareas);

const style = {
  icon: {
    fontSize: "20px",
  },
};

const Tarea = ({ tarea, listarTareasSubidas }) => {
  return (
    <div className="container-recursos d-flex justify-content-between border-top">
      <div className="nombre-archivo p-3">
        <a
          href={`https://docs.google.com/viewerng/viewer?url=${process.env.REACT_APP_API_URL}/${tarea.ruta_tarea}`}
          target="_black"
          className="mb-0 text-uppercase"
        >
          <p className="text-info">
            <span>
              <i className="fas text-dark fa-chevron-circle-right mr-2"></i>
            </span>
            {tarea.nombre_tarea}
          </p>
        </a>
      </div>
      <div className="icon-archivo p-3" style={style.icon}>
        <a
          //  onClick={() => eliminarTarea(tarea)}
          className="btn btn-sm"
          href={`https://docs.google.com/viewerng/viewer?url=${process.env.REACT_APP_API_URL}/${tarea.ruta_tarea}`}
          target="_black"
          title="Ver tarea"
        >
          <img src="/images/education.png" alt="" />
        </a>
        <button
          //  onClick={() => eliminarTarea(tarea)}
          className="btn btn-sm"
          data-toggle="modal"
          onClick={() => listarTareasSubidas(tarea, tarea.id_tarea)}
          data-target="#modal-tareas"
          title="Subir tarea"
        >
          <img src="/images/upload.png" alt="subir tarea" />
        </button>
      </div>
    </div>
  );
};
