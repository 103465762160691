import { alertaTypes } from "../actions/types";

const initialState = {
  show: false,
  id_empleo:null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case alertaTypes.SHOW_DETALLE_EMPLEO:
      return {
        ...state,
        show: !state.show
      }
    case alertaTypes.SET_ID_EMPLEO:
    return {
      ...state,
      id_empleo: action.payload
    }
    default:
      return state;
  }
}