import React, { useState } from 'react'
import '../contenidoProyecto.css'
//Redux
import {useDispatch, useSelector} from 'react-redux'
import { useEffect } from 'react';
import { listarDirectorio } from '../../../actions/cursosActions';
import Directorio from './Directorio';
import Pagination from './Pagination';
import { useParams } from 'react-router-dom';
const GrupoAcademico = () => {

    //State

    const dispatch = useDispatch()
    const {idCapacitacion} = useParams()
    const [grupoTrabajo,setGrupoTrabajo] = useState([])
    const [datosGrupo,setDatosGrupo] = useState(null)
    const proyecto = useSelector(state=>state.cursos.proyecto)
    const usuario = useSelector(state=> state.usuario.usuario);
    //console.log(proyecto)
    useEffect(() => {

    const fetchData = async (id,estudiante) => {
        const rpta = await dispatch(listarDirectorio(id,estudiante))
       // console.log(rpta)
        setGrupoTrabajo(rpta.grupo)
        setDatosGrupo(rpta.datos_grupo)
    }

    fetchData(idCapacitacion,usuario.id_usuario)
      
    }, [])

    return ( 
        <>
            <div className="content-header header-vista-content clearfix">
              <div className="row">
                <div className="col-md-8">
                    <h4 className='font-20 text-uppercase'>
                        Grupo Academico : { datosGrupo?.nombre_grupo? datosGrupo.nombre_grupo : '' }
                    </h4>
                    <p>{datosGrupo?.descripcion_grupo ? datosGrupo.descripcion_grupo : ''}</p>
                </div>
        
              </div>
            </div>
            <div className="body-calendar my-3 p-2">

              {
                grupoTrabajo?.length ? grupoTrabajo.map((estudiante, i) => (
                    <Directorio key={i} persona={estudiante}/>
                  )) : ''
              }
              
            </div>
        </>
     );
}


export default GrupoAcademico;