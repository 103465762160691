import React from 'react';
import { Table, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

const TablaReciboMensualidad = ({ mensualidad  = {}}) => {

  const { concepto, monto_pagado, id_registro_pago } = mensualidad;

  return ( 
    <Table responsive='sm'>
      <thead>
        <tr>
          <th className='text-center'>#</th>
          <th className='text-center'>Concepto</th>
          <th className='text-center'>Total</th>
          <th className='text-center'>Estado</th>
          <th className='text-center'>Descargar</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td align='center'>1</td>
          <td align='center'>{concepto}</td>
          <td align='center'>S/{monto_pagado}</td>
          <td align='center'>
            <Badge variant='success'>
              Pagado
            </Badge>
          </td>
          <td align='center'>
            <a href={`${process.env.REACT_APP_API_URL}/admin/pdf/recibo_ingreso/${id_registro_pago}`}>
              <FontAwesomeIcon icon={faFileDownload} />
            </a>
          </td>
        </tr>
      </tbody>
    </Table>
   );
}
 
export default TablaReciboMensualidad;