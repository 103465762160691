import React from 'react';
import "./Empleo.scss"

const Empleo = ({datos}) => {
    return (
        <div className="row work-item d-flex p-2 border m-1">
            <div className="col-2 work-item-img d-flex justify-content-center align-items-center">
                <img src="/images/bolsa-item.png" alt=""  className="img img-fluid" />
            </div>
            <div className="col-10 d-flex work-item-details flex-column align-items-start p-2">
                <h6>{datos.nombre_puesto}</h6>
                <p>{datos.empresa}</p>
            </div>
        </div>
    );
};

export default Empleo;