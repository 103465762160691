import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import Modal from './Modal'
import authAxios from '../../config/authAxios';
import './ModalDetalleEmpleo.scss';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { showDetalle } from '../../actions/alertaActions';
//import { CSSTransition,TransitionGroup } from 'react-transition-group';
import * as moment from 'moment'
import { Avatar } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { postularEmpleo } from '../../actions/cursosActions';
const DetalleEmpleo = () => {
  const [empleo, setEmpleo] = useState(null);

  const {show, id_empleo} = useSelector(state => state.detalleEmpleo);
  const usuario = useSelector(state=> state.usuario.usuario);
  const dispatch = useDispatch();

  const getEmpleo = async () => {
   
    const empleo_ = await authAxios.get(`/admin/detalleEmpleo/${id_empleo}`);
    setEmpleo(empleo_.data.empleo);
  }

  useEffect(() => {
    if (show) {
      getEmpleo();
      window.scrollTo(0, 0)
      window.document.body.style.overflow = "hidden"
    }
    else{
      window.document.body.style.overflow = "auto"
    }
  }, [show])


  if (!empleo) return null; 

  return (
  
    <Modal
        active={show && empleo ? true : false}
       // toggle={() => dispatch(showDetalle())}
      
      >
       <div className="detalle">
          <span className="d-flex justify-content-center align-items-center" style={{position:'absolute', width:'30px', height:'30px', background:'#fff', borderRadius:'100%', fontSize:'20px', padding:'2px',right:20, top:20, cursor:'pointer'}} onClick={()=> { 
            dispatch(showDetalle())
            setEmpleo(null) 
          } }>
            <FontAwesomeIcon icon={faTimes} />
          </span>
          <img src="/images/detalleEmpleoHeader.jpg" alt="Avatar" style={{width:'100%', zIndex:'1'}} />
         
          
          <div className="detalle-body">
            <div className="image-detalle">
              <div style={{backgroundColor:'#5FBAA7',borderRadius:'5px', padding:'5px'}}>
                <img src="/images/bolsaTrabajo.png" style={{width:'100%'}} alt="Bolsa de Trabajo" />
              </div>
            </div>
            <div className="contenido-detalle">
              <div className="head-detalle">
                <h4><b>{empleo.nombre_puesto}</b></h4>
                <div className="cab d-flex justify-content-between">
                  <div style={{padding:'5px'}} > {empleo.empresa} </div>
                  <div style={{padding:'5px', fontSize:'11px'}} > <p>Publicado {moment(empleo.fecha_publicacion).startOf('day').fromNow()} </p>  <p>  <strong>{empleo.postulaciones.length} postulantes </strong> </p></div>
                </div>
              </div>
              <div className="row p-1">
                <div className="col-6 col-md-2 d-flex p-2">
                { 
                  empleo.postulaciones.length>0 ? <>
                      {
                        empleo.postulaciones.map((discente, index) => {
                      if(index > 2) return null
                      
                      if(discente.img_usuario === null) return <Avatar
                          key={index}
                          alt=""
                          src='images/user.png'
                          style={{marginLeft:'-9px'}}
                          />
                      
                              return (
                              <Avatar
                                  key={index}
                                  alt=""
                                  src={`${process.env.REACT_APP_API_URL}/${discente.img_usuario}`}
                                  style={{marginLeft:'-9px'}}
                                  />
                              )
                          })
                          
                        
                      }  { empleo.postulaciones.length>2?<Avatar style={{marginLeft:'-9px', fontSize:'11px'}}> +{empleo.postulaciones.length-3}</Avatar> :''  }  </> : <p className="text-center"><strong>Sé el primero en postular!</strong></p>
                  }
                  
              </div>
              <div className="col-6 col-md-4 p-3">  
                <p style={{textAlign:'left'}}> {empleo.postulaciones.length} amigos postularon</p>
              </div>
              <div className="col-12 col-md-6 p-3" >  
                <p style={{textAlign:'right'}}><strong>Categoría:</strong> <u>{empleo.categoria}</u></p>
              </div>
            </div>
            <div style={{marginTop:'20px'}} >
                <Table>
                <thead>
                  <tr>
              
                    <th>Experiencia</th>
                    <th>Tipo de Empleo</th>
                    <th>Oferta de Salario</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> {empleo.experiencia} año(s)</td>
                    <td> {empleo.tiempo_empleo} </td>
                    <td>S/. {empleo.salario} / mes</td>
                  </tr>
       
                </tbody>
                </Table>
              </div>
              <div className="p-2">
                 <h4> <b>Descripción:</b></h4>
                 <div className="p-1" dangerouslySetInnerHTML={{__html:empleo.descripcion_puesto ? empleo.descripcion_puesto : 'No hay descripción.'}} ></div>
        
              </div>
              <div className="p-2">
                  <h4><b>Funciones:</b></h4>
                  <div className="p-1" dangerouslySetInnerHTML={{__html:empleo.funciones?empleo.funciones : 'No hay funciones establecidas.'}} ></div>
              </div>
              <div className="row p-3">
                <div className="col-12 col-md-6 p-2">
                  <p><b>¿Te intenresa el puesto?</b></p>
                </div>
                <div className="col-12 col-md-6 p-2 d-flex justify-content-end">
                  <button className="btn btn-primary" onClick={()=>{dispatch(postularEmpleo({id_usuario:usuario.id_usuario, id_trabajo:empleo.id_trabajo}))
                  window.open(empleo.link_contacto, '_blank').focus();}} >Postular ahora</button>
                </div>
              </div>
            </div>
          </div>
        </div>
       { //<Modal.Footer>
         // <Button variant="danger" onClick={() => dispatch(showAlerta())}>
        //    Close
        //  </Button>
       // </Modal.Footer>
        }
      </Modal>
   );
}
 
export default DetalleEmpleo;