import { faEnvelope, faEnvelopeOpen, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import FloatingWhatsAppButton from "../../WhatsappButton/FloatingWhatsAppButton";

//REDUX
import {connect} from 'react-redux'

const style = {
  boton: {
    background: "#28314A",
  }
}

const Menu = (props) => {

  //FUNCIONES
  const { setPanel, setMensajesSeleccionados} = props
  //STATE
  const { mensajesNoVistos, mensajesPapelera, panel} = props

  return (
    <React.Fragment>
      <div className="pos-f-t d-none">
        <div className="collapse" id="navbarToggleExternalContent">
          <div className="bg-dark p-4">
            <h5 className="text-white h4">Collapsed content</h5>
            <span className="text-muted">Toggleable via the navbar brand.</span>
          </div>
        </div>
        <nav className="navbar navbar-dark bg-dark">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarToggleExternalContent"
            aria-controls="navbarToggleExternalContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </nav>
      </div>

      <div className="container menu_mensajes ">
        <div className="position-relative clearfix mb-2">
          <button
      
            type="button"
            onClick={e => {
              e.preventDefault();
              setPanel("nuevo");
              setMensajesSeleccionados([])
            }}
            className="btn btn-primary btn-block float-right"
          >
            <FontAwesomeIcon icon={faPlus}/> Nuevo
          </button>
        </div>
        <ul className="list-group">
          <li
            onClick={e => {
              e.preventDefault();
              setPanel("entrada");
              if(panel !== 'entrada'){
                setMensajesSeleccionados([])
              }
            }}
            className="hover-light cursor-pointer list-group-item px-1 border-left-0 border-right-0 py-2 border-top-0 d-flex justify-content-between align-items-center"
          > 
            <span>
            
            <FontAwesomeIcon icon={faEnvelopeOpen}/> Recibidos
            </span>
            { mensajesNoVistos === 0 ?  '' : <span className="badge badge-danger badge-pill">{mensajesNoVistos}</span>}
          </li>
          <li
            onClick={e => {
              e.preventDefault();
              setPanel("enviados");
              if (panel !== 'enviados') {
                setMensajesSeleccionados([])
              }
            }}
            className="hover-light cursor-pointer list-group-item px-1 border-left-0 border-right-0 py-2 border-top-0 d-flex justify-content-between align-items-center"
          >
          <span>
            <FontAwesomeIcon icon={faEnvelope} /> Enviados
          </span>  
            
          </li>
          <li onClick={e => {
            e.preventDefault();
            setPanel("papelera");
            if (panel !== 'papelera') {
              setMensajesSeleccionados([])
            }
          }} className="hover-light cursor-pointer list-group-item px-1 border-left-0 border-right-0 py-2 border-top-0 d-flex justify-content-between align-items-center">
          <span>
            <FontAwesomeIcon icon={faTrashAlt} /> Papelera
          </span>  
          { mensajesPapelera.length === 0 ?  '' : <span className="badge badge-danger badge-pill">{mensajesPapelera.length}</span>}
          </li>
        </ul>
      </div>
      <FloatingWhatsAppButton />
    </React.Fragment>
  );
};
 
const mapStateToProps = state => ({
  mensajesNoVistos: state.mensajes.mensajesNoVistos,
  mensajesPapelera: state.mensajes.mensajesPapelera
})

export default connect(mapStateToProps, {})(Menu);