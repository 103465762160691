import React, { useEffect, useState } from "react";
// import "./proyecto.css";
import "./Proyectos.scss";
/*Redux*/
import { connect, useSelector, useDispatch } from "react-redux";
import {
	listarProyectos,
	listarProximasSesiones,
} from "../../actions/cursosActions";
import { showEncuesta } from "../../actions/encuestaActions";
import Layout from "../Layout/Layout";
import Proyecto from "./Proyecto";
import AlertaMensualidad from "../AlertaMensualidad/";
import FloatingWhatsAppButton from "../WhatsappButton/FloatingWhatsAppButton";

//Static files
import posterHero from "../../static/portada.png";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card } from "react-bootstrap";
import ListaEmpleos from "../BolsaDeTrabajo/listaEmpleos";
import ProximasSesiones from "../ProximasSesiones/ProximasSesiones";
import { Link } from "react-router-dom";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { alertaTypes } from "../../actions/types";
import CarouselButtons from "./CarouselButtons";

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};

const StyleSpinner = {
	margin: "200px auto 0",
	width: "70px",
	textAlign: "center",
};

const StyleSpinnerDiv = {
	width: "18px",
	height: "18px",
	backgroundColor: "#28314A",
};

const Cursos = (props) => {
	const { estado, proyectos, detalleNivel, usuario } = props;
	const show = useSelector((state) => state.encuesta.show);
	const [resumenes, setResumenes] = useState();
	const dispatch = useDispatch();
	//Funciones
	const { listarProyectos, listarProximasSesiones } = props;
	const [tiempoDia, setTiempoDia] = useState("morning");
	
	//console.log(proyectos);
	useEffect(() => {
		//console.log(show);
		if (!show) {
			dispatch(showEncuesta());
		}
		dispatch({
			type: alertaTypes.HIDE_REPRODUCTOR,
		});
	}, []);
	
	useEffect(() => {
		const tiempo = new Date();
		const hours = tiempo.getHours();
		//console.log(hours)
		hours < 13
			? setTiempoDia("morning")
			: hours < 19
			? setTiempoDia("afternoon")
			: setTiempoDia("evening");
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			listarProximasSesiones(detalleNivel[0].id_estudiante).then(
				(resp) => {
					setResumenes(resp);
					
				}
			);
			await listarProyectos(detalleNivel[0].id_estudiante);
		};
		fetchData();
	}, []);
	console.log(resumenes)
	return (
		<Layout>
			{/* Actualización del diseño del apartado de proyectos, añadiendo la portada Hero, 
			se cambió el diseño del menú hamburguesa  */}
			{/* Portada Hero */}
			<div className="hero-container">
				<div className="hero-content">
					<p>
						Conviértete en un <br /> <strong>Life-Long Learner</strong>
					</p>
				</div>
				
				<div className="hero-image-container align-self-end d-none d-lg-block">
					<div>
						<img
							src="images/persona_portada.png"
							alt="persons"
							className="img-fluid"
						/>
					</div>
				</div>
			</div>
			{/* Fin Portada Hero */}
			
			<div className="container-fluid main-section">
				<AlertaMensualidad />
				<div className="row">
					<div className="col-md-12 p-5 ">
						<div className="row mb-3">
							<div className="col-md-6 d-flex align-items-center">
								<span
									className="user-message"
									style={{ marginRight: "8px" }}
								></span>
								<h2>
									{tiempoDia === "morning"
										? "¡Buenos días"
										: tiempoDia === "afternoon"
										? "¡Buenas tardes"
										: "¡Buenas noches"}
									, {usuario.nombre_usuario}!
								</h2>
							</div>
						</div>
						
						{/* Remember Section */}
						<div className="row">
							{/* Next Sesions Section */}
							<div className="col-12 col-md-6 mb-3 p-lg-5">
								<Card className="p-4 card-next-sesions">
									<h2>Ingresa a tu clase de hoy aquí</h2>
									<div>
										<ProximasSesiones
											sesiones={resumenes?.sesiones}
										/>
									</div>
								</Card>
							</div>
						</div>
					</div>
				</div>
					
				{/* Mis Cursos Sections */}
				<div className="row">
					<div className="col-12 p-5">
						<div className="col-12">
							<h2 style={{ marginBottom: "15px" }}>Cursos:</h2>
						</div>
						<div className="col-12 p-0">
							{estado !== 4 ? (
								<div style={StyleSpinner} className="spinner">
									<div
										style={StyleSpinnerDiv}
										className="bounce1"
									></div>
									<div
										style={StyleSpinnerDiv}
										className="bounce2"
									></div>
									<div
										style={StyleSpinnerDiv}
										className="bounce3"
									></div>
								</div>
							) : proyectos.length ? (
								<Carousel
									swipeable={true}
									draggable={false}
									// showDots={true}
									responsive={responsive}
									ssr={true} // means to render carousel on server-side.
									infinite={false}
									autoPlay={false}
									autoPlaySpeed={1000}
									keyBoardControl={true}
									transitionDuration={500}
									renderButtonGroupOutside
									showDots
									renderDotsOutside
									containerClass="container-padding-bottom"
									customButtonGroup={<CarouselButtons />}
									removeArrowOnDeviceType={[
										"tablet",
										"mobile",
										"desktop",
									]}
									itemClass="p-2 p-lg-3"
								>
									{proyectos.map((proyecto) => (
										<div key={proyecto.id_proyecto}>
											<Proyecto proyecto={proyecto} />
										</div>
									))}
								</Carousel>
							) : (
								<p>No hay cursos en progreso</p>
							)}
						</div>
					</div>
				</div>
				<div className="col-md-12">
					<h2>Recuerda...</h2>
				</div>
				<div className="row">
					<div className="col-md-12 remember-section">
						<div className="row p-3">
							<div className="col-12 col-md-6 col-lg-3 remember-container mb-3 mb-lg-0 mr-3 mr-md-5">
								<h5>Próxima evaluación:</h5>
								<div className="row p-2 m-1 remember-item">
									{resumenes?.evaluaciones.length >
										0 ? (
										<>
											<div className="col-xl-3 mr-xl-3 mb-1 mb-md-2 mb-xl-0 remember-item-icon">
												<img
													src="/images/icons/file-icon.png"
													className="img-fluid remember-icons"
													alt="file icon"
												/>
											</div>
											<div className="col-xl-8 remember-item-content">
												<div className="row">
													<div className="col-12 d-flex flex-column align-items-start p-1">
														<h6>Sesión</h6>
														<p>
															{
																resumenes
																	?.evaluaciones[0]
																	.nombre_sesion
															}
														</p>
													</div>
													<div className="col-12 mt-2 calendar-container">
														<img
															src="images/icons/calendar-icon.png"
															alt="calendar"
															className="calendar-icon"
														/>
														<span>
															{" "}
															{
																resumenes
																	?.evaluaciones[0]
																	.fecha_sesion
															}{" "}
														</span>
													</div>
												</div>
											</div>
										</>
									) : (
										<p>
											No hay evaluaciones
											pendientes
										</p>
									)}
								</div>
							</div>
							<div className="col-12 col-md-6 col-lg-3 remember-container mb-3 mb-lg-0 mr-md-5">
								<h5>Próxima revisión TAF:</h5>
								<div className="row p-2 m-1 remember-item">
									{resumenes?.taf.length > 0 ? (
										<>
											<div className="col-xl-3 mr-xl-3 mb-1 mb-md-2 mb-xl-0 remember-item-icon">
												<img
													src="/images/icons/doc-icon.png"
													className="img-fluid remember-icons"
													alt="file icon"
												/>
											</div>
											<div className="col-xl-8 remember-item-content">
												<div className="row">
													<div className="col-12 d-flex flex-column align-items-start p-1">
														<h6>Sesión</h6>
														<p>
															{
																resumenes
																	?.taf[0]
																	.nombre_sesion
															}
														</p>
													</div>
													<div className="col-12 mt-2 calendar-container">
														<img
															src="images/icons/calendar-icon.png"
															alt="calendar"
															className="calendar-icon"
														/>
														<span>
															{" "}
															{
																resumenes
																	?.taf[0]
																	.fecha_sesion
															}{" "}
														</span>
													</div>
												</div>
											</div>
										</>
									) : (
										<p>
											No hay presentaciones
											pendientes
										</p>
									)}
								</div>
							</div>
							
							<div className="col-12 col-md-6 col-lg-3 remember-container mb-3 mb-lg-0 mr-md-5">
								<h5>Próximo pago:</h5>
								<div className="row p-2 m-1 remember-item">
									{resumenes?.pagos.length > 0 ? (
										<>
											<div className="col-4 col-md-3 d-flex align-items-center">
												<img
													src="/images/payment_2.png"
													className="img-fluid"
													alt="payment"
												/>
											</div>
											<div className="col-8 col-md-9">
												<div className="row">
													<div
														className="col-md-12 d-flex mb-2 p-0"
														style={{
															textAlign:
																"left",
															fontSize:
																"14px",
														}}
													>
														<span>
															<FontAwesomeIcon
																icon={
																	faCalendarAlt
																}
																style={{
																	marginRight:
																		"8px",
																}}
															/>
															Vencimiento:{" "}
															{
																resumenes
																	?.pagos[0]
																	.fecha_limite
															}{" "}
														</span>
													</div>

													<div className="mt-2 mt-md-0 p-0 col-md-5">
														<h6>Cuota:</h6>
														<h2
															style={{
																color: "red",
															}}
														>
															S/.{" "}
															{
																resumenes
																	?.pagos[0]
																	.monto_establecido
															}
														</h2>
													</div>
													<div
														className="col-md-7 p-0"
														style={{
															fontSize:
																"12px",
															padding:
																"5px",
														}}
													>
														<h6>
															Detalle:
														</h6>
														<p>
															{
																resumenes
																	?.pagos[0]
																	.lapso
															}
														</p>
													</div>
												</div>
											</div>
											{new Date().getDate() <=
												20 ? (
												<div
													className="col-12"
													style={{
														textAlign:
															"center",
													}}
												>
													<span
														style={{
															fontSize:
																"11px",
														}}
													>
														** Pága antes
														del 20 y obten
														el 10% de dscto.{" "}
													</span>{" "}
												</div>
											) : null}
										</>
									) : (
										<p>No hay cuotas pendientes</p>
									)}
								</div>
							</div>
						</div>
					</div>
					
					{/* List Works Sections */}
					<div className="col-12 col-md-6 p-lg-5">
						<Card className="p-4 card-list-works">
							<h2>Ofertas de empleo</h2>
							<div>
								<ListaEmpleos empleos={resumenes?.bolsa} />
							</div>
							{resumenes?.bolsa.length > 0 ? (
								<div className="p-2 work-actions">
									{" "}
									<Link
										className="btn"
										to="/bolsa-de-trabajo"
									>
										Ver más
									</Link>
								</div>
							) : (
								""
							)}
						</Card>
					</div>
				</div>
			</div>
			<FloatingWhatsAppButton />
		</Layout>
	);
};

const mapStateToProps = (state) => ({
	proyectos: state.cursos.proyectos,
	estado: state.cursos.estado,
	detalleNivel: state.usuario.detalleNivel,
	usuario: state.usuario.usuario,
});

export default connect(mapStateToProps, {
	listarProyectos,
	listarProximasSesiones,
})(Cursos);
