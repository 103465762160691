import React from 'react';


export const condiciones = [
  {
    label: "Punto 1",
    content: (
      <p>
        Garantizamos la apertura del servicio contratado -una vez superado el
        mínimo de discentes adquiridos- caso contrario la empresa se compromete
        a efectuar la devolución del 100% de la inversión total del servicio a
        excepción de comisiones bancarias externas a nuestra responsabilidad.
      </p>
    ),
  },
  {
    label: "Punto 2",
    content: (
      <p>
        Por la naturaleza de la contraprestación y servicio, una vez realizada
        la inversión la empresa no acepta devoluciones. Como es propio de los
        servicios, estos se desarrollan de manera escalonada o progresiva. La
        certificación forma parte del servicio.
      </p>
    ),
  },
  {
    label: "Punto 3",
    content: (
      <p>
        El cronograma de inversión es igual para todos los programas, siendo los
        días treinta (30) la última fecha para abonar las mensualidades del
        servicio. Podrán acceder al 10% de descuento de la inversión total los
        discentes que abonen hasta el día veinte (20) de cada mes, este
        descuento se aplica en cada mensualidad y por cada programa. Se
        restringe la oferta sobre oferta.
      </p>
    ),
  },
  {
    label: "Punto 4",
    content: (
      <p>
        Las inversiones posteriores al día treinta (30) de cada mes son afectas
        al pago de moras correspondientes al 1% de la inversión mensual por cada
        día de retraso.
      </p>
    ),
  },
  {
    label: "Punto 5",
    content: (
      <p>
        Reportaremos las deudas a las centrales de riesgo y de ser el caso se
        tomarán acciones legales necesarias para la recuperación de los derechos
        académicos.
      </p>
    ),
  },
];
