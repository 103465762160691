import React, { useState } from 'react'

import Tema from './Tema';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
const Foro = ({temas}) => {
    const [busqueda,setBusqueda]=useState('')
    const handleChange = e => {
      setBusqueda(e.target.value)
    }

    const temaFiltrado = temas ? temas.filter(
      tema => {
        return (
          tema
          .titulo_tema
          .toLowerCase()
          .includes(busqueda.toLowerCase()) ||
          tema
          .nombre_usuario
          .toLowerCase()
          .includes(busqueda.toLowerCase()) ||
          tema
          .apellido_usuario
          .toLowerCase()
          .includes(busqueda.toLowerCase()) 
        )
      }
    ) : null

    return ( 
        <>
            <div className="content-header header-vista-content">
            <div className="row p-2">
                <h4 className='col-8 font-20 text-uppercase'>
                    Temas del Foro
                </h4>
                <div className="col-4 d-flex align-items-center">
                  <input className="form-control" value={busqueda} onChange={handleChange} style={{marginRight:'5px'}} />
                  <FontAwesomeIcon icon={faSearch} size="lg"/>
                </div>
            </div>
            </div>
            <div className="body-calendar my-3 p-2">
              {
                temaFiltrado ? temaFiltrado.length!==0 ? temaFiltrado.map((tema, i) => (
                    <Tema key={i} tema={tema}/>
                  )) : 'No hay Temas recientes...': 'No hay Temas recientes...' 
              }
            </div>
        </>
     );
}


export default Foro;