import React, { useState } from "react";
import CanalesPago from "./CanalesPago";
import "../contenidoProyecto.css";
import FloatingWhatsAppButton from "../../WhatsappButton/FloatingWhatsAppButton";
//Redux
import { useSelector } from "react-redux";
import TablaPension from "./TablaPensiones/TablaPensiones";
import TablaPagosExtras from "./TablaPagosExtra";
import BasicModal from "../../Modals/BasicModal";
import ExaminarPensiones from "./ExaminarPensiones";

const EstadoCuenta = () => {
  const { pagos } = useSelector((state) => state.cursos);
  const [showModal, setShowModal] = useState(false);
  const [cuotaExaminar, setCuotaExaminar] = useState(null) 

  const onShowModal = () => setShowModal(true);
  const onCloseModal = () => setShowModal(false);

  return (
    <div className="content-estado-cuenta shadow-sm m-1 p-3">
      <BasicModal size="lg" showModal={showModal} onCloseModal={onCloseModal}>
        <ExaminarPensiones
          onCloseModal={onCloseModal}
          cuotaExaminar={cuotaExaminar}
        />
      </BasicModal>
      <div className="content-header header-vista-content py-1">
        <h4 className="mb-0 title text-uppercase font-20">estado de cuenta</h4>
      </div>
      <div className="content-body-cuenta pt-3">
        <TablaPension
          pagos={pagos}
          onShowModal={onShowModal}
          setCuotaExaminar={setCuotaExaminar}
        />
        <TablaPagosExtras pagosExtraordinarios={pagos.pagos_extraordinarios} />
      </div>
      <div className="content-canales-pago mt-4">
        <CanalesPago />
      </div>
      <FloatingWhatsAppButton />
    </div>
  );
};

export default EstadoCuenta;
