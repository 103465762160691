import React, { useEffect, useState } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { disLikeComentarioTemaForo, eliminarComent, likeComentarioTemaForo } from '../../actions/cursosActions';
import NuevaRespuesta from './NuevaRespuesta';
import Respuestas from './Respuestas';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Favorite, FavoriteBorder } from '@material-ui/icons';
import ReadMore from '../../components/ReadMore/ReadMore';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Comentario = ({comentario}) => {
    const dispatch=useDispatch()
    const[likes,setLikes]=useState(comentario.likes)
    const[liked,setLiked]=useState(comentario.mi_like)
    const usuario = useSelector(state=> state.usuario.usuario);
    const eliminarComentario = (id)=>{
      dispatch(eliminarComent(id))
    }
    useEffect(()=>{
      if(comentario)
      {
        //console.log(comentario)
        setLikes(comentario.likes)
        setLiked(comentario.mi_like)
      }
    },[comentario])
    return (
    <div className="row" style={{padding:'15px', marginBottom:'10px', borderBottom:'1px solid #EEEEED'}}>
            <div className="col-md-1 d-flex flex-column" >
              <FormControlLabel
                control={<Checkbox checked={liked} icon={<FavoriteBorder />} checkedIcon={<Favorite />} name="checkedH" onChange={(e)=> {
                  if(e.target.checked)
                  {
                    setLikes(likes+1) 
                    setLiked(!liked)
                    dispatch(likeComentarioTemaForo({id_ref:comentario.id_unique,slug:comentario.slug,id_usuario:usuario.id_usuario,propietario:comentario.autor}))
                  }
                  else
                  {
                    setLikes(likes-1)
                    setLiked(!liked)
                    dispatch(disLikeComentarioTemaForo({id_ref:comentario.id_unique,slug:comentario.slug,id_usuario:usuario.id_usuario}))
                  }}} />}
              />
               { comentario.whoslikes.length>0 ? <OverlayTrigger
                  placement='left'
                  overlay={
                    <Tooltip id='tooltip-message'>
                      <ul style={{textAlign:'left'}}>
                        {
                          comentario.whoslikes ? comentario.whoslikes.map((who,i)=>(
                            <li key={i}>{who.nombre_usuario}</li>
                          )):''
                        }
                      </ul>
                    </Tooltip>
                  }
                >
                  <label style={{marginLeft:'5px'}}>{likes}</label>
                </OverlayTrigger> : <label style={{marginLeft:'5px'}}>{likes}</label>}
            
            </div>
            <div className="col-md-2">
            {
                comentario.img_usuario === null ? <img
               
                      alt=""
                      src='/images/user.png'
                     style={{width:'70px', height:'70px', borderRadius:'100%'}}
                    /> :
                  <img
                      alt=""
                      src={`${process.env.REACT_APP_API_URL}/${comentario.img_usuario}`}
                      style={{width:'70px', height:'70px', borderRadius:'100%'}}
                    />
                
            }
            </div>
            <div className="col-md-5">
            <h4>{comentario.nombre_usuario + ' ' + comentario.apellido_usuario}</h4>
            <p>Publicado el: {comentario.fecha_registro}</p>
            </div>
            <div className="col-4 p-3 text-right">
            <NuevaRespuesta id_comentario={comentario.id_comentario} slug={comentario.slug} />
            { comentario.autor === usuario.id_usuario ?  <button className="btn btn-danger" onClick={()=>eliminarComentario({id_comentario:comentario.id_comentario,tema:comentario.slug,id_usuario:usuario.id_usuario})}><FontAwesomeIcon icon={faTrash} /></button> : ''}
            </div> 
            <div className="col-12 p-3 mt-2" style={{fontSize:'13px', borderTop:'1px solid #ebe'}} > <ReadMore>{comentario.comentario}</ReadMore></div>
            { comentario.respuestas.length>0 ? <Respuestas respuestas={comentario.respuestas} index={comentario.id_comentario} slug={comentario.slug} /> : '' }
      </div>
    );
};

export default Comentario;