import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import ReactPlayer from 'react-player'
import AudioPlayerControls from '../../components/AudioPlayerControls/AudioPlayerControls';
const StickPlayer = () => {
    const playerRef=useRef(null)
    const {show,podcast} = useSelector(state => state.reproductorShow);
    const [state,setState] = useState({
        playing:true,
        played:0,
        seeking:false,
    })
    const timeDisplayFormat="normal"
    const{playing,played} = state

    const format = (seconds) => {
        if (isNaN(seconds)) {
          return `00:00`;
        }
        const date = new Date(seconds * 1000);
        const hh = date.getUTCHours();
        const mm = date.getUTCMinutes();
        const ss = date.getUTCSeconds().toString().padStart(2, "0");
        if (hh) {
          return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
        }
        return `${mm}:${ss}`;
      };
      


    const handlePlayPause=()=>{
        setState({...state,playing:!state.playing})
    }
    const handleProgress=(changeState)=>{
        if(!state.seeking)
        { 
            setState({...state,...changeState})
        }
    }
    const handleSeekChange=(e,newValue)=>{
        setState({...state,played:parseFloat(newValue/100)})
    }
    const handleSeekMouseDown=(e)=>{
        setState({...state,seeking:true})
    }
    const handleSeekMouseUp=(e,newValue)=>{
        setState({...state,seeking:false})
        playerRef.current.seekTo(newValue/100)
    }


    const currentTime =
    playerRef && playerRef.current
      ? playerRef.current.getCurrentTime()
      : "00:00";

    const duration =
        playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00";
    const elapsedTime =
        timeDisplayFormat == "normal"
        ? format(currentTime)
        : `-${format(duration - currentTime)}`;

    const totalDuration = format(duration);


    if(!show) return null;
    return (
     <div className={`reproductor display-block`}>
        <div className="row p-3">
            <div className="col-md-6">
                <div className="row">
                    <div className="col-3 col-md-2 d-flex justify-content-center align-items-center">
                    <img
                        src={
                            podcast.img_usuario
                            ? `${process.env.REACT_APP_API_URL}/${podcast.img_usuario}`
                            : "/images/user.png"
                        }
                        className="img_pd"
                        alt=""
                    />
                    </div>
                    <div className="col-9 col-md-10 d-flex flex-column justify-content-center align-items-left datos-podcast">
                        <p>{podcast.nombre_archivo}</p>
                        <p className="p-1"> <strong>Sesión:</strong> {podcast.nombre_sesion} </p>
                    </div>
                </div>
              
            </div>
            <div className="col-md-6">
                <div className="playerWrapper">           
                    <ReactPlayer url={`${process.env.REACT_APP_API_URL}/${podcast.ruta_archivo}`}  
                    width='100%'
                    height='100%' 
                    playing={playing} 
                    style={{display:'none'}} 
                    onProgress={handleProgress} 
                    ref={playerRef}
                    volume={0.6} />
                    <AudioPlayerControls
                        onPlayPause={handlePlayPause}
                        playing={playing}
                        played={played}
                        onSeek={handleSeekChange}
                        onSeekMouseDown={handleSeekMouseDown}
                        onSeekMouseUp={handleSeekMouseUp}
                        elapsedTime ={elapsedTime}
                        totalDuration={totalDuration}
                     />
                </div> 
            </div>
        </div>
     </div>
    );
};

export default StickPlayer;