import { MOSTRAR_ERROR} from '../actions/types';

const initialState = {
    errorLogin: false
}

export default function(state = initialState, action){
    switch (action.type) {
        case MOSTRAR_ERROR:
            return{
                ...state,
                errorLogin: action.payload
            }
        default:
            return state;
    }
}