import React from 'react';
import { MensajeHead } from '../Styles';
import { PDFDownloadLink, ContainerDocumentos } from '../Styles';
import Contancia from '../Constancia/Constancia';

//Redux
import { useSelector } from 'react-redux';

const ConstanciaContainer = () => {

  const constancia = useSelector(state => state.cursos.constancia)

  return ( 
    <div className="contancia-estudio shadow-sm m-1 p-3">
      <div className="content-header header-vista-content py-1">
        <h4 className="mb-0 title text-uppercase font-20">Constancia de estudios</h4>
      </div>

      <ContainerDocumentos className="mensaje-contancia">
        <MensajeHead>¡Bien! diste el primer paso.</MensajeHead>
        <div className="mensaje d-flex">
          <p className="mb-0"> Ya puedes acceder a tu</p>
          <PDFDownloadLink
            document={<Contancia constancia={constancia} />}
            fileName="constancia-estudios.pdf"
            className=''
          >
            {({ blob, url, loading, error }) =>
              loading ? "Cargando constancia de estudios..." : "constancia de estudios."
            }
          </PDFDownloadLink>
          </div>
        </ContainerDocumentos>
    </div>
   );
}
 
export default ConstanciaContainer;