import { encuestaTypes } from "../actions/types";

const initialState = {
  show: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case encuestaTypes.SHOW_ENCUESTA:
      return {
        ...state,
        show: !state.show
      }
    default:
      return state;
  }
}