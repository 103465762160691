import React from 'react';


export const normasAcademicas = [
  {
    label: "De la asistencia y puntualidad",
    content: [
      <p>La asistencia a las sesiones es obligatoria.</p>,

      <p>
       El límite máximo de inasistencias permitido por programa académico es de 20% del total de sesiones, según corresponda. En el caso de exceder este límite, el discente desaprobara por inasistencias (DPI) el o los módulos correspondientes, invalidando las calificaciones que figuran en los registros de notas.</p>,

      <p>El inicio de las sesiones será puntual.</p>,

      <>
        <p>
        El gestor de servicio es el responsable del control de la asistencia de los discentes, y es el docente quien de manera opcional tendrá la facultad de pasar lista al inicio de la sesión, para lo cual -en el registro correspondiente- utilizará los siguientes signos:
        </p>
        <ul className='ml-3'>
          <li>Presente P.</li>
          <li>Ausente F.</li>
          <li>Tardanza T.</li>
        </ul>
        <p>
          Asimismo; es el responsable de hacer cumplir con exactitud el tiempo
          de receso establecido para el programa académico.
        </p>
      </>,

      <p>
        Cualquier justificación de inasistencia deberá tramitarse directamente
        con el gestor de servicio enviando un mensaje desde la intranet; dentro de las
        cuarenta y ocho (48) horas de sucedida la inasistencia.
      </p>,
    ],
  },
  {
    label: "De la calificación",
    content: [
      <p>
        El sistema de calificación es vigesimal. La nota mínima aprobatoria es de catorce (14) puntos para todo programa educativo. La evaluación final está compuesta en un 70% de los quiz.
      </p>,
      <p>
       La calificación final se obtiene de la evaluación del programa, pudiendo ser un trabajo aplicativo final o un examen.
      </p>,
      
    ],
  },
  {
    label: "De la Evaluación",
    content: [
      <p>
        Al inicio del programa académico, el gestor de servicio dará a conocer a los discentes los criterios de evaluación que se aplicarán durante el dictado del mismo, de acuerdo a las normas académicas.
      </p>,
      <p>
      Las evaluaciones pueden ser: <br/>
      <ul>
      <li>Examen individual. Conformado por veinte preguntas para marcar. Mantienen la estructura del quiz, pero son calificados. </li>
      <li>Examen grupal. Conformado por cinco preguntas para responder en grupo. A través de la resolución de problemas buscan aplicar lo aprendido en el desempeño laboral diario. </li>
      <li>Trabajo Aplicativo Final (TAF) grupal. Buscan promover la investigación. Se explican a detalle en la rúbrica. </li>
      </ul>
      </p>,
      <p>
       Las fechas fijadas por el gestor de servicio y docente para las evaluaciones, son de estricto cumplimiento por parte del discente. Las calificaciones se publican en la intranet. De tratarse de ausencias justificadas (ver punto 1.1.5) el discente tendrá como máximo diez (10) días calendarios posteriores a terminado el módulo para rendir su evaluación. Las evaluaciones extemporáneas están sujetas a derechos académicos.  </p>,
      <p>
      El discente podrá presentar remitir desde la intranet una solicitud formal de reconsideración de nota, y debidamente fundamentada. Esta solicitud sólo procederá dentro de las cuarenta y ocho (48) horas posteriores a la recepción de nota o evaluación por parte del discente.
      </p>,
    ],
  },
  {
    label: "Traslados",
    content: [
      <p>
       El discente puede solicitar su traslado a otro programa iniciado o por iniciar. Deberá abonar el derecho por gastos administrativos y de servicio que el traslado genere. El traslado puede ser interno o externo.
      </p>,
      <p>
      Para el caso de traslado interno, el discente matriculado regularmente en un programa puede solicitar un traslado cuando se ve imposibilitado de continuar sus estudios en el mismo, debido a causas justificadas. En programas similares o de la misma área se procederá a un reconocimiento de las notas y de inversión en los módulos concluidos debiendo cursar los módulos y regularizar los derechos académicos pendientes en el programa de destino. Su cronograma de pagos se adapta al del programa destino.
      </p>,
      <p>
       Para el caso de traslado externo, un discente que requiera convalidación con valor oficial puede solicitar su ingreso con el reconocimiento de las asignaturas y notas que ha cursado siempre y cuando coincidan con el temario vigente de nuestros programas. Debiendo presentar los certificados de notas de las asignaturas en original y sello del centro de estudios respectivo, una vez aprobada su incorporación por el tutor, tendrá que abonar el derecho correspondiente. Las notas no deben ser inferiores a catorce (14) puntos o su equivalente con el sistema de calificación vigesimal.
      </p>,
    ],
  },
  {
    label: "Retiros",
    content: [
      <p>
       Reembolso garantizado por 15 días. Si el discente no está satisfecho con el programa, puede contactarnos durante los 15 días desde la fecha de iniciado el servicio y le devolveremos lo invertido, sin preguntas.  </p>,
      <p>
       Reserva garantizada por 1 año. Si el discente se encuentra imposibilitado de continuar un programa, puede contactarse durante los 15 días desde la fecha de iniciado el servicio para reservar lo invertido y trasladarse a un programa similar futuro. 
     </p>,
      <p>
     El gestor de servicios es el responsable del tratamiento de los retiros. 
      </p>,
       <p>
      Se procederá a retirar un discente en los siguientes casos: <br/>
       <ul>
       <li>A solicitud voluntaria del discente. </li>
       <li>Por medida disciplinaria (suspensión temporal o definitiva).</li>
      </ul>
       </p>,
    ],
  },
  {
    label: "De la certificación",
    content: [
      <p>
        Obtendrán el certificado de aprobado los discentes que superen la calificación mínima establecida.</p>,
      <p>
       Obtendrán el certificado de asistente los discentes que no superen la calificación mínima establecida. </p>,
      <p>
     Los discentes que excedan el límite de inasistencias no tendrán derecho a certificación. </p>,
    ],
  },
  {
    label: "Del carácter disciplinario",
    content: [
      <p>
      Aquel discente que atente contra la buena marcha de la sesión, será motivo para que el gestor de servicios o docente solicite su retiro inmediato del aula. 
      </p>,
      <p>
     El discente será responsable del buen uso de la infraestructura y servicios que se le brinde.
    </p>,
      <p>
      El docente deberá orientar a los discentes a dirigirse  con el gestor de servicios cuando ellos planteen consultas de orden administrativo.
      </p>,
    ],
  },
  {
    label: "De las comunicaciones",
    content: [
      <p>
        La comunicación entre el discente y la empresa es a través de la bandeja de mensajes de la intranet.</p>,
      <p>
      La intranet permite todo tipo de solicitudes como duplicado de certificados, constancias de notas de estudios, justificaciones de inasistencias, sugerencias y otros similares. El gestor de servicio dará respuesta en un plazo de cuarenta y ocho (48) horas. 
 </p>,
      <p>
       El desarrollo de las sesiones se hará de acuerdo al temario propuesto para cada programa académico. En caso de suspensiones y/o reprogramaciones de sesión estos serán recuperados en el día y hora que el gestor de servicios lo indique, previa comunicación por la intranet.  </p>,
    ],
  },
  {
    label: "De la medición de la calidad y resultados",
    content: [
      <p>
       Usamos diversos quiz como herramienta para evaluar y medir el conocimiento aprendido por el discente en cada sesión. Tienen preguntas objetivas y se caracterizan por ser lúdicos, dinámicos, y mostrar automáticamente las respuestas. Está compuesto por lo enseñado en la sesión. No cuentan para calificaciones y están diseñados exclusivamente para ayudar al discente a evaluar su propio conocimiento y comprensión. 

      </p>,
       <p>
      Para el logro de la eficiencia y la eficacia en los resultados previstos medimos constantemente la calidad de nuestro servicio a través de  encuestas, y entrevistas a los discentes. 
<br/>Esto nos permite ponderar los componentes básicos del servicio académico ofrecido: logro de objetivos, metodología, organización, y una evaluación del desempeño del docente y gestor de servicios. 

      </p>,
    ],
  },
  {
    label: "Derechos Académicos",
    content: [
      <p>
       Garantizamos la apertura del servicio contratado -una vez superado el mínimo de discentes- caso contrario la empresa se compromete a efectuar la devolución del 100% de la inversión total del servicio a excepción de comisiones o derechos por conceptos externos a la empresa. 
      </p>,
       <p>
      El cronograma de inversión es igual para todos los programas, siendo los días treinta (30) la última fecha para abonar las mensualidades del servicio. Podrán acceder al 10% de descuento de la inversión total los discentes que abonen hasta el día veinte (20) de cada mes, este descuento se aplica en cada mensualidad y por cada programa. Não aplica oferta sobre oferta. En caso de acceder a más de una oferta el discente podrá acceder a la que más le beneficie. 
      </p>,
       <p>
       Las inversiones posteriores al día treinta (30) de cada mes son afectas al pago de moras correspondientes al 1% de la inversión mensual por cada día de retraso.  </p>,
        <p>
       Reportamos las deudas a las centrales de riesgo y de ser el caso se tomarán acciones legales necesarias para la recuperación de los derechos académicos.  </p>,
         <p>
         Se restringirá el acceso a clases a los discentes que no se mantengan al día en su inversión. Los mismos podrán acceder a un financiamiento sin ningún interés, siempre que su solicitud sea aprobada. 
   </p>,
    ],
  },
];
