import React, { useEffect, useState } from 'react';
import {Modal, Button } from 'react-bootstrap';
import Box from '@material-ui/core/Box';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { guardarComentario, showForm } from '../../actions/cursosActions';

const NuevoComentario = ({id_tema,slug,propietario}) => {

  const show = useSelector(state => state.foro.show_formulario);
  const usuario = useSelector(state=> state.usuario.usuario);

  const dispatch = useDispatch();
  const initialState = {
    comentario:'',
    tema:id_tema,
    slug: slug,
    id_usuario: usuario ? usuario.id_usuario : null,
    propietario: propietario? propietario : null,
  }
  const [datosTema,setDatosTema]=useState(initialState)

  const {comentario} = datosTema

  const handleChange = (evt) => {
    const value = evt.target.value;
    setDatosTema({
      ...datosTema,
      [evt.target.name]: value
    });
  }
  const enviarComentario = async (e) =>{
    e.preventDefault();
    dispatch(guardarComentario(datosTema))
    setDatosTema(initialState)
    dispatch(showForm())
  }

  return (
    <>
    <Button variant="primary" size="lg" className="float-right" onClick={() => dispatch(showForm())} style={{margin:'8px'}}>
     Comentar
    </Button>

    <Modal
      show={show}
      onHide={() => dispatch(showForm())}
      dialogClassName="modal-90w"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Nuevo Comentario
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form onSubmit={(e) => enviarComentario(e)}>

          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>Contenido del comentario</label>
            <textarea name="comentario" className="form-control" value={comentario} onChange={handleChange} />
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent" >          
          <button type="reset" className="btn btn-danger" onClick={() => dispatch(showForm())}>
           Cancelar
          </button>
          &nbsp;
          <button type="submit" className="btn btn-primary btn-submit mr-2" >
            Publicar Comentario
          </button>
          </Box>
      </form>
      </Modal.Body>
    </Modal>
  </>
   );
}
 
export default NuevoComentario;