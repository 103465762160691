import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';

const FilaTablaPagosExtra = props => {

  const { 
    index,
    concepto,
    fecha_limite,
    nombre_tp_estado_pago: estado,
    monto_concepto,
    id_factura
  } = props

  return ( 
    <tr>
      <td align='center'>
        <p>{index+1}</p>
      </td>
      <td align='center'>
        <p>{concepto}</p>
      </td>
      <td>
        <p>{fecha_limite}</p>
      </td>
      <td align='center'>
        <p>{monto_concepto}</p>
      </td>
      <td>
        <p>{estado}</p>
      </td>
      <td align='center'>
        <Button
          variant='link'
          href={`${process.env.REACT_APP_API_URL}/admin/pdf/factura/${id_factura}`}
          disabled={id_factura ? false : true}
          size='sm'
        >
          <FontAwesomeIcon 
            icon={faFilePdf}
            className={`cursor-pointer ${!id_factura && 'error'}`}
          /> 
        </Button>
      </td>
    </tr>
  );
}
 
export default FilaTablaPagosExtra;