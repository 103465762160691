import {
  ENVIAR_MENSAJE,
  REMOVER_ESTADO_MENSAJE,
  LISTAR_MENSAJES,
  LISTAR_PROFESORES,
  MOSTRAR_MENSAJE,
  LISTAR_MENSAJES_ENVIADOS,
  MENSAJES_NO_LEIDOS,
  ACTUALIZAR_CANTIDAD_MNV,
  LIMPIAR_MENSAJE,
  LISTAR_CONTACTOS,
  LISTAR_PAPELERA,
  MANDAR_MENSAJE_E_PAPELERA,
  MANDAR_MENSAJE_R_PAPELERA,
  RESTAURAR_MENSAJE,
  ELIMINAR_MENSAJE,
  ACTUALIZAR_TAB_MENSAJERIA,
  ACTUALIZAR_HILO
} from "../actions/types";

//Cada reducer tiene su propio state

const initialState = {
  mensajeEstado: 0,
  mensaje:{},
  hilo:'',
  panel:'entrada',
  bandejaMensajes: [],
  profesoresMensaje: [],
  mensajesNoVistos: 0 ,
  mensajesEnviados: [],
  mensajesPapelera: [],
  contactos: []
};

export default function(state = initialState, action){
    switch (action.type) {
        case ENVIAR_MENSAJE:

                return{
                    ...state,
                    mensajeEstado: action.payload.estado,
                    mensajesEnviados: action.payload.mensajes
                }
        case REMOVER_ESTADO_MENSAJE: 
            return{
                ...state,
                mensajeEstado: action.payload
            }
        case LISTAR_MENSAJES:
            return{
                ...state,
                bandejaMensajes: action.payload
            }
        case LISTAR_PROFESORES: 
            return{
                ...state,
                profesoresMensaje: action.payload
            }
        case MOSTRAR_MENSAJE:
            return{
                ...state,
                mensaje: action.payload,
                camensajesNoVistos: action.payload.cant_msj_bandeja
            }
        case LISTAR_MENSAJES_ENVIADOS:
            return {
              ...state,
              mensajesEnviados: action.payload
            };
        case MENSAJES_NO_LEIDOS:
            return {
                ...state,
                mensajesNoVistos: action.payload
            }
        case ACTUALIZAR_CANTIDAD_MNV:
            return{
                ...state,
                mensajesNoVistos: action.payload
            }
        case LISTAR_PAPELERA:
            return{
                ...state,
                mensajesPapelera: action.payload
            }
        case LISTAR_CONTACTOS:
            return{
                ...state,
                contactos: action.payload
            }
        case ACTUALIZAR_TAB_MENSAJERIA:
            return{
                ...state,
                panel: action.payload
            }
        case ACTUALIZAR_HILO:
            return{
                ...state,
                hilo: action.payload
            }
        case MANDAR_MENSAJE_E_PAPELERA:
            return{
                ...state,
                mensajesEnviados: action.payload.enviados,
                mensajesPapelera: action.payload.papelera
            }
        case MANDAR_MENSAJE_R_PAPELERA:
            return{
                ...state,
                bandejaMensajes: action.payload.bandeja,
                mensajesPapelera: action.payload.papelera
            }
        case RESTAURAR_MENSAJE:
            return{
                ...state,
                mensajesPapelera: action.payload
            }
        case ELIMINAR_MENSAJE:
            return{
                ...state,
                mensajesPapelera: action.payload
            }
        case LIMPIAR_MENSAJE:
            return{
                mensajeEstado: 0,
                mensaje: {},
                bandejaMensajes: [],
                profesoresMensaje: [],
                mensajesNoVistos: 0,
                mensajesEnviados: [],
                mensajesPapelera: []
            }
        default:
            return state
    }
}