import React, { useEffect } from "react";
import SimpleTabs from "../../components/Ui/Tabs/SimpleTabs";
import Modulos from "../../components/ContenidoProyecto/Modulos/Modulos";
import EstadoCuenta from "../../components/ContenidoProyecto/EstadoCuenta";
import ContentHorario from "../../components/ContenidoProyecto/Horario/ContentHorario";
import ValotarioNotas from "../../components/ContenidoProyecto/ValotarioNotas";
import Documentos from "../../components/ContenidoProyecto/Documentos";
import { Tabs, Tab } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AlertaEncuesta from "../../components/EncuestaCalidadServicio/EncuestaCalidadServicio";
//Redux
import { connect, useDispatch } from "react-redux";
import { listarProyecto, mostrarPagos } from "../../actions/cursosActions";
import Layout from "../../components/Layout/Layout";
import ContentComunidad from "../../components/ContenidoProyecto/Comunidad/ContentComunidad";
import ContentForo from "../../components/ContenidoProyecto/Foro/ContentForo";
import "./ContenidoEvento.scss";
import { ACTUALIZAR_TAB } from "../../actions/types";
//import { showEncuesta } from "../../actions/encuestaActions";
const ContenidoEvento = (props) => {
	const params = useParams();
	const { idCapacitacion } = params;
	//const show = useSelector(state => state.encuesta.show);
	//state
	const { detalleNivel } = props;
	const dispatch = useDispatch();
	//Funciones
	const { listarProyecto, mostrarPagos, currentTab } = props;
	//const dispatch = useDispatch();
	useEffect(() => {
		// console.log(show);
		if (detalleNivel.length > 0) {
			listarProyecto(detalleNivel[0].id_estudiante, idCapacitacion);
			mostrarPagos(detalleNivel[0].id_estudiante, idCapacitacion);
		}
	}, [detalleNivel]);

	return (
		<Layout>
			<AlertaEncuesta
				id_proyecto={idCapacitacion}
				id_estudiante={detalleNivel[0].id_estudiante}
			/>
			{/*<div className="container-vista-modulos">
        <SimpleTabs tabs={TABS} />
      </div>
      <div className="tab-content mt-3 " style={{ minHeight: "250px" }}>
        <div
          role="tabpanel"
          className="tab-pane table-responsive active"
          id="modulos"
        >
          <Modulos />
        </div>
        <div
          role="tabpanel"
          className="tab-pane table-responsive fade bg-white"
          id="estado-cuenta"
        >
          <EstadoCuenta idCapacitacion={idCapacitacion} />
        </div>
        <div
          role="tabpanel"
          className="table-responsive tab-pane fade"
          id="horario"
        >
          <ContentHorario />
        </div>
        <div
          role="tabpanel"
          className="table-responsive tab-pane fade"
          id="notas"
        >
          <ValotarioNotas />
        </div>
        <div
          role="tabpanel"
          className="table-responsive tab-pane fade"
          id="certificado"
        >
          <Documentos />
        </div>
        <div
          role="tabpanel"
          className="table-responsive tab-pane fade"
          id="comunidad"
        >
           <ContentComunidad/>
        </div>
        <div
          role="tabpanel"
          className="table-responsive tab-pane fade"
          id="foro"
        >
        <ContentForo/>
        </div>
      </div>*/}
			<div className="container contenido-tabs mt-4">
				<Tabs
					id="controlled-tab-example"
					activeKey={currentTab}
					onSelect={(k) =>
						dispatch({
							payload: k,
							type: ACTUALIZAR_TAB,
						})
					}
					className="mb-3"
				>
					<Tab
						eventKey="modulos"
						title="Módulos"
						tabClassName="contenido-tabitem"
					>
						<Modulos />
					</Tab>
					<Tab
						eventKey="estado-cuenta"
						title="Estado de Cuenta"
						tabClassName="contenido-tabitem"
					>
						<EstadoCuenta idCapacitacion={idCapacitacion} />
					</Tab>
					<Tab
						eventKey="horario"
						title="Cronograma de Estudios"
						tabClassName="contenido-tabitem"
					>
						<ContentHorario />
					</Tab>
					<Tab
						eventKey="notas"
						title="Calificaciones / Asistencias"
						tabClassName="contenido-tabitem"
					>
						<ValotarioNotas />
					</Tab>
					<Tab
						eventKey="certificado"
						title="Certificado"
						tabClassName="contenido-tabitem"
					>
						<Documentos idProyecto={idCapacitacion} />
					</Tab>
					<Tab
						eventKey="comunidad"
						title="Grupo de Trabajo / Comunidad"
						tabClassName="contenido-tabitem"
					>
						<ContentComunidad />
					</Tab>
					<Tab
						eventKey="foro"
						title="Foro"
						tabClassName="contenido-tabitem"
					>
						<ContentForo />
					</Tab>
				</Tabs>
			</div>
		</Layout>
	);
};

const mapStateToProps = (state) => ({
	detalleNivel: state.usuario.detalleNivel,
	currentTab: state.usuario.currentTab,
});

const mapDispatchToProps = {
	listarProyecto,
	mostrarPagos,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContenidoEvento);

const TABS = [
	{
		label: "Módulos",
		target: "modulos",
		estado: "active",
	},
	{
		label: "Estado de cuenta",
		target: "estado-cuenta",
	},
	{
		label: "Cronograma de estudio",
		target: "horario",
	},
	{
		label: "Calificaciones / Asistencias",
		target: "notas",
	},
	{
		label: "Certificado",
		target: "certificado",
	},
	{
		label: "Grupo de Trabajo / Comunidad",
		target: "comunidad",
	},
	{
		label: "Foro",
		target: "foro",
	},
];
