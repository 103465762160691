
import { faPause, faPlay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { alertaTypes } from '../../actions/types';
const PodCastInfo = ({datos}) => {
    const dispatch=useDispatch();
    const playPause = () =>{
        dispatch({
            type:alertaTypes.SHOW_REPRODUCTOR,
            payload:datos
        })
      //  dispatch(showDetalle())
    }
    return (
        <div className="col-12 p-2" style={{borderBottom:'1px solid #ebe', marginBottom:'8px'}}>
             <div className="row">
                <div className="col-3 col-md-2 d-flex justify-content-center align-items-center">
                <img
                    src={
                    datos.img_usuario
                        ? `${process.env.REACT_APP_API_URL}/${datos.img_usuario}`
                        : "/images/user.png"
                    }
                    className="img_pd"
                    alt=""
                />
                </div>
                <div className="col-9 col-md-10">
                    <h6 className="p-1"> {datos.nombre_archivo} </h6>   
                    <Badge className="bg-primary m-1 text-white p-2">
                        PodCast
                    </Badge>
                    <p className="p-1"> <strong>Docente:</strong> {datos.nombre_usuario} {datos.apellido_usuario} </p>
                    <p className="p-1"> <strong>Módulo:</strong> {datos.nombre_modulo} </p>
                    <p className="p-1"> <strong>Sesión:</strong> {datos.nombre_sesion} </p>
                    <button className="btn btn-primary btn-xs m-1" onClick={()=>playPause()}><FontAwesomeIcon icon={faPlay}/></button>
                </div>
             </div>
       </div>
    );
};

export default PodCastInfo;