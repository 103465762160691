import React, { useState, useEffect } from 'react';
import Modal from './Modal'
import authAxios from '../../config/authAxios';
import './AlertaMensualidadMensaje.scss';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { obtenerPopUp, showAlerta,showEstado } from '../../actions/alertaActions';
//import { CSSTransition,TransitionGroup } from 'react-transition-group';

const ToastMessage = () => {
  const [post, setPost] = useState(null);

  const show = useSelector(state => state.alerta.show);
  const showEstadoValue = useSelector(state => state.alerta.showEstado);


  const dispatch = useDispatch();

  const getFirtsPost = async () => {
   
    const posts = await authAxios.get(`/admin/verPagoCercano/`);
    setPost(posts.data.data);
  }

 


  useEffect(() => {

    if (show) {
      getFirtsPost();
      window.scrollTo(0, 0)
      window.document.body.style.overflow = "hidden"
    }
    else{
      window.document.body.style.overflow = "auto"
    }

  }, [show])


  if (!post) return null; 

  return (
  
    <Modal
        active={show && post ? true : false}
        toggle={() => dispatch(showAlerta())}
        bakground={post.SOLO_IMAGEN==="0"?post.FONDO:''}
      >
        <div className="contenedor-mensaje">
         {
          post.SOLO_IMAGEN==="0" ? 
          <>
          <div className="titulo_ini" dangerouslySetInnerHTML={{__html:post.TITULO}} >
          </div> 
          <div className='text-justify mensaje_ini' dangerouslySetInnerHTML={{__html:post.MENSAJE}} >
          </div> 
          <div className='text-left mt-3' style={{padding:'20px'}}>
            { 
              post.ENLACE ? <><a href={`${post.ENLACE}`} target='_blank' rel="noopener noreferrer" className='btn btn-danger'>
                Saber más
              </a></> : ''
            }
          </div> </> : <img src={process.env.REACT_APP_API_URL+"/files_inedi/fondos_pop/"+post.FONDO} className="img-fluid" style={{borderRadius:'5px', marginTop:'50px'}} alt="" />
          }
        </div>
      </Modal>
   );
}
 
export default ToastMessage;