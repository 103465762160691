import React from 'react';
import Opcion from './OpcionExamen';

const listarOpcionesExamen = ({ opciones, opcion_marcada, explicacion}) => {

  const mostrarOpciones = () => {
    if(opciones.length > 0) {
      return opciones.map(opcion => (
       <Opcion 
       key={opcion.id_opcion}
       {...opcion}
       opcion_respuesta={opcion_marcada}
     />
      ))
    } else {
      return <p>No hay opciones</p>
    }
  }

  const selecionarOpcionCorrecta = () => {
    if(opciones.length > 0) {
      return opciones.map(opcion => (
        opcion.opcion_correcta === '1' ?  opcion.opcion : ''
      ))
    } else {
      return <p>No hay opciones</p>
    }
  }
  return ( 
  <>  
  <div style={{display:'flex', flexDirection:'column'}} className="mb-3 form-check" >
      {mostrarOpciones()}
    </div>
    <div style={{display:'flex', flexDirection:'column'}} className="mb-3 form-check" >
      Opción Correcta: {selecionarOpcionCorrecta()}<br/>
      {explicacion && explicacion!='' ? 'Explicacion: '+explicacion : ''}
    </div>
  </>
  );
}
 
export default listarOpcionesExamen;