import React from 'react';
import shorid from 'shortid';
import {Link} from 'react-router-dom'

const style = {
  link: {
    fontSize: ".9167em",
    background: "fdfdfd"
  }
};

const Breadcrumb = ({data}) => {

    return (
      <div>
        {/* <div
        className="border mt-4 mb-3"
        style={{
         
        }}
      >
        <ol className="breadcrumb m-0 p-0" style={style.link}>
          {data !== undefined
            ? data.map(item => (
                <React.Fragment key={shorid.generate()}>
                  {migas(item)}
                </React.Fragment>
              ))
            : null}
        </ol>
      </div> */}
      </div>
      
    );
}
export default Breadcrumb;

const migas = (item) => {

  if(item.url !== ''){
    return (
      <li className="breadcrumb-item d-flex align-items-center">
        <Link to={item.url}>
          {item.title}
        </Link>
      </li>
    );
  }else{
     return (
       <li className="breadcrumb-item  d-flex align-items-center" aria-current="page">
         <a className="text-secondary">{item.title}</a>
       </li>
     );
  }
  
}