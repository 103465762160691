import { authTypes, RESTABLECER_ERROR } from './types';

export const removerTodo = () => {
  return dispatch => {
    window.sessionStorage.removeItem('user-JWT');
    dispatch({
      type: authTypes.REMOVER_TODO
    })
  }
}

export const mostrarErrorLogin = mensaje => {
  return dispatch => {
    dispatch({
      type: authTypes.DATOS_NECESARIOS,
      payload: mensaje
    })

    setTimeout(() => {
      dispatch({
        type: RESTABLECER_ERROR
      })
    }, 3000);
  }
}
