import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import authAxios from '../../config/authAxios';
import '../AlertaMensualidad/AlertaMensualidadMensaje.scss';
import Rating from '@material-ui/lab/Rating';
import Box from '@material-ui/core/Box';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { showEncuesta, guardarEncuesta } from '../../actions/encuestaActions';

const ToastMessage = ({id_proyecto,id_estudiante}) => {
  const [post, setPost] = useState(null);
  const [mostrarEncuesta, setMostrarEncuesta] = useState(false);
  const show = useSelector(state => state.encuesta.show);
  const [value, setValue] = React.useState(0);
  const [opinion, setOpinion] = React.useState('');
  const [display,setDisplay] = useState('none');
  const [disabled,setDisabled] = useState(false);
  //const usuario = useSelector(state=> state.usuario.usuario);
  const dispatch = useDispatch();

  const getFirtsPost = async () => {
   // const posts = await authAxios.get(`/admin/verPagoCercano/`);
   const posts = await authAxios.get(`/admin/validarEncuesta/${id_proyecto}/us/${id_estudiante}`);
    setPost(posts.data.data);
    if(!posts.data.encuesta_respondida)
    {
      if(posts.data.dentro_de_rango)
      {
        setMostrarEncuesta(true);
      }
    }
  }
const enviarEncuesta = async (e) =>{
  e.preventDefault();
  let dataEncuesta = {
    calificacion:value,
    opinion:opinion,
    id_proyecto_e:id_proyecto,
    id_estudiante_e:id_estudiante
  }
  let activar = dispatch(guardarEncuesta(dataEncuesta));
  setDisabled(activar);
}
  useEffect(() => {
    if (show) {
      getFirtsPost();
      window.scrollTo(0, 0)
      window.document.body.style.overflow = "hidden"
      if(!post)
      {
        window.document.body.style.overflow = "auto"
      }
    }
    else
    {
        window.document.body.style.overflow = "auto"
    }
  }, [show])


  if (!post) return null; 

  return (
  
    <Modal
    active={show && mostrarEncuesta ? true : false}
    toggle={() => dispatch(showEncuesta())}
    >
    <div style={{height:'340px'}}></div>
    <div style={{padding:'25px'}}>
        <p>El evento "{post.nombre_proyecto}", ha finalizado y nos encantaria conocer tu opinión.</p>
        <br></br>
        <p>¿Te hemos tratado bien y merecemos tu lealtad? ¿Hasta que punto recomendarías nuestro servicio?</p>
        <form onSubmit={(e) => enviarEncuesta(e)}>
            <Box component="fieldset" mb={3} borderColor="transparent">
            <Rating
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
                if(newValue>0)
                setDisplay('block');
                else
                setDisplay('none')
              }}
              max={10}
            />
            <p style={{fontSize:'11px'}}>Califica: Siendo 1: No lo recomendarías y 10: Lo recomendaría totalmente</p>
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent">
            <label>¿Qué le dirias a un amigo o familar sobre INEDI Posgrados?</label>
            <input type="text" className="form-control" value={opinion} onChange={(e)=>{
              setOpinion(e.target.value)
            }}/>
          </Box>
          <Box component="fieldset" mb={3} borderColor="transparent" display={display}>
          <button type="submit" className="btn btn-primary btn-submit" disabled={disabled}>
                          Valorar servicio
          </button>
          </Box>
        </form>
    </div>
  </Modal>
   
   );
}
 
export default ToastMessage;