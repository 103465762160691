import React from 'react';

export const valuesEducacion = [
  {
    label: "Áreas",
    content: (
      <>
      <p>
       Dictamos en cuatro ámbitos:  
      </p>
      <ul>
          <li>Gestión Contable:<p>Obtener información contable y financiera precisa para la toma oportuna de decisiones.</p></li>
          <li>Gestión de la calidad:<p>Obtener información contable y financiera precisa para la toma oportuna de decisiones.Buscamos ganar valor mediante la optimización de los procesos.</p></li>
          <li>Gestión Pública:<p>Administrar los recursos del Estado, a fin de satisfacer las necesidades de la ciudadanía e impulsar el desarrollo del país.</p></li>
          <li>Gestión de los Recursos Humanos:<p>Organizar y promover un desempeño eficiente del personal que compone nuestra estructura.</p></li>
      </ul>
      <p>Para cada una de estas áreas, se han diseñado varios tipos de Programas educativos: Pasantías, Diplomados de posgrado, Diplomados o Programas de Especialización, Diplomados, Programas de formación continua o de actualización, Talleres, Cursos, Conferencias e In House.</p>
      </>
    ),
  },
  {
    label: "Pasantía",
    content: (
      <p>
  Actividad práctica de carácter académico, de investigación o profesional en otra entidad pública o privada, en el país o en el extranjero, con el objeto de adquirir experiencia y/o profundizar los conocimientos necesarios para el desarrollo de sus funciones. 
    </p>
    ),
  },
  {
    label: "Diploma de posgrado",
    content: (
      <p>
      Estudios de perfeccionamiento profesional en áreas específicas. 
La metodología es eminentemente práctica dónde es indispensable contar con conocimientos básicos en la materia.
Es una estrategia de enseñanza-aprendizaje que comprende un conjunto de cursos o módulos organizados para profundizar en una temática específica que tiene como propósito la adquisición o desarrollo de conocimientos teóricos y/o prácticos, debiendo completarse un total de veinticuatro (24) créditos.
      </p>
    ),
  },
  {
    label: "Diploma y programa de especialización",
    content: (
      <p>
       Estudios de especialización profesional en materias específicas. La metodología teórica – práctica tiene por objetivo especializar al capacitado en una materia específica.Es una estrategia de enseñanza-aprendizaje que comprende un conjunto de cursos o módulos organizados para instruir al capacitado en una determinada materia con el  propósito de especializarse. Debiendo completar un mínimo de ocho (08) créditos. </p>
    ),
  },
  {
    label: "Programas de formación continua",
    content: (
      <p>
       Buscan actualizar los conocimientos profesionales en aspectos teóricos y prácticos de una disciplina, o desarrollar y actualizar determinadas habilidades y competencias. Estos programas se organizan preferentemente bajo el sistema de créditos pudiendo ser diplomas, programas de actualización o de formación. Debiendo completar un mínimo de cuatro (04) créditos.
      </p>
    ),
  },
  {
    label: "Curso",
    content: (
      <p>
       Actividad teórica – formativa e informativa que resuelve problemas de temas específicos. Puede comprender una secuencia de sesiones articuladas y orientadas al logro de los objetivos de aprendizaje previstos.
      </p>
    ),
  },
  {
    label: "Taller",
    content: (
      <p>
       Actividad práctica que resuelve problemas de temas específicos a partir de la puesta en práctica de los conocimientos adquiridos por parte del docente, y los discentes, de manera individual o grupal. Estos deben aportar para <strong> resolver problemas concretos y proponer soluciones.</strong> 
      </p>
    ),
  },
  {
    label: "Conferencia",
    content: (
      <p>
      Actividad académica de naturaleza técnica o científica que tienen como propósito difundir y transmitir conocimientos actualizados y organizados previamente a manera de exposiciones, que buscan solucionar o aclarar problemas de interés común sobre una materia específica, tales como congresos, seminarios, simposios, entre otros.
      </p>
    ),
  },
  {
    label: "In house",
    content: (
      <p>
        Actividades académicas diseñadas por el staff de analistas del INEDI Posgrados® "a la medida" de las necesidades particulares del solicitante desarrollando casos reales de la actividad principal.  
        Para el dictado de los In House, nos acondicionamos a los requerimientos de las empresas, ya sea en: frecuencia u horario, los cuales pueden ser dictados en las instalaciones del solicitante o en nuestras aulas, debidamente acondicionados e implementadas, que sirven de soporte para el desarrollo de cada una de las sesiones de trabajo. 
      </p>
    ),
  },
];
