import {
    MOSTRAR_SESIONES,
    MOSTRAR_SESIONES_EXITO,
    LISTAR_TAREA_ESTUDIANTE,
    LISTAR_VIDEOS,
    LISTAR_SESION_CURSO,
    LISTAR_RECURSOS,
    ELIMINAR_DATOS_SESIONES,
    SUBIR_TAREAS,
    LISTAR_TAREAS,
    ELIMINAR_TAREA
} from '../actions/types';

//Cada reducer tiene su propio state

const initialState = {
    sesiones: [],
    sesion: {},
    recursos: [],
    podcasts:[],
    tareas: [],
    tareasEstudiantes: {},
    videos: [],
    linkVivo: [],
    loading: null
}

export default function(state = initialState, action){
    switch (action.type) {
        case MOSTRAR_SESIONES:
            return {
                ...state,
                loading: true
            }
        case MOSTRAR_SESIONES_EXITO:
            return{
                ...state,
                sesiones: action.payload,
                loading: null
            }
        case LISTAR_RECURSOS:
            return{
                ...state,
                recursos: action.payload.tp_archivos,
                videos: action.payload.tp_videos,
                linkVivo: action.payload.tp_sesion_en_vivo,
                podcasts: action.payload.tp_podcast,
            }
        case LISTAR_TAREAS: 
            return{
                ...state,
                tareas: action.payload
            }
        case LISTAR_SESION_CURSO:
            return{
                ...state,
                sesion: action.payload
            }
        case SUBIR_TAREAS:
            return{
                ...state,
                tareas: action.payload
            }
        case LISTAR_TAREA_ESTUDIANTE:
            return {
                ...state,
                tareasEstudiantes: action.payload
            }
        case ELIMINAR_DATOS_SESIONES:
            return{
                sesiones: action.payload,
                recursos: action.payload,
                tareas: action.payload
            }
        // case LISTAR_VIDEOS:
        //     return{
        //         ...state,
        //         videos: action.payload,
        //     }
        case ELIMINAR_TAREA:
            return{
                ...state,
                tareasEstudiantes: action.payload
            }
        default:
            return state
    }
}