import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/es.js';
import './sass/styles.scss';
import './sass/agenda.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const localizer = momentLocalizer(moment)

const MyCalendar = ({ eventos }) => {

  return(
      <div className="bigCalendar-container">
        <Calendar
          localizer={localizer}
          events={eventos}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={event =>  window.open(event.url, '_blank')}
          eventPropGetter={
                            function(event) {
                           // console.log(event);
                            var backgroundColor = '#1C56FF';
                            var style = {
                                backgroundColor: backgroundColor,
                               
                            };
                            return {
                                style: style
                            }
                          }
                          }
          style={{ height: 500 }}
          messages={{
                  next: <FontAwesomeIcon icon={faArrowRight} /> ,
                  previous: <FontAwesomeIcon icon={faArrowLeft} />,
                  today: "Hoy",
                  month: "Mes",
                  week: "Semana",
                  day: "Día"
                }}
          />
      </div>
  )
}

export default MyCalendar;