import React, { useState, useEffect} from "react";
import Menu from './componentesMensajes/Menu'
import NuevoMensaje from './componentesMensajes/NuevoMensaje';
import Breadcrumb from "../migasPan/Breadcrumb";
import Entrada from './componentesMensajes/Entrada'
import {useHistory} from 'react-router-dom'
import {TransitionGroup, CSSTransition} from 'react-transition-group'
import Swal from 'sweetalert2'

//REDUX 
import {connect, useDispatch} from 'react-redux'
import { listarBandeja,eliminarMensaje, listarMensajesEnviados, listarPapelera, retaurarMensaje, listarContactos, mandarMensajeBandejaPapelera, mandarMensajeEnviadosPapelera } from "../../actions/MensajesActions";
import Layout from "../Layout/Layout";
import { ACTUALIZAR_TAB_MENSAJERIA } from "../../actions/types";

const Mensajeria = (props) => {

  //State
  const {usuario, detalleNivel, hilo, currentPanel} = props

  //Funciones
  const { mandarMensajeBandejaPapelera,eliminarMensaje, listarContactos, retaurarMensaje, listarPapelera, mandarMensajeEnviadosPapelera, listarBandeja, listarMensajesEnviados} = props

  const [panel, setPanel] = useState(currentPanel);
  const [mensajesSeleccionados, setMensajesSeleccionados] = useState([])

  const history = useHistory();
  const dispatch = useDispatch();
  let dataRuta = [
    { title: <i className="material-icons">home</i>, url: "" },
    { title: "Mensajería", url: "" }
  ];
  
  const handlePanel = (p) =>{
    dispatch({
      type:ACTUALIZAR_TAB_MENSAJERIA,
      payload:p
    })
  }

  //Funcion para guardar lo mensajes seleccionados
  const guardarMensajesSeleccionados = (mensaje) => {
    //Si el checked es true se guarda
    if (mensaje.checked) {
      setMensajesSeleccionados([...mensajesSeleccionados, {id_mensaje: mensaje.id}])
      return
    }
    //Sino es true se pasa a eliminar
    let nuevaSeleccion = mensajesSeleccionados.filter(m => m.id_mensaje !== mensaje.id)
    setMensajesSeleccionados(nuevaSeleccion)
  }

  //const mensaje si no se selecciona nada
  const mensajeAlert = mensaje => {
    const Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({
      icon: 'warning',
      title: mensaje
    })
  }
  //Eliminar completamente un mensaje
  const eliminarMensajeCompleto = () => {
    
    if (mensajesSeleccionados.length === 0) {
      mensajeAlert('No se selecciono mensajes')
      return
    }

    let data = {
      id_usuario: usuario.id_usuario,
      eliminados: mensajesSeleccionados
    }
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "El mensaje se movera a la papelera",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        confirmButtonColor: "#842eff",
        cancelButtonColor: "#29d698",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
            if ((await eliminarMensaje(data)).mensaje) {
              setMensajesSeleccionados([])
              return true;
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Error en eliminar los mensajes"
              });
            
          }
          
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        if (result.value === true) {
          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "Se elimino con exito",
            "success"
          );
        }
      });
    
    
  }

  //Mover a la papelera los mensaje
  const moverMensajePapelera = () => {

    //VALIDAR si NO selecciono nada
    if (mensajesSeleccionados.length === 0) {
      mensajeAlert('No hay selecciones')
          return
        }


    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons.fire({
      title: '¿Estas seguro?',
      text: "El mensaje se movera a la papelera",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, continuar!',
      cancelButtonText: 'No, cancelar!',
      confirmButtonColor: "#842eff",
			cancelButtonColor: "#29d698",
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: async function () {
        //consultando para ver el mensaje de aprovación
        if (panel === 'entrada') {
          if((await mandarMensajeBandejaPapelera(usuario.id_usuario, mensajesSeleccionados)).mensaje){
            setMensajesSeleccionados([])
            return true
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Error en eliminar los mensajes"
            });
          }
        }
        if (panel === 'enviados') {
          //Ejecuat funcion
          if((await mandarMensajeEnviadosPapelera(usuario.id_usuario, mensajesSeleccionados)).mensaje){
            return true
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Error en eliminar los mensajes"
            });
          }
        }
        
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      //mensaje que se cambio la contraseña satisfactoriamente
      if (result.value === true) {
        swalWithBootstrapButtons.fire(
          'Movido!',
          'Se movio a la papelera',
          'success'
        )
      }
    })

    
  }

  const restaurarMensajesPapelera = async () => {
    if (mensajesSeleccionados.length === 0) {
      mensajeAlert('Debe seleccionar mensajes')
    }
    if((await retaurarMensaje(usuario.id_usuario, mensajesSeleccionados)).mensaje){
      Swal.fire(
        'Mensaje Restaurado!',
        '',
        'success'
      )
    }
  }


  useEffect(() => {
    /*LISTAR LA BANDEJAS */
    if (detalleNivel[0]) {
      listarBandeja(usuario.id_usuario,usuario.id_tp_nivel_usuario);
      listarMensajesEnviados(usuario.id_usuario)
      listarPapelera(usuario.id_usuario)
      listarContactos(usuario.id_usuario, usuario.id_tp_nivel_usuario)
    }
  }, [detalleNivel[0]]);

  useEffect(() => {
    /*LISTAR LA BANDEJAS */
    if (currentPanel) {
      setPanel(currentPanel)
    }
  }, [currentPanel]);


  return (
    <Layout>
    <div className="container content-mensajeria mt-5">
      <Breadcrumb data={dataRuta} />
      <div className="row">
        <div className="col-lg-2 px-0 col-xs-12">
          <Menu
            setPanel={handlePanel}
            setMensajesSeleccionados={setMensajesSeleccionados}
            panel={panel}
          />
        </div>
        <div className="col-lg-10 col-xs-12 mt-md-4 mt-lg-0 px-4 px-lg-3 panel">
          <TransitionGroup
            component='div'
            className='panel'
          >
            <CSSTransition
              classNames='panel'
              key={panel}
              timeout={{enter: 500, exit: 500}}
            >
              {panel === "responder" ? (
                <NuevoMensaje setPanel={handlePanel} hilo={hilo} />
                ):
                panel === "entrada" ? (
                <Entrada
                  title="Recibidos"
                  guardarMensajesSeleccionados={guardarMensajesSeleccionados}
                  moverMensajePapelera={moverMensajePapelera}
                />
                ) : panel === "nuevo" ? (
                  <NuevoMensaje setPanel={handlePanel} />
                ) : panel === "enviados" ? (
                  <Entrada
                    title="Enviados"
                    guardarMensajesSeleccionados={guardarMensajesSeleccionados}
                    moverMensajePapelera={moverMensajePapelera}
                  />
                ) : panel === "papelera" ? (
                  <Entrada
                    title="Papelera"
                    guardarMensajesSeleccionados={guardarMensajesSeleccionados}
                    restaurarMensajesPapelera={restaurarMensajesPapelera}
                    eliminarMensajeCompleto={eliminarMensajeCompleto}
                  />
                ) : null}
            </CSSTransition>
          </TransitionGroup>
          
        </div>
      </div>
    </div>
    </Layout>
  );
};

const mapStateToProps = state => ({
  usuario: state.usuario.usuario,
  detalleNivel: state.usuario.detalleNivel,
  hilo : state.mensajes.hilo,
  currentPanel: state.mensajes.panel
});

export default connect(mapStateToProps, {
  listarBandeja,
  listarMensajesEnviados,
  mandarMensajeBandejaPapelera,
  mandarMensajeEnviadosPapelera,
  listarPapelera,
  listarContactos,
  retaurarMensaje,
  eliminarMensaje
})(Mensajeria);