import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import PreloadPerfil from "../../components/plugins/preloadPerfil";
import { setJwt } from "../../components/Helpers/FunctionsHelpers";
import Skeleton from "@material-ui/lab/Skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCloud,
	faInfoCircle,
	faPen,
} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import "./PerfilUsuario.scss";
import FloatingWhatsAppButton from "../../components/WhatsappButton/FloatingWhatsAppButton";
//REDUX
import {
	mostrarUsuario,
	editarusuario,
	actualizarUsuario,
} from "../../actions/usuarioActions";
import Layout from "../../components/Layout/Layout";
import TitlePagina from "../../components/TitlePagina";
import { Row } from "react-bootstrap";
import authAxios from "../../config/authAxios";

const Perfil = ({
	actualizarUsuario,
	detalleNivel,
	usuario,
	editarusuario,
}) => {
	const { email } = usuario;

	const inputFile = useRef();
	const [preload, setPreload] = useState(false);

	const nombre_usuarioRef = useRef(null);
	const apellido_usuarioRef = useRef(null);
	const fecha_nacRef = useRef(null);
	const dniRef = useRef(null);
	const direccionRef = useRef(null);
	const distritoRef = useRef(null);
	const provinciaRef = useRef(null);
	const departamentoRef = useRef(null);
	const celularRef = useRef(null);
	const celular_refRef = useRef(null);
	const emailRef = useRef(null);
	const usuarioRef = useRef(null);

	const styleImg = {
		width: "70%",
	};

	//Cambia la imagen de usuario
	const cambiarImagen = async (e) => {
		e.preventDefault();
		setPreload(true);

		let foto = e.target.files[0];

		let dato = new FormData();

		dato.append("img_usuario", foto);
		dato.append("id_usuario", usuario.id_usuario);

		const myConfig = {
			header: {
				"Content-Type": "multipart/form-data",
			},
		};

		let respuesta = await authAxios.post(`/admin/subirImg`, dato);
		if (respuesta.status === 200) {
			setPreload(false);
		}
		setJwt(respuesta.data.encode);
		//recargar la info del usuario
		actualizarUsuario(respuesta.data.decode.datos[0]);
	};

	//EDITAR USUARIO
	const editarInfoUsuario = async (e) => {
		e.preventDefault();

		let datainfoUsuario = {
			id_usuario_a: usuario.id_usuario,
			nombre_usuario: nombre_usuarioRef.current.value,
			apellido_usuario: apellido_usuarioRef.current.value,
			fecha_nac: fecha_nacRef.current.value,
			dni: dniRef.current.value,
			direccion: direccionRef.current.value,
			distrito: distritoRef.current.value,
			provincia: provinciaRef.current.value,
			departamento: departamentoRef.current.value,
			celular: celularRef.current.value,
			celular_ref: celular_refRef.current.value,
			email: emailRef.current.value,
			usuario: usuarioRef.current.value,
			id_estado: 1,
		};

		const swalWithBootstrapButtons = Swal.mixin({
			customClass: {
				confirmButton: "btn btn-success",
				cancelButton: "btn btn-danger",
			},
			buttonsStyling: false,
		});
		swalWithBootstrapButtons
			.fire({
				title: "¿Estas seguro?",
				text: "La información de tu perfíl sera actualizado",
				icon: "warning",
				showCancelButton: true,
				confirmButtonText: "Si, continuar!",
				cancelButtonText: "No, cancelar!",
				reverseButtons: true,
				showLoaderOnConfirm: true,
				preConfirm: async function () {
					let { mensaje, textMensaje } = await editarusuario(
						usuario.id_usuario,
						datainfoUsuario
					);
					//consultando para ver el mensaje de aprovación
					if (mensaje) {
						return true;
					} else {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: `${textMensaje}`,
						});
					}
				},
				allowOutsideClick: () => !Swal.isLoading(),
			})
			.then((result) => {
				//mensaje que se cambio la contraseña satisfactoriamente
				if (result.value === true) {
					swalWithBootstrapButtons.fire(
						"Correcto",
						"Se actualizarion datos correctamente",
						"success"
					);
				}
			});
	};

	return (
		<Layout>
			<div className="container PerfilUsuario mt-4">
				<div className="content_datos py-1 px-1">
					<div className="mb-4">
						<TitlePagina clases="primary">
							Datos personales
						</TitlePagina>
					</div>
					<Row>
						<div className="col-lg-8 order-lg-2">
							<div className="tab-content pt-1">
								<div className="" id="edit">
									<form
										onSubmit={(e) => editarInfoUsuario(e)}
									>
										<div className="form-group row">
											<label className="col-lg-3 col-form-label form-control-label">
												Nombres
											</label>
											<div className="col-lg-9">
												<input
													className="form-control"
													type="text"
													defaultValue={
														usuario.nombre_usuario
													}
													ref={nombre_usuarioRef}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-3 col-form-label form-control-label">
												Apellidos
											</label>
											<div className="col-lg-9">
												<input
													className="form-control"
													type="text"
													defaultValue={
														usuario.apellido_usuario
													}
													ref={apellido_usuarioRef}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-3 col-form-label form-control-label">
												Fecha Nac.
											</label>
											<div className="col-lg-9">
												<input
													className="form-control"
													type="date"
													defaultValue={
														usuario.fecha_nac
													}
													ref={fecha_nacRef}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-3 col-form-label form-control-label">
												E-mail
											</label>
											<div className="col-lg-9">
												<input
													className="form-control"
													type="email"
													defaultValue={email}
													ref={emailRef}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-3 col-form-label form-control-label">
												DNI
											</label>
											<div className="col-lg-9">
												<input
													className="form-control"
													defaultValue={usuario.dni}
													type="text"
													ref={dniRef}
												/>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-lg-3 col-form-label form-control-label">
												Dirección
											</label>
											<div className="col-lg-9">
												<input
													className="form-control"
													type="text"
													defaultValue={
														usuario.direccion
													}
													placeholder="Ejm: Av. La Victoria #225"
													ref={direccionRef}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-3 col-form-label form-control-label"></label>
											<div className="col-lg-3">
												<input
													className="form-control"
													type="text"
													defaultValue={
														usuario.distrito
													}
													placeholder="Distrito"
													ref={distritoRef}
												/>
											</div>
											<div className="col-lg-3">
												<input
													className="form-control"
													type="text"
													defaultValue={
														usuario.provincia
													}
													placeholder="Provincia"
													ref={provinciaRef}
												/>
											</div>
											<div className="col-lg-3">
												<input
													className="form-control"
													type="text"
													defaultValue={
														usuario.departamento
													}
													placeholder="Departamento"
													ref={departamentoRef}
												/>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-lg-3 col-form-label form-control-label">
												Tel/Celular
											</label>
											<div className="col-lg-9">
												<input
													className="form-control"
													defaultValue={
														usuario.celular
													}
													type="number"
													ref={celularRef}
												/>
											</div>
										</div>
										<div className="form-group row">
											<label className="col-lg-3 col-form-label form-control-label">
												Celular Ref.
											</label>
											<div className="col-lg-9">
												<input
													className="form-control"
													defaultValue={
														usuario.celular
													}
													type="number"
													ref={celular_refRef}
												/>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-lg-3 col-form-label form-control-label">
												Usuario
											</label>
											<div className="col-lg-9">
												<input
													className="form-control"
													type="text"
													defaultValue={
														usuario.usuario
													}
													ref={usuarioRef}
												/>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-lg-3 col-form-label form-control-label">
												Asesor
											</label>
											<div className="col-lg-9">
												<input
													className="form-control"
													defaultValue={
														detalleNivel[0]
															? `${detalleNivel[0].nombre_asesor} ${detalleNivel[0].apellido_asesor}`
															: ""
													}
													type="text"
													disabled
												/>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-lg-3 col-form-label form-control-label">
												Confirmación de contrato
											</label>
											<div className="col-lg-9">
												<input
													className="form-control"
													defaultValue={
														detalleNivel[0]
															? detalleNivel[0]
																	.confirmacion_contrato
															: ""
													}
													type="text"
													disabled
												/>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-lg-3 col-form-label form-control-label">
												Observaciones
											</label>
											<div className="col-lg-9">
												<textarea
													className="form-control"
													disabled
													name=""
													id=""
													cols="30"
													rows="3"
													defaultValue={
														detalleNivel[0]
															? detalleNivel[0]
																	.observaciones
															: ""
													}
												></textarea>
											</div>
										</div>

										<div className="form-group row">
											<label className="col-lg-3 col-form-label form-control-label"></label>
											<div className="btn_datos col-lg-9">
												<button
													type="submit"
													className="btn btn-primary btn-submit"
												>
													<FontAwesomeIcon
														icon={faPen}
														className="mr-2"
													/>
													Guardar configuración
												</button>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-4 order-lg-1 text-center">
							{usuario.img_usuario !== undefined ? (
								<img
									style={styleImg}
									src={
										usuario.img_usuario !== null
											? `${process.env.REACT_APP_API_URL}/${usuario.img_usuario}`
											: "/images/user.png"
									}
									className="mx-auto img-fluid img-circle d-block mb-1"
									alt=""
								/>
							) : (
								<div className="w-100 justify-content-center d-flex skeleton">
									<Skeleton
										variant="circle"
										margin={"auto"}
										width={120}
										height={120}
									/>
								</div>
							)}

							<div style={{ width: "70%", marginLeft: "50px" }}>
								{preload ? <PreloadPerfil /> : null}
							</div>
							<div className="custom-file">
								<input
									type="file"
									id="inputImg"
									ref={inputFile}
									onChange={(e) => cambiarImagen(e)}
									className="d-none"
								/>
								<span
									onClick={() => inputFile.current.click()}
									className="custom-file-control btn btn-sm"
								>
									Subir foto
								</span>
							</div>

							<div
								className="alert alert-info mt-4"
								style={{ textAlign: "justify" }}
							>
								<p className="my-2">
									<FontAwesomeIcon
										icon={faInfoCircle}
										size={32}
									/>{" "}
									La información proporcionada en esta sección
									será usada para la generación de tu
									certificado digital.{" "}
								</p>
								<p>
									Te recordamos escribir correctamente tus datos.
								</p>
							</div>
						</div>
					</Row>
				</div>
			</div>
			<FloatingWhatsAppButton />
		</Layout>
	);
};

const mapStateToProps = (state) => ({
	usuario: state.usuario.usuario,
	detalleNivel: state.usuario.detalleNivel,
});

export default connect(mapStateToProps, {
	mostrarUsuario,
	actualizarUsuario,
	editarusuario,
})(Perfil);
