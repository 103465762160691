import React from "react";
import { useParams } from "react-router-dom";

const Video = ({ video }) => {
  const { idSesion,idCurso } = useParams()
  return (
    <div className="container-recursos videos d-flex justify-content-between border-top">
      <div className="nombre-archivo p-3">
      <a href={`/capacitaciones/${idCurso}/sesiones/${idSesion}/videos/${video.id_archivo_sesion}`}><p
          href="#"
          className="mb-0 p-nombre-video"
        >
          <span>
            <i className="fas text-dark fa-chevron-circle-right mr-2"></i>
          </span>
          <span className="text-info">{video.nombre_archivo}</span>
        </p>
        </a> 
      </div>
    </div>
  );
};

export default Video;
