import React, { useEffect, useState } from "react";
//Redux
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {Spinner} from 'react-bootstrap'
import { listarEmpleos } from "../../actions/cursosActions";
import { consultarPension, registrarPensionFraccion } from "../../actions/usuarioActions";
import RegistroFactura from "../../components/ContenidoProyecto/EstadoCuenta/ExaminarPensiones/RegistroFactura";
import Layout from "../../components/Layout/Layout";
import TablaCuotas from "../../components/ContenidoProyecto/EstadoCuenta/ExaminarPensiones/TablaCuotas";
import Swal from "sweetalert2";
//import { showEncuesta } from "../../actions/encuestaActions";
const ReporteDePago = (props) => {

  const dispatch = useDispatch()
  //const show = useSelector(state => state.encuesta.show);
  const { detalleNivel, usuario} = props;
  const params = useParams();
  const { idPension } = params;
  const history = useHistory() 
  const [pension,setPension]=useState(null)
  const [loading,setLoading] = useState(true)
  const handleFormSubmit = async (datosFactura,idMatricula) => {

    //console.log(datosFactura,idMatricula)
  const rpta = await  dispatch(registrarPensionFraccion(datosFactura,idMatricula))
  if(rpta){
    Swal.fire({
      title: 'Registro completado!',
      html: 'Redirigiendo a estado de cuenta....',
      timer: 1500,
      icon: 'success',
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false,                
      showLoading:true,
      allowOutsideClick: false
    }).then( async (result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
          history.goBack()
      }})
  }
  else
  {
    Swal.fire({
      title: 'Ocurrió un problema!',
      html: 'No se pudo realizar el registro, redirigiendo a estado de cuenta....',
      timer: 1500,
      icon: 'warning',
      timerProgressBar: true,
      showCancelButton: false,
      showConfirmButton: false,                
      showLoading:true,
      allowOutsideClick: false
    }).then( async (result) => {
      /* Read more about handling dismissals below */
      if (result.dismiss === Swal.DismissReason.timer) {
          history.goBack()
      }})
  }

  }

  const handleRegresar = () =>{
    history.goBack()
  }

  useEffect(()=>{
    const fetchPension = async ()=>{
        const rpta = await dispatch(consultarPension(idPension,detalleNivel[0].id_u_estudiante));
        setLoading(false)
        //console.log(rpta)
        setPension(rpta.datos)
    }

    fetchPension()
  
  },[])

  return (
  
    <Layout>
      <div className="container mt-5">
        <div className="row">
            <div className="container">
              <div className="d-flex justify-content-between py-3">
              <h2>Reporta tu Pago</h2>
              <button className="btn btn-primary" onClick={handleRegresar} >Regresar</button>
              </div>
              <div className="card">
                <div className="card-body">
                { pension ? pension.id_tp_cuota !=="1" ? <div className="alert alert-info mb-5">Este pago ha sido fraccionado, por favor elija una de las fracciones mostradas en pantalla para reportar:</div>:null:null}
                 {!loading ? pension ? pension.id_tp_cuota==="1" ? <RegistroFactura handleFormSubmit={handleFormSubmit} pension={pension} /> : <TablaCuotas pension={pension} handleFormSubmit={handleFormSubmit} />: 'No hay datos':  <div className="text-center"><Spinner animation="border" variant="dark"  /></div>}
                </div>
              </div>
            </div>
        </div>
      </div>
    </Layout>

  );
};

const mapStateToProps = (state) => ({
  usuario: state.usuario,
  detalleNivel: state.usuario.detalleNivel,
  currentTab:state.usuario.currentTab,
});


export default connect(mapStateToProps,{listarEmpleos})(ReporteDePago);