import React from 'react';
import { Modal } from 'react-bootstrap';
import './BasicModal.scss';

const BasicModal = ({ children, showModal, onCloseModal, ...rest }) => {
  return ( 
      <Modal className='BasicModal' show={showModal} onHide={onCloseModal} {...rest}>
        {children}
      </Modal>
  );
}
 
export default BasicModal;