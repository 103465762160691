import React, { useState } from 'react'
//Redux
import {connect, useDispatch} from 'react-redux'
import { useEffect } from 'react';
import { listarTemas, showForm } from '../../../actions/cursosActions';
import Foro from './Foro';
import NuevoTema from './NuevoTema';
import FloatingWhatsAppButton from "../../WhatsappButton/FloatingWhatsAppButton";

const ContentForo = (props) => {

        //State
        const {proyecto, temas} = props
        const dispatch = useDispatch()
        const [listaTemas, setListaTemas] = useState(null)
        useEffect(() => {
    
        const fetchData = async (id) => {
            const rpta = await dispatch(listarTemas(id))
        }
    
        if (proyecto !== undefined) {
            if (Object.keys(proyecto).length > 0) {
                fetchData(proyecto.id_proyecto)
              }  
        }
          
        }, [proyecto])

        useEffect(()=>{
            if(temas)
            {
                setListaTemas(temas)
            }
        },[temas])

    return ( 
       <> 
     
       <div className="clearfix"><NuevoTema /></div>
        <div className="content-horario">
            <div className="content-horario-agenda p-3 bg-white shadow-sm m-1 mt-3">
                <Foro temas={listaTemas ? listaTemas : null} setListaTemas={setListaTemas} />
            </div>
        </div>
        <FloatingWhatsAppButton />
        </>
     );
}
const mapStateToProps = state => ({
    proyecto: state.cursos.proyecto,
    temas : state.foro.temas
})
export default connect(mapStateToProps, {})(ContentForo);