import React from 'react'
import {Pie} from 'react-chartjs-2'
import { useState } from 'react';

const PieChart = ({asistencia, inasistencia}) => {
    
    const labels = [
        'Asistencia', 'Inasistencia'
    ];
    const dataSets = [{
        data: [asistencia, inasistencia],
        backgroundColor: ['#1C56FF', '#29d698']
    }];
    
    return ( 
        <div>
            <Pie
                data={{
                    labels: labels,
                    datasets: dataSets
                }}
                height={280}
            />
        </div>
     );
}
 
export default PieChart;