import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import Empleo from './Empleo';
import * as moment from 'moment'

const ListaEmpleos = ({empleos}) => {


    const [busqueda,setBusqueda]=useState('')
    const handleChange = e => {
      setBusqueda(e.target.value)
    }

    const empleoFiltrado = empleos ? empleos.filter(
      empleo => {
        return (
          (empleo
          .nombre_puesto
          .toLowerCase()
          .includes(busqueda.toLowerCase()) ||
          empleo
          .empresa
          .toLowerCase()
          .includes(busqueda.toLowerCase()) ||
          empleo
          .categoria
          .toLowerCase()
          .includes(busqueda.toLowerCase()) )
          &&
          moment().diff(moment(empleo.fecha_publicacion),'days')<15 
        )
      }
    ) : null

    

    return (
        <>
         <div className="row mb-4 p-2">
                <h4 className='col-8 font-20 text-uppercase'>
                 
                </h4>
                <div className="col-4 d-flex align-items-center">
                  <input className="form-control" value={busqueda} onChange={handleChange} style={{marginRight:'5px'}} placeholder="Búsqueda por Empresa o Empleo" />
                  <FontAwesomeIcon icon={faSearch} size="lg"/>
                </div>
        </div>
        <div className="row p-2">
        {   empleoFiltrado?.length>0 ? empleoFiltrado.map((empleo,i)=>{
             //  <Empleo datos={empleo} key={i} />
            return <Empleo datos={empleo} key={i} />
           }) : <div className="card col-12"><div className="card-body">No hay ofertas recientes</div></div>}
        </div>
        </>
    );
};

export default ListaEmpleos;