export const MOSTRAR_USUARIOS = 'MOSTRAR_USUARIOS';
export const VERIFICAR_USUARIO = 'VERIFICAR_USUARIO'; 
export const VERIFICAR_USUARIO_EXITO = 'VERIFICAR_USUARIO_EXITO'; 
export const VERIFICAR_USUARIO_ERROR = 'VERIFICAR_USUARIO_ERROR'; 
export const MOSTRAR_USUARIO = 'MOSTRAR_USUARIO';
export const EDITAR_USUARIO = 'EDITAR_USUARIO';
export const ELIMINAR_USUARIO = 'ELIMINAR_USUARIO';
export const ISERTAR_USUARIO = 'ISERTAR_USUARIO';
export const REMOVER_ESTADO_USUARIO = 'REMOVER_ESTADO_USUARIO';
export const ACTUALIZAR_ESTATE_USUARIO = 'ACTUALIZAR_ESTATE_USUARIO'
export const ACTUALIZAR_USUARIO_P = 'ACTUALIZAR_USUARIO_P'
export const RESTABLECER_ERROR = 'RESTABLECER_ERROR'
export const MOSTRAR_ERROR = 'MOSTRAR_ERROR';
export const GUARDAR_PUNTAJE = 'GUARDAR_PUNTAJE';
export const ACTUALIZAR_TAB = 'ACTUALIZAR_TAB';
export const ACTUALIZAR_TAB_MENSAJERIA = 'ACTUALIZAR_TAB_MENSAJERIA';
export const ACTUALIZAR_HILO = 'ACTUALIZAR_HILO';
export const OBTENERN_NOTIFICACIONES = 'OBTENERN_NOTIFICACIONES';
//CURSOS
export const MOSTRAR_CURSOS = 'MOSTRAR_CURSOS';
export const LISTAR_CURSO = "LISTAR_CURSO";
export const LISTAR_SESIONES_ACTIVAS_RULETA = 'LISTAR_SESIONES_ACTIVAS_RULETA'; 
export const ELIMINAR_CURSOS = 'ELIMINAR_CURSOS';
export const ALUMNOS_CURSO = "ALUMNOS_CURSO";
export const LISTAR_SESION_CURSO = 'LISTAR_SESION_CURSO'
export const LISTAR_PROYECTOS = 'LISTAR_PROYECTOS'
export const LISTAR_PROYECTO = 'LISTAR_PROYECTO'
export const LISTAR_PROYECTOS_ACTIVOS_ESTUDIANTE = 'LISTAR_PROYECTOS_ACTIVOS_ESTUDIANTE'
export const LISTAR_CURSOS_ACTIVOS_RULETA = 'LISTAR_CURSOS_ACTIVOS_RULETA'
//Mostrar SESIONES
export const MOSTRAR_SESIONES = 'MOSTRAR_SESIONES'
export const MOSTRAR_SESIONES_EXITO = 'MOSTRAR_SESIONES_EXITO';
export const ELIMINAR_DATOS_SESIONES = 'ELIMINAR_DATOS_SESIONES';
//MOSTRAR PAGOS
export const MOSTRAR_PAGOS = 'MOSTRAR_PAGOS'
export const REMOVER_ESTADO_PAGOS = 'REMOVER_ESTADO_PAGOS'

//NOTAS
export const LISTAR_NOTAS = 'LISTAR_NOTAS'
//TAREAS
export const ELIMINAR_TAREA = "ELIMINAR_TAREA"; 
export const LISTAR_TAREA_ESTUDIANTE = 'LISTAR_TAREA_ESTUDIANTE'
//RECURSOS
export const LISTAR_RECURSOS = 'LISTAR_RECURSOS'
export const LISTAR_TAREAS = 'LISTAR_TAREAS'
export const SUBIR_TAREAS = "SUBIR_TAREAS";
//
export const LISTAR_ASISTENCIA = 'LISTAR_ASISTENCIA' 

//
export const ENVIAR_MENSAJE = "ENVIAR_MENSAJE";
export const REMOVER_ESTADO_MENSAJE = "REMOVER_ESTADO_MENSAJE" 
export const LISTAR_MENSAJES = "LISTAR_MENSAJES"; 
export const MOSTRAR_MENSAJE = "MOSTRAR_MENSAJE"; 
export const LISTAR_PROFESORES = "LISTAR_PROFESORES";
export const LISTAR_MENSAJES_ENVIADOS = "MOSTRAR_MENSAJES_ENVIADOS";
export const MENSAJES_NO_LEIDOS = 'MENSAJES_NO_LEIDOS';
export const ACTUALIZAR_CANTIDAD_MNV = 'ACTUALIZAR_CANTIDAD_MNV'
export const LIMPIAR_MENSAJE = 'LIMPIAR_MENSAJE' 
export const LISTAR_PAPELERA = 'LISTAR_PAPELERA'
export const MANDAR_MENSAJE_E_PAPELERA = 'MANDAR_MENSAJE_E_PAPELERA'
export const MANDAR_MENSAJE_R_PAPELERA = 'MANDAR_MENSAJE_R_PAPELERA'
export const LISTAR_CONTACTOS = 'LISTAR_CONTACTOS'
export const RESTAURAR_MENSAJE = 'RESTAURAR_MENSAJE' 
export const ELIMINAR_MENSAJE = 'ELIMINAR_MENSAJE' 

/**VIDEOS */
export const LISTAR_VIDEOS = 'LISTAR_VIDEOS'

/**TOAST */
export const toastTypes = {
  SHOW_TOAST: 'SHOW_TOAST' 
}

/**ALERTA MENSUALIDAD */
export const alertaTypes = {
  SHOW_ALERTA: 'SHOW_ALERTA',
  SHOW_MENU:'SHOW_MENU',
  SHOW_DETALLE_EMPLEO:'SHOW_DETALLE_EMPLEO',
  SET_ID_EMPLEO:'SET_ID_EMPLEO',
  SHOW_REPRODUCTOR:'SHOW_REPRODUCTOR',
  HIDE_REPRODUCTOR:'HIDE_REPRODUCTOR',
  SHOW_OBTENER_ESTADO: 'SHOW_OBTENER_ESTADO'
}


/**ENCUESTA CALIDAD */
export const encuestaTypes = {
  SHOW_ENCUESTA: 'SHOW_ENCUESTA' 
}

export const temasTypes = {
  SHOW_FORM: 'SHOW_FORM',
  OBTENER_TEMAS: 'OBTENER_TEMAS',
  OBTENER_TEMAS_EXITO : 'OBTENER_TEMAS_EXITO',
  GUARDAR_TEMA : 'GUARDAR_TEMA',
  ELIMINAR_TEMA : 'ELIMINAR_TEMA',
  OBTENER_TEMA_EXITO : 'OBTENER_TEMA_EXITO',
  OBTENER_VIDEO_EXITO : 'OBTENER_VIDEO_EXITO',
}

/** AUTH */
export const authTypes = {
  REMOVER_TODO: 'REMOVER_TODO',
  DATOS_NECESARIOS: 'DATOS_NECESARIOS',
}

export const examenesTypes = {
  LISTAR_EXAMENES:'LISTAR_EXAMENES',
  LISTAR_EXAMENES_EXITO: 'LISTAR_EXAMENES_EXITO',
  LISTAR_EXAMENES_ERROR: 'LISTAR_EXAMENES_ERROR',
  OBTENER_EXAMEN_SESION: 'OBTENER_EXAMEN_SESION',
  OBTENER_EXAMEN_SESION_EXITO: 'OBTENER_EXAMEN_SESION_EXITO',
  OBTENER_RESULTADO_SESION: 'OBTENER_EXAMEN_SESION',
  OBTENER_RESULTADO_SESION_EXITO: 'OBTENER_EXAMEN_SESION_EXITO',
  INSERTAR_EXAMEN: 'INSERTAR_EXAMEN',
  INSERTAR_EXAMEN_EXITO: 'INSERTAR_EXAMEN_EXITO',
  INSERTAR_EXAMEN_ERROR: 'INSERTAR_EXAMEN_ERROR',
  INSERTAR_PREGUNTA: "INSERTAR_PREGUNTA",
  TIEMPO_EXAMEN_TERMINADO:'TIEMPO_EXAMEN_TERMINADO',
  TIEMPO_EXAMEN_TERMINADO_EXITO:'TIEMPO_EXAMEN_TERMINADO_EXITO',
  OBTENER_INTENTOS_REALIZADOS: 'OBTENER_INTENTOS_REALIZADOS',
  OBTENER_INTENTOS_REALIZADOS_EXITO: 'OBTENER_INTENTOS_REALIZADOS_EXITO',
 }

 /** AUTH */
 export const ruletaTypes = {
  OBTENER_CUPON: 'OBTENER_CUPON',
  OBTENER_CUPON_EXITO: 'OBTENER_CUPON_EXITO',
  OBTENER_CUPON_ERROR: 'OBTENER_CUPON_ERROR',
}