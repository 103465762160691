import React, { Component } from "react";

import CreatableSelect from "react-select/creatable";
import { useState } from "react";
import { useEffect } from "react";



// const createOption = (label: string) => ({
//   label,
//   value: label
// });

// export default class CreatableInputOnly extends Component<*, State> {
//   state = {
//     inputValue: "",
//     value: []
//   };
  
//   componentDidUpdate(prevProps, prevState, snapshot){

//     if ((prevProps.defaultSelect) !== (this.state.value)) {
//        this.handleKeyDown('Enter');

//     }
//   }
//   render() {
//     return (
//       <CreatableSelect
//         components={components}
//         inputValue={this.state.inputValue}
//         isClearable
//         isMulti
//         menuIsOpen={false}
//         onChange={this.handleChange}
//         value={this.state.value}
//       />
//     );
//   }
// }
const components = {
  DropdownIndicator: null
};

const CreatableInputOnly = ({defaultSelect, guardarSeleccion}) => {

    const [inputValue, setinputValue] = useState('')
    const [value, setValue] = useState([])

    const handleChange = (value: any, actionMeta: any) => {
      console.group("Value Changed");
        setValue(value)
        guardarSeleccion(value)
      console.groupEnd();
    };
    const handleInputChange = (inputValue: string) => {
      this.setState({ inputValue });
    };
    const handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
      if (!inputValue) return;
      switch (event.key) {
        case "Enter":
        case "Tab":
          setinputValue('')
          setValue(defaultSelect)
          event.preventDefault();
      }
    };

    useEffect(() => {
        setValue(defaultSelect)
    }, [defaultSelect])

    return (
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={e => handleChange(e, '')}
        value={value}
      />
    );

}

export default CreatableInputOnly;