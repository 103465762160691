import { alertaTypes } from "../actions/types";

const initialState = {
  show: false,
  podcast:null,
  isPlaying:false,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case alertaTypes.SHOW_REPRODUCTOR:
      return {
        ...state,
        show: true,
        isPlaying:true,
        podcast:action.payload
      }
    case alertaTypes.HIDE_REPRODUCTOR:
      return {
        ...state,
        show: false,
        isPlaying:false,
        podcast:null
      }
    default:
      return state;
  }
}