import React from 'react';
import { faMapMarked,faMailBulk, faPhone } from '@fortawesome/free-solid-svg-icons';  
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Directorio = ({persona}) => {
   
    return (
    <div className="row" style={{padding:'15px', marginBottom:'10px', borderBottom:'1px solid #EEEEED'}}>
            <div className="col-md-2">
            {
                persona.image_estudiante === null ? <img
               
                      alt=""
                      src='/images/user.png'
                     style={{width:'70px', height:'70px', borderRadius:'100%'}}
                    /> :
                  <img
                      alt=""
                      src={`${process.env.REACT_APP_API_URL}/${persona.image_estudiante}`}
                      style={{width:'70px', height:'70px', borderRadius:'100%'}}
                    />
                
            }
            </div>
            <div className="col-md-10">
            <h4>{persona.nombre_estudiante.split(' ')[0]+ ' ' + persona.apellido_estudiante.split(' ')[0]}</h4>
            <p><span style={{marginRight:'5px'}}><FontAwesomeIcon icon={faMailBulk} /></span><a style={{textDecoration:'none',color:'#000'}} href={`mailto:${persona.email}`}>{persona.email}</a></p>
            <p><span style={{marginRight:'5px'}}><FontAwesomeIcon icon={faPhone} /></span><a style={{textDecoration:'none',color:'#000'}} href={`tel:${persona.celular}`}>{persona.celular}</a></p>
            <p><span style={{marginRight:'5px'}}><FontAwesomeIcon icon={faMapMarked} /></span>{persona.departamento+'-'+persona.provincia}</p>
            </div>
    </div>
    );
};

export default Directorio;