import React from 'react'
import ReactHtmlParser from 'react-html-parser';
const ModalVideo = ({dataVideo}) => {
    return (
      <div
        className="modal fade bd-example-modal-lg"
        style={{ backgroundColor: "rgba(41,48,59,.6)" }}
        id="modal-video"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg modal-video"
          style={{ maxWidth: "700px" }}
        >
          <div className="modal-content" style={{ background: "#29303b" }}>
            <div className="modal-header" style={{ borderBottom: "none" }}>
              <h5 className="modal-title text-white" id="exampleModalLabel">
                {dataVideo.nombre_archivo}
              </h5>
              <button
                type="button"
                className="close text-white"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-content" style={{ background: "#29303b" }}>
              <div className="embed-container">
              {ReactHtmlParser(dataVideo.ruta_archivo)}
                {/* <iframe
                  loading="lazy"
                  src={`${dataVideo.ruta_archivo}`}
                  allowfullscreen="allowfullscreen"
                  data-rocket-lazyload="fitvidscompatible"
                  data-lazy-src="//player.vimeo.com/video/33787818?color=c9ff23"
                  class="lazyloaded"
                  data-was-processed="true"
                  width="700"
                  height="337"
                  frameborder="0"
                ></iframe> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
 
export default ModalVideo;