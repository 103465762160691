import React from "react";

const AvisoCertificado = () => {
  return (
    <div className="aviso-certificado w-100 py-4">
      <div className="d-flex flex-column mx-auto">
        <span className='font-20 font-weight-bold'>¡Oh no! Todavía no cuentas con certificados</span>
        <span>
          Recuerda que para poder obtener tu certificado debes de terminar y haber aprobado
          tus capacitaciones.
        </span>
      </div>
    </div>
  );
};

export default AvisoCertificado;
