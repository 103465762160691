import React from 'react';
import Pregunta from './PreguntaExamen';

const listarPreguntasExamen = ({ preguntas, respuestas }) => {

  const mostrarPreguntas = () => {
    if(preguntas.length > 0) {
      return preguntas.map((pregunta,i) => (
          <Pregunta 
          num_pregunta={i+1}
          key={pregunta.pregunta.id_pregunta}
          {...pregunta}
          {...respuestas[i]}
          />
      ))
    } else {
      return <p>No hay Preguntas registradas</p>
    }
  }

  return ( 
    <>
      {mostrarPreguntas()}
    </>
  );
}
 
export default listarPreguntasExamen;