import React, { useState } from 'react'
import '../contenidoProyecto.css'
import FloatingWhatsAppButton from "../../WhatsappButton/FloatingWhatsAppButton";
//Redux
import {useDispatch, useSelector} from 'react-redux'
import { useEffect } from 'react';
import { listarDirectorio } from '../../../actions/cursosActions';
import Directorio from './Directorio';
import Pagination from './Pagination';
import { useParams } from 'react-router-dom';
const Comunidad = () => {

    //State

    const dispatch = useDispatch()
    const {idCapacitacion} = useParams()
    const [datosPaginados,setDatosPaginados] = useState({ allContacts: null, currentContacts: null, currentPage: null, totalPages: null })
    const [grupoTrabajo,setGrupoTrabajo] = useState([])
    const {allContacts,currentContacts,currentPage,totalPages} = datosPaginados
    const proyecto = useSelector(state=>state.cursos.proyecto)
    const usuario = useSelector(state=> state.usuario.usuario);
    //console.log(proyecto)
    useEffect(() => {

    const fetchData = async (id,estudiante) => {
        const rpta = await dispatch(listarDirectorio(id,estudiante))
       // console.log(rpta)
        setDatosPaginados({
          ...datosPaginados,
          allContacts: rpta.directorio
        })
        setGrupoTrabajo(rpta.grupo)
    }

    fetchData(idCapacitacion,usuario.id_usuario)
      
    }, [])

    const onPageChanged = data => {
  
      const { allContacts } = datosPaginados;
      const { currentPage, totalPages, pageLimit } = data;
      const offset = (currentPage - 1) * pageLimit;
      const currentContacts = allContacts ? allContacts.slice(offset, offset + pageLimit) : null;
      setDatosPaginados({
        ...datosPaginados,
        currentContacts:currentContacts,
        currentPage:currentPage,
        totalPages: totalPages
      })
    }


    return ( 
        <>
            <div className="content-header header-vista-content clearfix">
              <div className="row">
                <div className="col-md-8">
                    <h4 className='font-20 text-uppercase'>
                        Comunidad
                    </h4>
                </div>
                <div className="col-md-4 mb-2">
                       
                        <p className="ml-2"> <span className="font-weight-bold text-label">Grupo WhatsApp</span> {proyecto.enlace_grupo!==null ? <a className="btn btn-danger" href={proyecto.enlace_grupo} target="_blank">Únete aquí</a> : 'Sin Enlace'}</p>
                </div>
              </div>
            </div>
            <div className="body-calendar my-3 p-2">
            <div className="row py-2">
            <div className="col-md-6">
            { currentPage && (
                <span className="current-page d-inline-block h-100 pl-4 text-secondary">
                  Página <span className="font-weight-bold">{ currentPage }</span> / <span className="font-weight-bold">{ totalPages }</span>
                </span>
              ) }
            </div>
            <div className="col-md-6">
            { allContacts ? <Pagination totalRecords={allContacts.length} pageLimit={8} pageNeighbours={1} onPageChanged={onPageChanged} /> : ''}
            </div>
            </div>
              {
                currentContacts ? currentContacts.map((estudiante, i) => (
                    <Directorio key={i} persona={estudiante}/>
                  )) : ''
              }
              
            </div>
            <FloatingWhatsAppButton />
        </>
     );
}


export default Comunidad;