import React from 'react';
import styled from "@emotion/styled";

const P = styled.p`
    font-size: 13px;
    display: flex;
    align-items: center;
    margin: 0;
    width: 100%;
    cursor: pointer;
` 

const CategoriasTabla = (props) => {

    const {setSeleccionPestana} = props

    return (
      <div className="container-fluid px-0 border w-100 h-100">
        
        <div className=" p-1 bg-light">
            Pestañas
        </div>
        <div className=" p-1 d-flex align-items-center" onClick={() => setSeleccionPestana('alumnos')} >
            <P>
                <span className="material-icons mr-1">folder</span>Alumnos
            </P>
        </div>
        <div className="p-1 d-flex align-items-center bg-light " onClick={() => setSeleccionPestana('profesores')}>
            <P>
                <span className="material-icons mr-1">folder</span>Profesores
            </P>
        </div>
        <div className=" p-1 d-flex align-items-center" onClick={() => setSeleccionPestana('admin')}>
            <P>
                <span className="material-icons mr-1">folder</span>Administracion
            </P>
        </div>
      </div>
    );
}
 
export default CategoriasTabla;