import React from 'react'
import BasicMenu from '../Menus/BasicMenu';
import SlideMenu from '../Menus/BasicMenu/SlideMenu';
import "./Layout.scss";

const Layout = ({children}) => {
    return ( 
        <>
            <div className="row">
                {   /*  <div className="col-lg-3 col-xs-12">
                    <BasicMenu/>
                </div>*/
                }
                <SlideMenu />
                <div className="col-12 children-container" style={{marginTop:'78px'}}>
                    {children}
                </div>
            </div>
        </>
    );
}
export default Layout;