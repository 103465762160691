import React from 'react';

const SpinKit = ({bg}) => {
    return ( 
        <div className="spinner ml-1">
            <div className={`bounce1 bg-${bg}`}></div>
            <div className={`bounce2 bg-${bg}`}></div>
            <div className={`bounce3 bg-${bg}`}></div>
        </div>
     );
}
 
export default SpinKit;