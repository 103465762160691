import React from "react";
import authAxios from "../../../../config/authAxios";
import { generarPdf } from "../../../Helpers/funcitonsPdf";
import FilaTablaPensiones from "./FilaTablaPensiones";
import { useParams } from "react-router-dom";

const TablaPension = ({ pagos = {}, onShowModal, setCuotaExaminar }) => {

  const { pagos_ordinarios = [] } = pagos;
  const { idCapacitacion } = useParams();
  const generarRecibo = async (tipo, pago) => {
    let detallesPagos =
      pago.id_dt_matricula_modulo || pago.id_dt_matricula_proyecto;
    let dataRecibo = await authAxios.get(
      `/admin/dt_generarBoleta/${detallesPagos}/${tipo}`
    );
    // console.log(dataRecibo)
    if (!dataRecibo.data.error) {
      generarPdf(dataRecibo.data);
    }
  };

  const handleClickButton = (datos) => {
    onShowModal();
    setCuotaExaminar(datos);
  }

  return (
    <div className="tabla-cuenta table-responsive">
      <table className="table">
        <thead className="thead-cuenta text-white thead-warning">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Descripción</th>
            <th scope="col">Subtotal</th>
            <th scope="col">Penalidad</th>
            <th scope="col">Descuento</th>
            <th scope="col">Total</th>
            <th scope="col">Saldo</th>
            <th scope="col">Vencimiento</th>
            <th scope="col">Estado</th>
            <th scope="col">Detalles</th>
          </tr>
        </thead>
        <tbody className="tbody-cuenta">
          { 
            pagos_ordinarios.map((pago, index) => (
                <FilaTablaPensiones
                  key={pago.id_dt_matricula_proyecto}
                  pago={pago}
                  tipo={pagos.tipo}
                  index={index}
                  generarRecibo={generarRecibo}
                  idCapacitacion={idCapacitacion}
                  handleClickButton={handleClickButton}
                />
              ))
            }
        </tbody>
      </table>
    </div>
  );
};

export default TablaPension;
