import { faChevronCircleRight, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { obtenerExamen } from "../../../actions/cursosActions"
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Examen = (props) => {
  const { idSesion } = props;
  const {examen} = useSelector(state=> state.cursos);
  const {usuario} = useSelector(state=> state.usuario);
  const [infoExamen,setInfoExamen] = useState(null)
  const dispatch=useDispatch();

  useEffect(()=>{
    async function fetchData() {
        // You can await here
        const response = await dispatch(obtenerExamen(idSesion,usuario.id_estudiante));
        // ...
      }
      fetchData();
   
  },[idSesion])

 useEffect(()=>{
    if(examen)
    {
        setInfoExamen(examen);
    }
 },[examen])
  return (
    <>
  { infoExamen ? infoExamen.nombre_examen!=='' ? <> 
  <div className="container-recursos videos d-flex justify-content-between border-top">
      <div className="nombre-archivo p-3">
        <Link to={idSesion+'/actividad'} >
          <p className="text-info">
            <span>
              <FontAwesomeIcon
                icon={faChevronCircleRight}
                className='mr-2'
              />
            </span>
            {examen.nombre_examen}
          </p>
          </Link>
      </div>
   </div>
   <div className="container-recursos videos d-flex justify-content-between border-top">
                        <div className="nombre-archivo p-3">
                          <Link to={idSesion+'/resultado'} >
                            <p className="text-info">
                              <span>
                                <FontAwesomeIcon
                                  icon={faChevronCircleRight}
                                  className='mr-2'
                                />
                              </span>
                              Resultado
                            </p>
                            </Link>
                        </div>
    </div>
    </>
  : <>   <div className="alert alert-dismissible alert-light mensajes-info">
                      No hay Quiz para esta sesión. 
                        </div> </> : <>   <div className="alert alert-dismissible alert-light mensajes-info">
                      No hay Quiz para esta sesión. 
                        </div> </> } </>
  );
};

export default Examen;
