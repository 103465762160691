import React from "react";
import Empleo from "./Empleo";

const listaEmpleos = ({ empleos }) => {
	return empleos?.length > 0
		? empleos.map((empleo, i) => <Empleo datos={empleo} key={i} />)
		: "No hay ofertas recientes";
};

export default listaEmpleos;
