import {
  ENVIAR_MENSAJE,
  REMOVER_ESTADO_MENSAJE,
  LISTAR_MENSAJES,
  LISTAR_PROFESORES,
  MOSTRAR_MENSAJE,
  LISTAR_MENSAJES_ENVIADOS,
  MENSAJES_NO_LEIDOS,
  ACTUALIZAR_CANTIDAD_MNV,
  LIMPIAR_MENSAJE,
  ALUMNOS_CURSO,
  ELIMINAR_MENSAJE,
  LISTAR_PAPELERA,
  LISTAR_CONTACTOS,
  MANDAR_MENSAJE_E_PAPELERA,
  MANDAR_MENSAJE_R_PAPELERA,
  RESTAURAR_MENSAJE,
  ACTUALIZAR_HILO,
} from "./types";

import authAxios from "../config/authAxios";
 
export const enviarMensaje = (file) => async dispatch => {
    try {
        let respuesta = await authAxios.post(`/admin/insertarMensaje`, file)
        
        let estado = 200
        if (respuesta.status === 200) {
            estado = 200
        }

        dispatch({
            payload: {
              mensajes: respuesta.data.datos,
              estado
            },
            type: ENVIAR_MENSAJE
        })
    } catch (error) {
        console.log(error)
    }

}

export const removerEstadoMensaje = () => dispatch => {
    dispatch({
        payload: 0,
        type: REMOVER_ESTADO_MENSAJE
    })
}

export const listarBandeja = (idUsuario,nivelUsuario) => async dispatch => {
  try {
    let bandeja = await authAxios.get(
      `/admin/mostrarBandejaEntra/${idUsuario}/${nivelUsuario}`
    );

    dispatch({
        payload: bandeja.data,
        type: LISTAR_MENSAJES
    })
  } catch (error) {
    console.log(error)
  }
    
}

export const listarProfesores = idEstudiante => async dispatch => {
    let profesores = await authAxios.get(
      `/admin/mostrarDocenteDeEstudiante/${idEstudiante}`
    );

    dispatch({
      payload: profesores.data,
      type: LISTAR_PROFESORES
    });
}

export const listarPapelera = idUsuario => async dispatch => {
  
  try {
    let papelera = await authAxios.get(`/admin/mostrarPapeleraIdUsuario/${idUsuario}`)

  dispatch({
    payload: papelera.data.datos,
    type: LISTAR_PAPELERA
  })
  } catch (error) {
    console.log(error)
  }
  
}

export const mostrarMensaje = (idMensaje, idUsuario) => async dispatch => {
    let mensaje = await authAxios.get(
      `/admin/mostrarMensajeId/${idMensaje}/${idUsuario}`
    );
    //console.log(mensaje)
    dispatch({
      payload: mensaje.data,
      type: MOSTRAR_MENSAJE
    });
    dispatch({
      payload: "<p></p><p>------------------</p><p>Emisor: "+ mensaje.data.datosMensaje.nombre_emisor+" "+ mensaje.data.datosMensaje.apellido_emisor +"</p> En respuesta de:"+mensaje.data.datosMensaje.mensaje,
      type: ACTUALIZAR_HILO
    });
}

export const listarMensajesEnviados = idEstudiante => async dispatch => {
  try {
    let mensajes = await authAxios.get(
      `/admin/mostrarEnviados/${idEstudiante}`
    );

    dispatch({
      payload: mensajes.data,
      type: LISTAR_MENSAJES_ENVIADOS
    });
  } catch (error) {
    console.log(error)
  }
    
}

export const cantidadMensajesNoVistos = idUsuario => async dispatch => {
  try {
      let mensajesnoVistos = await authAxios.get(`/admin/cantidadMensajesBandejaEntrada/${idUsuario}`)

      dispatch({
        payload: parseInt(mensajesnoVistos.data.cantidad_mensajes),
        type: MENSAJES_NO_LEIDOS
      })
    
  } catch (error) {
    console.log(error)  
  }
}

export const editarCanMensajesNV = (idMensaje, idUsuario) => async dispatch => {
  const dataEnviar = {
    id_usuario: idUsuario,
    mensajesParaCammbiarEstado: [{id_mensaje: idMensaje}]
  } 
  let cantidadNuevaMensajesNoLeidas = await authAxios.put(`/admin/cambiarEstadoLeidoNoLeidoBandeja`,dataEnviar)
  dispatch({
    payload: parseInt(cantidadNuevaMensajesNoLeidas.data.cantidad_mensajes),
    type: ACTUALIZAR_CANTIDAD_MNV
  })
}

export const mandarMensajeBandejaPapelera = (idUsuario, mensajes) => async dispatch => {

  let dataMensajeCambio = {
    id_usuario: idUsuario,
    mensajesParaPapelera: mensajes
  }

  let respuesta = await authAxios.put(`/admin/mandarPapeleraIdMensajeIdUsuarioBandeja`, dataMensajeCambio)

  if (respuesta.data.mensaje === 'SE CAMBIO EL ESTADO DE MENSAJE'
    || respuesta.data.mensaje === 'NO HAY FILAS PARA MOSTRAR'
    || respuesta.data.mensaje === 'SE MOSTRO CON EXITO LA PAPELERA') {

    dispatch({
      payload:{
        papelera: respuesta.data.papelera,
        bandeja: respuesta.data.bandeja
      },
      type: MANDAR_MENSAJE_R_PAPELERA
    })

    return { mensaje: true }
  }

  return { mensaje: false }

}

export const mandarMensajeEnviadosPapelera = (idUsuario, mensajes) => async dispatch => {
  let dataMensajeCambio = {
    id_usuario: idUsuario,
    mensajesParaPapelera: mensajes
  }

  let respuesta = await authAxios.put(`/admin/mandarPapeleraIdMensajeIdUsuarioEnviados`, dataMensajeCambio)

  if (
    respuesta.data.mensaje === "SE MOSTRO CON EXITO LA PAPELERA" ||
    respuesta.data.mensaje === "NO HAY FILAS PARA MOSTRAR"
  ) {
    dispatch({
      payload: {
        papelera: respuesta.data.papelera,
        enviados: respuesta.data.enviados
      },
      type: MANDAR_MENSAJE_E_PAPELERA
    });

    return { mensaje: true };
  }

  return {mensaje: false}
}

export const listarContactos = (idUsuario, nivelUsuario) => async dispatch => {
  let contactos = await authAxios.get(`/admin/mostrarListaDeContactoMensaje/${idUsuario}/${nivelUsuario}`)
  dispatch({
    payload: contactos.data,
    type: LISTAR_CONTACTOS 
  })
} 

export const retaurarMensaje = (idUsuario, mensajes) => async dispatch => {

  const dataMensajes = {
    id_usuario: idUsuario,
    restaurados: mensajes
  }

  let respuesta = await authAxios.put(`/admin/restaurarMensaje`, dataMensajes)
  
  if (respuesta.data.mensaje === 'SE MOSTRO CON EXITO LA PAPELERA'
    || respuesta.data.mensaje === 'NO HAY FILAS PARA MOSTRAR') {
    dispatch({
      payload: respuesta.data.datos,
      type: RESTAURAR_MENSAJE
    })

    return {mensaje: true}
  }

  return {mensaje: false}
}

export const eliminarMensaje = (dataMensajesEliminados) => async dispatch => {

  let respuesta = await authAxios.put(`/admin/eliminarMensajeId`, dataMensajesEliminados);
  if (
    respuesta.data.mensaje === "SE MOSTRO CON EXITO LA PAPELERA" ||
    respuesta.data.mensaje === 'NO HAY FILAS PARA MOSTRAR'
  ) {
    dispatch({
      payload: respuesta.data.datos,
      type: ELIMINAR_MENSAJE
    });
    return { mensaje: true };
  }

  return{mensaje: false}
} 

export const limpiarMensaje = () => dispatch => {
  dispatch({
    paylod: [],
    type: LIMPIAR_MENSAJE
  })
}
