export const setJwt = (data) => {
  sessionStorage.setItem("user-JWT", JSON.stringify(data));
};

export const getJWT = () => {
  return sessionStorage.getItem("user-JWT");
};

export const deleteJWt = () => {
  return sessionStorage.removeItem("user-JWT");
};


//ESTILOS ROW PAGOS
export const colorCelda = (estado) => {
  if (estado === "Pendiente") {
    return "bg-warning";
  }
  if (estado === "Cancelado") {
    return "bg-success";
  }
};

//agregar nombre de archivo selecionado
export const agregarNombre = (nombre) => {
  const inputNombre = document.getElementById("fileArchivo");

  inputNombre.innerHTML = `<p>${nombre}</p>`;
};

export const aparecerSeleccion = () => {
  const inputFile = document.getElementById("fileInput");

  inputFile.click();
};

export const getElement = (id) => {
  return document.getElementById(id);
};

function abortRead() {
  var reader;
  reader.abort();
}

function errorHandler(evt) {
  switch (evt.target.error.code) {
    case evt.target.error.NOT_FOUND_ERR:
      alert("File Not Found!");
      break;
    case evt.target.error.NOT_READABLE_ERR:
      alert("File is not readable");
      break;
    case evt.target.error.ABORT_ERR:
      break; // noop
    default:
      alert("An error occurred reading this file.");
  }
}

function updateProgress(evt) {
  // evt is an ProgressEvent.
  if (evt.lengthComputable) {
    var percentLoaded = Math.round((evt.loaded / evt.total) * 100);
    // Increase the progress bar length.
    var progress = document.querySelector(".percent");

    if (percentLoaded < 100) {
      progress.style.width = percentLoaded + "%";
      progress.textContent = percentLoaded + "%";
    }
  }
}

export function handleFileSelect(evt) {
  var reader;
  var progress = document.querySelector(".percent");

  // Reset progress indicator on new file selection.
  progress.style.width = "0%";
  progress.textContent = "0%";

  reader = new FileReader();
  reader.onerror = errorHandler;
  reader.onprogress = updateProgress;
  reader.onabort = function (e) {
    alert("File read cancelled");
  };
  reader.onloadstart = function (e) {
    document.getElementById("progress_bar").className = "loading";
  };
  reader.onload = function (e) {
    // Ensure that the progress bar displays 100% at the end.
    progress.style.width = "100%";
    progress.textContent = "100%";
    setTimeout("document.getElementById('progress_bar').className='';", 2000);
  };

  // Read in the image file as a binary string.
  reader.readAsBinaryString(evt.target.files[0]);
}

//DIA CONVERTIDOR
export const getDia = (fecha) => {
  var nuevaFecha = new Date(fecha);
  var x = document.getElementById("fecha");
  let date = new Date(fecha.replace(/-+/g, "/"));

  let options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return date.toLocaleDateString("es-MX", options).replace("de", "");
};

//Convertir nde numero a letras

let decena = "",
  unidad = "",
  centenas = "",
  decenas = "",
  cientos = "",
  resto = "",
  letras = "",
  divisor = "",
  strMiles = "",
  strCentenas = "",
  strMillones;

function Unidades(num, prepo) {
  switch (num) {
    case 1:
      return "UN";

    case 2:
      return "DOS";

    case 3:
      if(prepo === 'DIECI' || prepo === 'VEINTI') return "TRÉS" 
      return "TRES";

    case 4:
      return "CUATRO";

    case 5:
      return "CINCO";

    case 6:
      if(prepo === 'DIECI' || prepo === 'VEINTI') return "SEÍS" 
      return "SEIS";

    case 7:
      return "SIETE";

    case 8:
      return "OCHO";

    case 9:
      return "NUEVE";
  }

  return "";
}

function Decenas(num) {
  decena = Math.floor(num / 10);

  unidad = num - decena * 10;

  switch (decena) {
    case 1:
      switch (unidad) {
        case 0:
          return "DIEZ";

        case 1:
          return "ONCE";

        case 2:
          return "DOCE";

        case 3:
          return "TRECE";

        case 4:
          return "CATORCE";

        case 5:
          return "QUINCE";

        default:
          return "DIECI" + Unidades(unidad, 'DIECI');
      }

    case 2:
      switch (unidad) {
        case 0:
          return "VEINTE";

        default:
          return "VEINTI" + Unidades(unidad, 'VEINTI');
      }

    case 3:
      return DecenasY("TREINTA", unidad);

    case 4:
      return DecenasY("CUARENTA", unidad);

    case 5:
      return DecenasY("CINCUENTA", unidad);

    case 6:
      return DecenasY("SESENTA", unidad);

    case 7:
      return DecenasY("SETENTA", unidad);

    case 8:
      return DecenasY("OCHENTA", unidad);

    case 9:
      return DecenasY("NOVENTA", unidad);

    case 0:
      return Unidades(unidad);
  }
} //Unidades()

function DecenasY(strSin, numUnidades) {
  if (numUnidades > 0) return strSin + " Y " + Unidades(numUnidades);

  return strSin;
} //DecenasY()

function Centenas(num) {
  centenas = Math.floor(num / 100);

  decenas = num - centenas * 100;

  switch (centenas) {
    case 1:
      if (decenas > 0) return "CIENTO " + Decenas(decenas);

      return "CIEN";

    case 2:
      return "DOSCIENTOS " + Decenas(decenas);

    case 3:
      return "TRESCIENTOS " + Decenas(decenas);

    case 4:
      return "CUATROCIENTOS " + Decenas(decenas);

    case 5:
      return "QUINIENTOS " + Decenas(decenas);

    case 6:
      return "SEISCIENTOS " + Decenas(decenas);

    case 7:
      return "SETECIENTOS " + Decenas(decenas);

    case 8:
      return "OCHOCIENTOS " + Decenas(decenas);

    case 9:
      return "NOVECIENTOS " + Decenas(decenas);
  }

  return Decenas(decenas);
} //Centenas()

function Seccion(num, divisor, strSingular, strPlural) {
  cientos = Math.floor(num / divisor);

  resto = num - cientos * divisor;

  letras = "";

  if (cientos > 0)
    if (cientos > 1) letras = Centenas(cientos) + " " + strPlural;
    else letras = strSingular;

  if (resto > 0) letras += "";

  return letras;
} //Seccion()

function Miles(num) {
  divisor = 1000;

  cientos = Math.floor(num / divisor);

  resto = num - cientos * divisor;

  strMiles = Seccion(num, divisor, "MIL", "MIL");

  strCentenas = Centenas(resto);

  if (strMiles == "") return strCentenas;

  return strMiles + " " + strCentenas;

  //return Seccion(num, divisor, "UN MIL", "MIL") + " " + Centenas(resto);
} //Miles()

function Millones(num) {
  divisor = 1000000;

  cientos = Math.floor(num / divisor);

  resto = num - cientos * divisor;

  console.log(resto);

  strMillones = Seccion(num, divisor, "UN MILLON", "MILLONES");

  strMiles = Miles(resto);

  if (strMillones == "") return strMiles;

  return strMillones + " " + strMiles;

  //return Seccion(num, divisor, "UN MILLON", "MILLONES") + " " + Miles(resto);
} //Millones()

export function NumeroALetras(num, centavos) {
    var data = {
      numero: num,
  
      enteros: Math.floor(num),
  
      centavos: Math.round(num * 100) - Math.floor(num) * 100,
  
      letrasCentavos: "",
    };
  
    if (centavos == undefined || centavos == false) {
      data.letrasMonedaPlural = "SOLES";
  
      data.letrasMonedaSingular = "SOL";
    } else {
      data.letrasMonedaPlural = "CENTIMOS";
  
      data.letrasMonedaSingular = "CENTIMO";
    }
  
    if (data.centavos >= 0)
      if (data.centavos <= 9) {
        data.letrasCentavos =
          "CON " + "0" + data.centavos + "/100 ";
      } else {
        data.letrasCentavos =
          "CON " + data.centavos + "/100 ";
      }
  
    if (data.enteros == 0) return "CERO ";
  
    if (data.enteros == 1)
      return Millones(data.enteros);
    else return Millones(data.enteros);
  }
  

export function NumeroALetrasDinero(num, centavos) {
  var data = {
    numero: num,

    enteros: Math.floor(num),

    centavos: Math.round(num * 100) - Math.floor(num) * 100,

    letrasCentavos: "",
  };

  if (centavos == undefined || centavos == false) {
    data.letrasMonedaPlural = "SOLES";

    data.letrasMonedaSingular = "SOL";
  } else {
    data.letrasMonedaPlural = "CENTIMOS";

    data.letrasMonedaSingular = "CENTIMO";
  }

  if (data.centavos >= 0)
    if (data.centavos <= 9) {
      data.letrasCentavos =
        "CON " + "0" + data.centavos + "/100 " + data.letrasMonedaPlural;
    } else {
      data.letrasCentavos =
        "CON " + data.centavos + "/100 " + data.letrasMonedaPlural;
    }

  if (data.enteros == 0) return "CERO " + " " + data.letrasCentavos;

  if (data.enteros == 1)
    return Millones(data.enteros) + " " + data.letrasCentavos;
  else return Millones(data.enteros) + " " + data.letrasCentavos;
}
