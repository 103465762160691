import React, { useEffect } from "react";

import Select from "react-select";
import makeAnimated from "react-select/animated";

const animatedComponents = makeAnimated();


export default function AnimatedMulti({dataContactosAlumnos,defaultSelect, guardarSeleccion}) {

    const handleChange = (item) => {
        guardarSeleccion(item);
    }   

  return (
    <Select
      closeMenuOnSelect={false}
      value={defaultSelect}
      components={animatedComponents}
      defaultValue={defaultSelect}
      isMulti
      onChange={item => handleChange(item)}
      options={dataContactosAlumnos}
    />
  );
}