import React from 'react';
import Comunidad from './Comunidad';
import GrupoAcademico from './Grupo';

const ContentComunidad = () => {
    return ( 
        <div className="content-horario">
            <div className="content-horario-agenda p-3 bg-white shadow-sm m-1 mt-3">
                <GrupoAcademico />
            </div>
            <div className="content-horario-agenda p-3 bg-white shadow-sm m-1 mt-3">
                <Comunidad/>
            </div>
      
        </div>
     );
}
 
export default ContentComunidad;