import React, { useState } from 'react';
import Layout from "../../components/Layout/Layout";
import { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { verExamen, guardarRespuestasExamen, guardarRespuestasExamenOutTime} from '../../actions/cursosActions'
import { useDispatch, useSelector } from 'react-redux';
import ListarPreguntasExamen from './Components/ListarPreguntasExamen';
import { Card, Spinner, Alert } from "react-bootstrap";
import { examenesTypes } from '../../actions/types'
import CountDownTime from './CountDownTimer/CountDownTime';
import FloatingWhatsAppButton from "../../components/WhatsappButton/FloatingWhatsAppButton"

const VistaPreviaExamen = () => {
    const { examen } = useSelector(state => state.cursos);
    const { idSesion, idCurso } = useParams();
    const dispatch = useDispatch();
    const { usuario } = useSelector(state => state.usuario)

    const { respondido, tiempoTerminado } = useSelector(state => state.cursos)
    const { intentosRealizados } = useSelector(state => state.cursos)

    const history = useHistory();
    const initialStatePreguntas = () => ({
        id_examen: examen.id_examen,
        id_sesion: idSesion,
        id_modulo: idCurso,
        usuario: usuario.id_estudiante,
        id_usuario: usuario.id_usuario,
        respuestas_marcadas: []
    })
    const [examen_res, setExamen] = useState(null);
    const [respuestas, setRespuestas] = useState(initialStatePreguntas());
    useEffect(() => {
        async function fetchData() {
            // You can await here
            const response = await dispatch(verExamen(examen.id_examen, usuario.id_estudiante));
            setExamen(response.examen);
            // ...
        }
        fetchData();

    }, [])

    useEffect(() => {
        if (respondido) {
            dispatch({
                type: examenesTypes.INSERTAR_EXAMEN
            })
            setTimeout(() => {
                history.push('/capacitaciones/' + idCurso + '/sesiones/' + idSesion + '/resultado')
            }, 2000)
        }
    }, [respondido])

    useEffect(() => {
        if (tiempoTerminado) {
            dispatch({
                type: examenesTypes.TIEMPO_EXAMEN_TERMINADO_EXITO
            })
            dispatch(guardarRespuestasExamenOutTime(respuestas));
            setTimeout(() => {
                history.push('/capacitaciones/' + idCurso + '/sesiones/' + idSesion + '/resultado')
            }, 2000)
        }
    }, [tiempoTerminado])
    const handleChange = (event) => {
        let arregloActualizado = [];
        let respuesta_sel = { id: event.target.id, pregunta: event.target.name, respuesta: event.target.value };

        const copiaEliminar = [...respuestas.respuestas_marcadas];
        arregloActualizado = copiaEliminar.filter(respuesta => respuesta.pregunta !== respuesta_sel.pregunta);
        const copiaAgregar = [...arregloActualizado, respuesta_sel];
        setRespuestas({
            ...respuestas,
            respuestas_marcadas: copiaAgregar,
        });
    }
    const handleSubmit = (event) => {


        event.preventDefault()
        dispatch(guardarRespuestasExamen(respuestas))

    }
    return (

        <Layout>
            <div className="container content mt-5">
                {examen_res ? examen_res.length > 0 ?
                    <> <div style={{ maxWidth: "850px", margin: "auto" }}>
                        { //<div style={{height:'150px', background: "url('https://intranet.inedi.edu.pe/images/cabecera_examen.png') center center no-repeat", backgroundSize:"cover", marginBottom:"10px", borderRadius:"8px"}}></div>
                        }
                        <Card style={{ borderTop: '#27314A 5px solid', marginBottom: '15px' }}>

                            <Card.Body>
                                <h1>{examen_res ? examen_res[0].pregunta.nombre_examen : ''}</h1>
                                <p style={{ fontSize: '18px' }}>{examen_res ? examen_res[0].pregunta.descripcion_examen : ''}</p>
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div style={{ margin: '5px' }}>Intento N°{intentosRealizados+1}</div>
                                    <div style={{ margin: '5px' }}>Preguntas: {examen_res.length}</div>
                                </div>
                                <CountDownTime
                                    tiempo={examen.duracion}
                                    quizId={examen.id_examen}
                                />
                            </Card.Body>

                        </Card>

                        <form id="examen_res" onSubmit={handleSubmit}>

                            {examen_res && <ListarPreguntasExamen preguntas={examen_res} handleChange={handleChange} />}


                            <button type="submit" className="btn btn-lg btn-primary mb-5">Enviar</button>

                        </form>
                    </div>

                    </>
                    : <>  <Alert variant="info">
                        No hay Actividad para esta sesión.  <Link className="btn btn-primary" to={'/capacitaciones/' + idCurso + '/sesiones'} style={{ marginBottom: '10px' }}> Volver a Sesiones </Link>
                    </Alert></> : <div className="w-100 h-100 d-flex align-items-center" style={{ minHeight: "500px", background: "rgba(0,0,0,.01)" }}>

                    <div className="sk-folding-cube ">
                        <div className="sk-cube1 sk-cube "></div>
                        <div className="sk-cube2 sk-cube "></div>
                        <div className="sk-cube4 sk-cube "></div>
                        <div className="sk-cube3 sk-cube "></div>
                    </div>
                </div>}

            </div>
            <FloatingWhatsAppButton />
        </Layout>

    );
};

export default VistaPreviaExamen;