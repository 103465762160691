import { Build } from "@material-ui/icons";
import React from "react";
import { useParams } from "react-router-dom";
import TablaCalificaciones from "../../Tablas/TablaCalificaciones";
import FloatingWhatsAppButton from "../../WhatsappButton/FloatingWhatsAppButton";

const ValotarioNotas = () => {
	const { idCapacitacion } = useParams();
	return (
		<div className="content-notas bg-white shadow-sm m-1 p-3">
			<div className="content-header header-vista-content py-1">
				<h4 className="mb-0 title text-uppercase font-20">
					calificaciones
				</h4>
				{(parseInt(idCapacitacion) >= 45 &&
					parseInt(idCapacitacion) < 73) ||
				parseInt(idCapacitacion) === 31 ||
				parseInt(idCapacitacion) === 32 ||
				parseInt(idCapacitacion) === 35 ? (
					<>
						{" "}
						Revisa el Sistema de Evaluación:{" "}
						<a
							href={
								"https://apisistema.gestoraperu.pe/files_inedi/sistema_evaluacion.pdf"
							}
							className="btn btn-primary"
							target="_blank"
							rel="noopener noreferrer"
						>
							Aquí
						</a>
					</>
				) : (
					<>
						{" "}
						Revisa el Sistema de Evaluación:{" "}
						<a
							href={
								"https://apisistema.gestoraperu.pe/files_inedi/Sistema-de-calificacion-1.pdf"
							}
							className="btn btn-primary"
							target="_blank"
							rel="noopener noreferrer"
						>
							Aquí
						</a>
					</>
				)}
			</div>
			<div className="content-body-cuenta my-4">
				<TablaCalificaciones />
			</div>
			<FloatingWhatsAppButton />
		</div>
	);
};

export default ValotarioNotas;
