import React from 'react'
import {getDia} from '../../Helpers/FunctionsHelpers'
//REDUX
import {connect} from 'react-redux' 
import { listarBandeja, mostrarMensaje, editarCanMensajesNV} from "../../../actions/MensajesActions";
import ReactHtmlParser from "react-html-parser";

const ListaMensajes = (props) => {

  //FUNCIONES
  const { mostrarMensaje, editarCanMensajesNV, guardarMensajesSeleccionados } = props;
  //STATES
  const { title, mensajesEnviados, bandejaMensajes, usuario, mensajesPapelera } = props;
  
  //
  const verMensaje = (e, id_mensaje, id_usuario) => {
    e.preventDefault();

    mostrarMensaje(id_mensaje, id_usuario);
    if (title === 'Recibidos' && usuario) {
      // editarCanMensajesNV(id_mensaje, usuario.id_usuario)
    }
  };


  const contenidoLista = () => {
    if (title === "Recibidos") {
       return bandejaMensajes.length > 0 ? bandejaMensajes.map(item => (
         <React.Fragment key={item.id_mensaje}>
           {liMensajes(item, getDia, verMensaje, guardarMensajesSeleccionados, usuario)}
         </React.Fragment>
       )): <div className=" alert alert-light text-center text-bold">
              No hay mensajes para mostrar
            </div> ;
      
    }

    if (title === 'Enviados') {
      return mensajesEnviados.length > 0
        ? mensajesEnviados.map(item => (
            <React.Fragment key={item.id_mensaje}>
            {liMensajes(item, getDia, verMensaje, guardarMensajesSeleccionados, usuario)}
            </React.Fragment>
          ))
        : <div className=" alert alert-light text-center text-bold">
              No hay mensajes para mostrar
            </div> ;
    }
    if (title === 'Papelera') {
      return mensajesPapelera.length > 0
        ? mensajesPapelera.map(item => (
            <React.Fragment key={item.id_mensaje}>
            {liMensajes(item, getDia, verMensaje, guardarMensajesSeleccionados, usuario)}
            </React.Fragment>
          ))
        : <div className=" alert alert-light text-center text-bold">
              No hay mensajes para mostrar
          </div> ;;
    }
  }

  return (
    <ul
      className="list-group w-100"
      style={{ maxHeight: "400px", minHeight: "350px", overflowY: "scroll" }}
    >
      {contenidoLista()}
    </ul>
  );
};

const mapStateToProps = state => ({
  bandejaMensajes: state.mensajes.bandejaMensajes,
  mensajesEnviados: state.mensajes.mensajesEnviados,
  mensajesPapelera: state.mensajes.mensajesPapelera,
  usuario: state.usuario.usuario
})

export default connect(mapStateToProps, { listarBandeja, mostrarMensaje, editarCanMensajesNV })(
  ListaMensajes
);

const liMensajes = (item, getDia, verMensaje, guardarMensajesSeleccionados, usuario) => {
  return (
    <li
      className="list-group-item border-right-0 border-bottom hover-light cursor-pointer border-left-0 px-3"
    >
      <div className="container-fluid pl-2 p-0">
        <div className="row p-0">
        <div className="col-1 px-0">
          <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                onChange={e => guardarMensajesSeleccionados(e.target)}
                id={item.id_mensaje}
              />
              <label className="custom-control-label" htmlFor={item.id_mensaje}>
              </label>
            </div>
          </div>
        </div>
          <div className="col-8 px-0 pl-1" onClick={e => verMensaje(e, item.id_mensaje, usuario.id_usuario)}>
            <p className="mb-0 text-uppercase" style={{ color: "#454545", fontSize: "15px" }}>
              {item.id_emisor
                ? `${item.nombre_emisor} ${item.apellido_emisor}`
                : `${item.nombre_receptor} ${item.apellido_receptor}`}
            </p>
            <p className="mb-0" style={{ color: "#454545", fontSize: "11px" }}>
              {item.asunto}
            </p>
            <div className="mb-0" style={{ color: "#888" }}>
              {contenidoMensaje(item.mensaje)}
            </div>
          </div>
          <div className="col-2 px-0">
            <p style={{ color: "#565656", fontSize: "11px" }}>{getDia(item.hora_msj ? item.hora_msj : item.fecha_hora)}</p>
          </div>
        </div>
      </div>
    </li>
  );
};

const contenidoMensaje = (men) => {
  let mensaje = (ReactHtmlParser(men)[0]);
  if (mensaje !== undefined) {
    return null
  }
  
  return null
}