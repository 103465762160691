import React, {useState} from "react";
import * as moment from 'moment'
import Swal from "sweetalert2";
import {aparecerSeleccion} from '../../Helpers/FunctionsHelpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';

//Redux
import {connect} from 'react-redux'
import {listarTareasEstudiante, eliminarTareas} from '../../../actions/cursosActions'
import authAxios from "../../../config/authAxios";

const ModalTareas = (props) => {
  
  const { carga,tarea,usuario, idSesion,listarTareasEstudiante,eliminarTareas, tareasEstudiantes, detalleNivel } = props;

  const [subiendo, setSubiendo] = useState()

  const agregarTarea = async e => {
    e.preventDefault();
    setSubiendo(true)

    let archivo = e.target.files[0];
    let dato = new FormData();
   

    dato.append("id_estudiante", detalleNivel[0].id_estudiante);
    dato.append("file_tarea", archivo);
    dato.append("id_sesion", idSesion);
    dato.append("id_tarea", tarea.id_tarea);
    dato.append("fecha_sub",moment().format('YYYY-MM-DD'));
    dato.append("hora_sub",moment().format('hh:mm:ss'));
    
    let myConfig = {
      header: {
        "Content-Type": "application/json",
        "Content-Type": "image/jpeg",
        "Content-Type": "image/jpg",
        "Content-Type": "multipart/form-data"
      },
    };
    let respuesta = await authAxios.post(`/admin/subirTareasEstudiante`, dato, myConfig);
    if (respuesta.data) {
      setSubiendo(false)
    }
    if (Object.keys(respuesta.data).length > 0) {
      listarTareasEstudiante(tarea.id_tarea, detalleNivel[0].id_estudiante);
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons.fire(
        "Tarea enviada!",
        "Archivo enviado correctamente",
        "success"
      );
    }
  }

  const eliminarTareaEstudiante = (idTareaEstudiante) => {

    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });
    swalWithBootstrapButtons
      .fire({
        title: "¿Estas seguro?",
        text: "No se podra recuperar el archivo",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, continuar!",
        cancelButtonText: "No, cancelar!",
        reverseButtons: true,
        showLoaderOnConfirm: true,
        preConfirm: async function() {
          //consultando para ver el mensaje de aprovación
          if ((await eliminarTareas(idTareaEstudiante, usuario.id_usuario)).mensaje) {
            return true;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "La hubo un error al eliminar el archivo"
            });
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      })
      .then(result => {
        //mensaje que se cambio la contraseña satisfactoriamente
        if (result.value === true) {
          swalWithBootstrapButtons.fire(
            "Eliminado!",
            "El archivo se elimino exitosamente",
            "success"
          );
        }
      });
    
    
  }


  return (
    <div
      className="modal fade"
      id="modal-tareas"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {tarea.nombre_tarea}
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {carga ? (
              <>
                <div className="botones">
                  <BotonesAcciones
                    aparecerSeleccion={aparecerSeleccion}
                  />
                </div>
                <div className="subida">
                  <InputSubirTarea
                    agregarTarea={agregarTarea}
                  />
                </div>
                <div className="tabla-cuenta table-responsive">
                  <table className="table table-hover table-striped">
                    <thead className="thead-cuenta text-white thead-warning">
                      <tr>
                        <th scope="col">Item</th>
                        <th scope="col">Archivo</th>
                        <th scope="col">Fecha</th>
                        <th scope="col">Acción</th>
                      </tr>
                    </thead>
                    <tbody className="tbody-cuenta">
                    {
                      tareasEstudiantes.tareas_discente.length > 0
                      ?
                        tareasEstudiantes.tareas_discente.map((tarea, index) => (
                          <TareaEstudiante
                            key={tarea.id_tareas_estudiante}
                            tareaEstu={tarea}
                            index={index}
                            eliminarTareaEstudiante={eliminarTareaEstudiante}
                          />
                        ))
                      : 'No hay archivos subidos'
                    }
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div className="aviso-cargando">
              <div className="sk-chase">
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
                <div className="sk-chase-dot"></div>
              </div>
                Cargando
              </div>
             
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  tareasEstudiantes: state.sesiones.tareasEstudiantes,
  detalleNivel: state.usuario.detalleNivel,
  usuario: state.usuario.usuario
})

const mapDispatchToProps = {
  listarTareasEstudiante,
  eliminarTareas
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalTareas);

const BotonesAcciones = ({aparecerSeleccion}) => {
  return (
    <>
      <button
        type="button"
        className="btn btn-sm"
        title=''
        data-container="body"
        data-toggle="popover"
        data-placement="bottom"
        data-content="Aquí podrá ver sus archivos subidos de las tareas respectivas"
      >
        <img src="/images/info.png" alt=""/>
      </button>
      <button
        title='Elegir archivo'
        type="button"
        onClick={() => aparecerSeleccion()}
        className="btn btn-sm subir-tarea"
      >
        <img src="/images/cloud.png" alt=""/>
      </button>
    </>
  );
};

const InputSubirTarea = ({agregarTarea}) =>{
  return (
    <input
        type="file"
        id="fileInput"
        onChange={e => agregarTarea(e)}
        className="form-control d-none"
      />
  )
}

const TareaEstudiante = (props) => {
  
  const {tareaEstu, index, eliminarTareaEstudiante} = props

  return (

    <tr>
      <td>
        {index + 1}
      </td>
      <td>
        <p><p>{tareaEstu.nombre_tarea_est}</p></p>
      </td>
      <td>
        <p className='text-capitalize'>
          {moment(tareaEstu.fecha_tarea_presentada).format('DD MMM YYYY')}
        </p>
      </td>
      <td>
        <a
          className='btn btn-warning btn-sm mr-2'
          href={`https://docs.google.com/viewerng/viewer?url=${process.env.REACT_APP_API_URL}/${tareaEstu.ruta_tarea_est}`}
          target='_black'
        >
          <FontAwesomeIcon icon={faEye} />
        </a>
        <button
          type='button'
          className='btn btn-danger btn-sm'
          onClick={() => eliminarTareaEstudiante(tareaEstu.id_tareas_estudiante)}
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </td>
  </tr>
  )
}
