import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Layout from '../../components/Layout/Layout';
import './ruleta2.css';
import { Modal } from 'react-bootstrap';
import Premio from '../../components/Ruleta/premio';


const Ruleta2 = () => {
    const { usuario } = useSelector(state => state.usuario);
    // Define una referencia para la sección de destino
    const targetSectionRef = useRef(null);

    // Función para hacer scroll a la sección de destino
    const handleScroll = () => {
        if (targetSectionRef.current) {
          targetSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      };
      

    const premios = [
        { nombre: "10% en todas tus mensualidades", angulo: 0, detalle: "un descuento en tus mensualidades", tipo: 1 },
        { nombre: "Samsung Galaxy", angulo: 60, detalle: "un celular de última generación", tipo: 3 },
        { nombre: "10% en todas tus mensualidades", angulo: 120, detalle: "un descuento en tus mensualidades", tipo: 1 },
        { nombre: "Una beca al curso", angulo: 180, detalle: "el acceso a un curso en vivo", tipo: 2 },
        { nombre: "2 Becas a cursos", angulo: 240, detalle: "el acceso a dos cursos en vivo", tipo: 2 },
        { nombre: "5% en todas tus mensualidades", angulo: 300, detalle: "un descuento en tus mensualidades", tipo: 1 },
    ];
    const [ancho, setAncho] = useState(1);
    const [rotation, setRotation] = useState(0);
    const barraRef = useRef(null);
    const [premio, setPremio] = useState({});
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [showContinue, setShowContinue] = useState(false);
    const [showPremio, setShowPremio] = useState(false);

    const lanzar = () => {
        barraRef.current.classList.toggle('parate');
        const width2 = barraRef.current.getBoundingClientRect().width;
        setAncho(width2)
        girar()
    }

    //logica de giro por dias
    const girar = () => {
        const today = new Date();
        const day = today.getDate();
        let anguloDestino = 0;
        if (day >= 1 && day <= 15) {
            //0 o 2 : 10% en todas tus mensualidades
            setPremio(premios[0])
            const min = premios[0].angulo - 25;
            const max = premios[0].angulo + 25;
            const angulo = Math.floor(Math.random() * (max - min + 1)) + min;
            anguloDestino = angulo;
        } else if (day >= 15 && day <= 20) {
            //5 : 5% en todas tus mensualidades
            setPremio(premios[5])
            const min = premios[5].angulo - 25;
            const max = premios[5].angulo + 25;
            const angulo = Math.floor(Math.random() * (max - min + 1)) + min;
            anguloDestino = angulo;

        } else if (day >= 21 && day <= 25) {
            //4 : 2 Becas a cursos
            setPremio(premios[4])
            const min = premios[4].angulo - 25;
            const max = premios[4].angulo + 25;
            const angulo = Math.floor(Math.random() * (max - min + 1)) + min;
            anguloDestino = angulo;
        } else if (day >= 26 && day <= 31) {
            //3 : Una beca al curso SUNAFIL
            setPremio(premios[3])
            const min = premios[3].angulo - 25;
            const max = premios[3].angulo + 25;
            const angulo = Math.floor(Math.random() * (max - min + 1)) + min;
            anguloDestino = angulo;
        }
        setRotation((360 * 10) + anguloDestino);
    }

    const handleTransitionEnd = () => {
        setModalIsOpen(true);
        setShowContinue(true);
    };

    const closeModal = () => {

        setModalIsOpen(false);

    };
    const handleContinue = () => {
        setShowPremio(true);
    }

    useEffect(() => {
        if (!modalIsOpen) {
            setTimeout(() => setModalIsOpen(false), 400); // Asegura el cierre
        }
    }, [modalIsOpen]);





    useEffect(() => {

    }, []);


    return (
        <Layout>
            {!showPremio &&
            <div className="afiliate-container pb-4 pt-4"
                style={{
                    backgroundImage: `url('./images/assets/fondo_ruleta.png')`,
                }}>
                <div className="text-center">
                    <div className="box-image mb-3">
                        <img src="./images/assets/premios_afiliate.png" alt="Premios y Beneficios" className="img-fluid" />
                    </div>
                    <h2 className="affiliate-title">
                        Afíliate al pago recurrente y <br />
                        <span className="highlight-afiliate">gana los mejores beneficios y premios</span>
                    </h2>
                    <button className="affiliate-button mt-2" onClick={handleScroll}>¡Quiero participar!</button>
                </div>
            </div>}
            <div className="plafon" ref={targetSectionRef}>
                {!showPremio ?
                    <>
                        <div className="ruleta"
                            style={{
                                backgroundImage: `url('./images/assets/ruleta.png')`,
                                transform: `rotate(${rotation}deg)`,
                                transition: "transform 6s cubic-bezier(0.25, 0.1, 0.25, 1)"
                            }}
                            onTransitionEnd={handleTransitionEnd}
                        >

                        </div>
                        <div className="premio"></div>
                        <div className="barra1">
                            <div className="mi_barra" ref={barraRef}></div>
                        </div>
                        {(!showContinue) ? <button className="boton-ruleta" onClick={lanzar}><strong>Gira la ruleta</strong></button>
                            : <button className="boton-ruleta" onClick={handleContinue}>
                                <strong> Continuar </strong>
                            </button>
                        }

                        <div className="central">
                            <img src="./images/assets/central.png" alt=""></img>
                        </div>
                    </>
                    : <><Premio premio={premio} ></Premio></>}
            </div>
            <Modal
                show={modalIsOpen}
                premio={premio}
                centered
                className="Modal"
                onHide={closeModal}

            >
                <Modal.Header closeButton={closeModal}>
                    <Modal.Title>¡Felicidades {usuario.nombre_usuario} has ganado <p>{premio.detalle} !</p></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="image-container text-center">
                        <img src="./images/assets/premio.png" alt="Premio" className="img-fluid" />
                        <div className="overlay-text">{premio.nombre}</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={closeModal}>
                        Cerrar
                    </button>
                </Modal.Footer>
            </Modal>
        

        </Layout>
    );

};

export default Ruleta2;
