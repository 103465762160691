import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import Swal from 'sweetalert2';

//REDUX
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import './BasicMenu.scss'
import { Badge } from 'react-bootstrap';
import { removerTodo } from '../../../actions/authActions';

const BasicMenu = () => {

  const { usuario = {} } = useSelector(state => state.usuario);
  const dispacth = useDispatch();
  const history = useHistory();

    const cerrarSesion = () => {
      Swal.fire({
        title: "¿Esta seguro de cerrar sesión?",
        text: "Tendra que logearse para entrar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#1C56FF",
        cancelButtonColor: "#29d698",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, cerrar sesión"
      }).then(result => {
        if (result.value) {
          dispacth(removerTodo());
          history.push("/login");
      }
      });
    }

  return ( 
    <div className="basic-menu">
      <div className="card img mt-4">
          <div className="d-none d-lg-block text-center">
            {usuario.img_usuario !== undefined ? (
              <img
                src={
                  usuario.img_usuario !== null
                    ? `${process.env.REACT_APP_API_URL}/${usuario.img_usuario}`
                    : "/images/user.png"
                }
                className="kimg_perfil imgPerfil border-white card-img-top my-3"
                alt=""
              />
            ) : (
              <div className="w-100 my-3 justify-content-center d-flex skeleton">
                <Skeleton
                  variant="circle"
                  margin={"auto"}
                  width={120}
                  height={120}
                />
              </div>
            )}
          </div>
        </div>
        <div className="card mt-4 ml-md-0" id="perfi">
          <div className="card-body py-1 py-lg-2 px-3 bg-light">
            <h5
              className="card-title text-left mb-0"
              style={{ fontSize: "15px" }}
            >
              Menú
            </h5>
          </div>

          <ul className="list-group list-group-flush">
            <li className="list-group-item d-flex align-items-center">
              <img
                src="/images/capacitaciones.png"
                style={{ width: "22px", height: "22px" }}
                alt=""
              />{" "}
              <Link
                
                to="/capacitaciones"
                className="px-2 py-0 enlaceListGroup"
              >
                <span className="text-left"> Mis capacitaciones</span>
              </Link>
            </li>
            <li className="list-group-item d-flex align-items-center">
              <img
                src="/images/carpeta.png"
                alt=""
                style={{ width: "22px", height: "22px" }}
              />
              <Link  to="/perfil" className="px-2 py-0 enlaceListGroup">
                Mis datos
              </Link>
            </li>
           {/* <li className="list-group-item d-flex align-items-center">
              <img src="/images/institucion.png" alt="" />
              <Link
                
                to="/institucion"
                className="px-2 py-0 enlaceListGroup"
              >
                Información institucional
              </Link>
            </li>*/}
            <li className="list-group-item d-flex align-items-center">
              <img src="/images/question.png" alt="" />
              <Link
                
                to="/reglas-normas"
                className="px-2 py-0 enlaceListGroup"
              >
                Reglas y normas
              </Link>
            </li>
            <li className="list-group-item d-flex align-items-center">
              <img src="/images/blogger.svg" alt="" />
              <a
                href="https://inedi.edu.pe/blog"
                target='_blank'
                className="px-2 py-0 enlaceListGroup"
              >
                Blog <Badge variant='success'>Nuevo</Badge>
              </a>
            </li>
            <li className="list-group-item d-flex align-items-center">
              <img src="/images/contrasena.png" alt="" />
              <Link  to="/cuenta" className="px-2 py-0 enlaceListGroup">
                Cambiar contraseña
              </Link>
            </li>
            <li className="list-group-item  d-flex align-items-center">
              <img
                src="/images/cerrar-sesion.png"
                alt=""
                className="mr-2"
                style={{ width: "22px", height: "22px" }}
              />
              <button
                onClick={cerrarSesion}
                className="px-0 bg-white text-primary btn-perfil"
              >
                Cerrar sesión
              </button>
            </li>
          </ul>
        </div>
    </div>
   );
}

export default BasicMenu;