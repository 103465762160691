import React from 'react';
import { Table } from 'react-bootstrap';
import FilaTablaPagosExtra from './FilaTablaPagosExtra';

const TablaPagosExtras = ({ pagosExtraordinarios = [] }) => {

  const mostrarPagosExtras = () => {
    if(!pagosExtraordinarios.length) {
      return <tr>
        <td align='center' colSpan='6'>
          <p>No hay datos para mostrar</p>
        </td>
      </tr>
    } else {
      return pagosExtraordinarios.map((pagos, index) => (
        <FilaTablaPagosExtra
          key={pagos.id_detalles_pagos}
          index={index}
          {...pagos}
        />
      ))
    }
  }

  return (
    <div className='tabla-cuenta'>
      <Table>
        <thead className="thead-cuenta text-white thead-warning">
          <tr>
            <th>#</th>
            <th>Concepto</th>
            <th>Fecha límite</th>
            <th>Total a pagar (S/)</th>
            <th>Estado</th>
            <th>Descargar</th>
          </tr>
        </thead>
        <tbody>
          {mostrarPagosExtras()}
        </tbody>
      </Table>
    </div> 
  );
}
 
export default TablaPagosExtras;