import React from 'react';
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-classic-plus";



const Editor = ({ setMensaje, mensaje=""}) => {
  return (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={mensaje}
        config={{
          ckfinder: {
            uploadUrl:
              "/ckfinder/core/connector/php/connector.php?command=QuickUpload&type=Files&responseType=json"
          }
        }}
        onInit={editor => {
          // You can store the "editor" and use when it is needed.
         // console.log("Editor is ready to use!", editor);
        //  console.log(editor.data)
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          // console.log({ event, editor, data });
          if (setMensaje !== undefined) {
            setMensaje(data)
          }
        }}
        onBlur={(event, editor) => {
          // console.log("Blur.", editor);
        }}
        onFocus={(event, editor) => {
          // console.log("Focus.", editor);
        }}
      />
    </div>
  );
}
 
export default Editor;