import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Layout from "../../components/Layout/Layout";
import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSadTear } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import * as moment from "moment";
import { Card, Alert } from "react-bootstrap";
import FloatingWhatsAppButton from "../../components/WhatsappButton/FloatingWhatsAppButton"
import { obtenerIntentos } from "../../actions/cursosActions";

const VistaPreviaExamen = () => {
	const { loading } = useSelector(state => state.cursos);
	const { examen, preguntas } = useSelector((state) => state.cursos);
	const { sesion } = useSelector((state) => state.sesiones);
	const { usuario } = useSelector(state => state.usuario)
	const { intentosRealizados } = useSelector(state => state.cursos)

	const { idCurso } = useParams();
	const dispatch = useDispatch();
	const puntaje = (preguntas_) => {
		let puntaje_total = 0;
		preguntas_.map((pregunta) => {
			puntaje_total += parseInt(pregunta.puntaje);
		});
		return puntaje_total;
	};

	useEffect(() => {
		dispatch(obtenerIntentos(examen.id_examen, usuario.id_estudiante));
	}, [examen, usuario]);


	return (
		<Layout>
			<div className="container content mt-5">
				{examen ? (
					examen.nombre_examen && examen.nombre_examen !== "" ? (
						<>
							{" "}
							<div style={{ maxWidth: "900px", margin: "auto" }}>
								{
									//<div style={{height:'150px', background: "url('https://intranet.inedi.edu.pe/images/cabecera_examen.png') center center no-repeat", backgroundSize:"cover", marginBottom:"10px", borderRadius:"8px"}}></div>
								}

								<Card
									style={{
										borderTop: "#27314A 5px solid",
										marginBottom: "15px",
									}}
								>
									<Card.Body>
										<h1>{examen.nombre_examen}</h1>
										<p style={{ fontSize: "18px" }}>
											{examen
												? examen.descripcion_examen
												: ""}
										</p>
										<div className="content-body-proyecto">
											<div className="resumen">
												<div className="row p-3">
													<div className="col-md-4">
														<div className="content-info">
															<div className="info">
																<p className="font-weight-bold text-label">
																	Fecha de
																	entrega
																</p>
																<p className="ml-2 text-lowercase">
																	{sesion
																		? moment(
																			sesion.fecha_sesion
																		).format(
																			"DD MMM YYYY"
																		)
																		: ""}
																</p>
															</div>
															<div className="info">
																<p className="font-weight-bold text-label">
																	Límite de
																	Tiempo
																</p>
																<p className="ml-2 text-lowercase">
																	{examen
																		? examen.duracion
																		: ""}{" "}
																	minutos.
																</p>
															</div>
														</div>
													</div>
													<div className="col-md-4">
														<div className="content-info">
															<div className="info">
																<p className="font-weight-bold text-label">
																	Puntos
																</p>
																<p className="ml-2">
																	0
																	{/*puntaje(preguntas)*/}
																</p>
															</div>
															<div className="info mt-2">
																<p className="font-weight-bold text-label">
																	Preguntas
																</p>
																<p className="ml-2">
																	{
																		preguntas.length
																	}
																</p>
															</div>
														</div>
													</div>
													<div className="col-md-4">
														<div className="content-info">
															<div className="info mt-2">
																<p className="font-weight-bold text-label">
																	Intentos
																</p>
																{(examen.max_intentos && examen.max_intentos!=0) ?
																	<p className="ml-2">
																		{examen.max_intentos}
																	</p>
																	: <p className="ml-2">
																		Ilimitados
																	</p>
																}
															</div>
															{(examen.max_intentos && examen.max_intentos!=0) &&
																<div className="info mt-2">
																	<p className="font-weight-bold text-label">
																		Intentos Restantes
																	</p>
																	<p className="ml-2">
																		{
																			examen.max_intentos - intentosRealizados
																		}
																	</p>
																</div>
															}
														</div>
													</div>
												</div>
											</div>
										</div>
									</Card.Body>
								</Card>
								<Card
									style={{
										borderTop: "#27314A 5px solid",
										marginBottom: "15px",
									}}
								>
									<Card.Body>
										{sesion.id_sesion !== "384" ? (
											<div>
												<h2>Instrucciones:</h2>
												<p>
													Ahora que comprendes{" "}
													<strong>
														{" "}
														{sesion.nombre_sesion}
													</strong>
													, contesta este breve Quiz
													para reforzar los conceptos
													y aprendizaje clave.
												</p>
												<br></br>
												<p>
													<strong>
														Por favor, toma nota:{" "}
													</strong>
													Este quiz está diseñado para
													ayudarte a medir tu propio
													nivel de conocimientos y
													comprensión. Si te equivocas
													en más de una pregunta te
													recomendamos revisar una vez
													más y después tratar de
													responder nuevamente el
													quiz. Sin embargo, si
													obtienes una calificación
													superior a 14 en el primer
													intento ganarás 10 puntos en
													el Gestora Rank.
												</p>
												<br></br>
												<p>
													¡Éxitos!
												</p>
											</div>
										) : (
											<div>
												<p>
													<strong>
														{" "}
														Simulacro de Evaluación
														de Certificación por
														niveles - OSCE
													</strong>
												</p>
												<p>
													Con el fin de medir tu nivel
													de conocimiento antes de
													rendir el examen de
													certificación por niveles –
													OSCE, hemos seleccionado
													este balotario de preguntas.
												</p>
												<br></br>
												<p>
													<strong>Ventajas:</strong>
												</p>
												<p>
													<ul
														style={{
															marginLeft: "15px",
														}}
													>
														<li>
															Reconocer el nivel
															de conocimiento al
															rendir el Simulacro
															de Evaluación.
														</li>

														<li>
															Identificar nuestras
															potenciales fallas
															ante un posible
															Examen de
															Certificación.
														</li>

														<li>
															Identificar las
															posibles preguntas
															en un Examen de
															Certificación.
														</li>

														<li>
															{" "}
															Mejorar
															considerablemente
															los puntos claves en
															las Contrataciones
															para rendir con
															éxito el Examen de
															Certificación.
														</li>
													</ul>
												</p>
											</div>
										)}
										{loading ? (
											<>
												<div className="text-center mt-5">
													{/* Muestra el spinner mientras se carga */}
													<Spinner animation="border" role="status">
													</Spinner>
												</div>
											</>
										) : ((intentosRealizados < examen.max_intentos || !examen.max_intentos || examen.max_intentos==0) ? (

											<div className="text-center mt-5">
												<Link
													className="btn btn-primary"
													to={"actividad/resolver"}
												>
													{sesion.id_sesion !== "384"
														? "Resolver Quiz"
														: "Iniciar Simulacro"}
												</Link>
											</div>
										) : (
											<>
												<div className="text-center mt-5">
													<div className="d-flex flex-column align-items-center">
														{/* Icono con animación sutil */}
														<FontAwesomeIcon
															icon={faSadTear}
															style={{ fontSize: '4rem', color: '#f5ee07', animation: 'shake 1s ease-in-out infinite' }}
														/>

														{/* Mensaje amigable con estilo mejorado */}
														<p className="mt-3" style={{ fontSize: '1.3rem', color: '#ff6b6b', fontWeight: 'bold' }}>
															¡Oh no! Ya has realizado todos los intentos permitidos para este quiz.
														</p>

														{/* Botón para redirigir o intentar otro quiz */}
														<Link
															className="btn btn-outline-primary mt-3 px-4 py-2"
															style={{ fontSize: '1.0rem', borderRadius: '20px', transition: 'all 0.3s ease-in-out' }}
															onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#1c56ff'}
															onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
															to={"/capacitaciones/" + idCurso + "/sesiones"}
														>
															Intentar otro quiz
														</Link>
													</div>

													{/* Estilos adicionales para animación */}
													<style>{`
														@keyframes shake {
														0%, 100% { transform: translateX(0); }
														25% { transform: translateX(-5px); }
														50% { transform: translateX(5px); }
														75% { transform: translateX(-5px); }
														}
													`}</style>
												</div>

											</>
										))
										}
									</Card.Body>
								</Card>
							</div>
						</>
					) : (
						<Alert variant="info">
							No hay Actividad para esta sesión.{" "}
							<Link
								className="btn btn-primary"
								to={"/capacitaciones/" + idCurso + "/sesiones"}
								style={{ marginBottom: "10px" }}
							>
								{" "}
								Volver a Sesiones{" "}
							</Link>
						</Alert>
					)
				) : (
					<div
						className="w-100 h-100 d-flex align-items-center"
						style={{
							minHeight: "500px",
							background: "rgba(0,0,0,.01)",
						}}
					>
						<div className="sk-folding-cube ">
							<div className="sk-cube1 sk-cube "></div>
							<div className="sk-cube2 sk-cube "></div>
							<div className="sk-cube4 sk-cube "></div>
							<div className="sk-cube3 sk-cube "></div>
						</div>
					</div>
				)}
			</div>
			<FloatingWhatsAppButton />
		</Layout>
	);
};

export default VistaPreviaExamen;
