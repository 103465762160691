import React from "react";
import * as moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckSquare, faEye } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const FilaTablaPensiones = ({
  pago = {},
  index,
  idCapacitacion,
  handleClickButton,
}) => { 

  return (
    <tr
      className={`${
        idCapacitacion === "8" &&
        (pago.lapso === "MATRÍCULA" || pago.lapso === "Pago unico")
          ? "d-none"
          : ""
      }`}
    >
      <td align="center">
        <p>{index + 1}</p>
      </td>
      <td>
        <p>{pago.lapso !== undefined && pago.lapso.toUpperCase()}</p>
      </td>
      <td align="center">
        <p>{pago.monto_establecido}</p>
      </td>
      <td align="center">
        <p>{pago.importe_penalidad}</p>
      </td>
      <td align="center">
        <p>{pago.importe_descuento}</p>
      </td>
      <td align="center">
        <p className="font-weight-bold">{pago.monto_pagado}</p>
      </td>
      <td align="center">
        <p className="font-weight-bold">{pago.monto_saldo}</p>
      </td>
      <td align="center">
        <p className="text-uppercase">
          {pago.fecha_limite
            ? moment(pago.fecha_limite).format("DD MMM YYYY")
            : "---"}
        </p>
      </td>
      <td>
        <p>
          {pago.nombre_tp_estado_pago !== undefined &&
            pago.nombre_tp_estado_pago.toUpperCase()}
        </p>
      </td>
      <td align="center" className="text-info text-recibo">
        <Link to={`/reportar-pago/${pago.id_dt_matricula_proyecto}`}>
          <FontAwesomeIcon
                icon={faCheckSquare}
                className={`cursor-pointer ${!pago.id_factura && "error"}`}
              />
        </Link>
        <Button
          variant="link"
          // href={`${process.env.REACT_APP_API_URL}/admin/pdf/factura/${pago.id_factura}`}
          // disabled={pago.id_factura ? false : true}
          size="sm"
          onClick={() => handleClickButton(pago.recibos)}
        >
          <FontAwesomeIcon
            icon={faEye}
            className={`cursor-pointer ${!pago.id_factura && "error"}`}
          />
        </Button>
      </td>
    </tr>
  );
};

export default FilaTablaPensiones;
