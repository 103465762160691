import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import { getJWT} from '../components/Helpers/FunctionsHelpers'

const isAuth = () => {
    if (getJWT() !== null) {
        return true
    }
    return false;
}

const PrivateRoute = ({ component: Component, ...rest}) => {

    return (
        <Route
            {...rest}
            render={
                props => isAuth() ? (
                    <Component {...props} />
                ) : (
                    <Redirect
                        to='/login'
                    />
                )
            }
        />
    )
}


export default PrivateRoute;