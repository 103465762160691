import React, { useState, useEffect} from 'react';
import Editor from '../Editor';
import { enviarMensaje, removerEstadoMensaje,listarProfesores} from '../../../actions/MensajesActions';
import { getElement, handleFileSelect } from '../../Helpers/FunctionsHelpers'
import MuliSelect from '../../plugins/MultiSelect'
import Swal from "sweetalert2";
//REDUX
import {connect} from 'react-redux';
import SpinKit from '../../Spinkit';
import ModalContactos from './ModalContactos';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook, faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

const style = {
  boton: {
    background: "#28314A",
    color: "#fff"
  },
  buttonText: {
    fontSize: "13px",
    background: "#28314A",
    color: '#fff'
  },
  textArchivo: {
    fontSize: "12px",
    fontWeight: "600",
    color: "#aaa"
  }
}

const NuevoMensaje = (props) => {

  //State
  const {usuario, contactos, setPanel, hilo} = props
  //Funciones
  const { enviarMensaje, mensajeEstado, listarProfesores, removerEstadoMensaje} = props

  const [submit, setSubmit] = useState(false)
  const [destino, setDestino] = useState()
  const [asunto, setAsunto] = useState()
  const [mensaje, setMensaje] = useState(hilo?hilo:'')
  const [subir, setSubir] = useState(false)

  //Estate de usuarios
  const [dataEstudiantes, setDataEstudiantes] = useState([])
  //SE ALMACENARA LO QUE ELEJIRE DEL SELECT
  const [defaultSelect, setDefaultSelect] = useState([])

  const [nombreArchivo, setNombrearchivo] = useState('')
  const [tamanoArchivo, setTamanoArchivo] = useState('')
  
  const enviarMensajeNuevo = (e) => {
    e.preventDefault()
    setSubmit(true)
    const form = document.getElementById('formNuevo')
    var dato = new FormData(form)
    dato.append('id_emisor', usuario.id_usuario)
    dato.append('mensaje', mensaje)
    dato.append('asunto', asunto)
    dato.append('receptores', JSON.stringify(defaultSelect))
    enviarMensaje(dato)
  }

  const abrirInputFile = e => {
    e.preventDefault()
    const input = getElement('fileArchivo')
    input.click()
  }

  const mostrarArchivo = (e) => {
    setSubir(true)
    handleFileSelect(e)
    e.preventDefault()
    const divMostrar = getElement('subidaArchivo')
    
    setNombrearchivo(e.target.files[0].name)
    setTamanoArchivo(`${e.target.files[0].size / 1000} KB`)
  }

  const MensajeExito = () => {
    if (mensajeEstado === 200) {
      Swal.fire(
        'Mensaje Enviado',
        '',
        'success'
      )
      removerEstadoMensaje()
      setPanel('enviados')
      if (submit) {
        setSubmit(false)
      }
    }
    
  }
  const mostrarMensaje = () => {
    return <div className="alert alert-primary mt-3 d-flex" role="alert">
      Enviando <SpinKit bg='primary' />
    </div>
  }

//Funcion para guardar los item seleccionados
  const guardarSeleccion = item => {
    if (item === null) {
      setDefaultSelect([])
      return
    }
    setDefaultSelect(item)
  }
  const guardarSeleccionTabla = item => {
    setDefaultSelect([...defaultSelect, item])
  }


  // const dataContactosAlumnos = [
  //   { label: "Eduardo", value: "1", id_receptor: 1 },
  //   { label: "Jose", value: "2", id_receptor: 2 },
  //   { label: "Marcos", value: "3", id_receptor: 3}
  // ];

  const llenarContactosAlumnos = contactos => {
    let dataContactos = []

    if (contactos && Object.keys(contactos).length > 0) {
      (contactos.estudiantes).map(estudiante => {
        dataContactos = [...dataContactos, {
          label: `${estudiante.nombre_usuario} ${estudiante.apellido_usuario}`,
          value: estudiante.id_usuario,
          id_receptor: estudiante.id_usuario
        }]
      })
    }

    setDataEstudiantes([
      {
        label: 'Dpto. Experiencia del Cliente',
        options: [
          {
            label: 'Dpto. Experiencia del Cliente',
            value: 5,
            id_receptor: 5
          }
        ]
      },
      {
        label: 'Discentes',
        options: dataContactos
      }
    ])
  }

  useEffect(() => {
    llenarContactosAlumnos(contactos)
  },[contactos])



    return (
      <div className="contenetdor-nuevo my-3 my-md-0 bg-white">
        <div className="cabecera px-md-5">
          <div className="tile-nuevo-mensaje d-flex align-items-center border px-3 pt-2 pb-4">
            <i className="far fa-edit mr-1 ml-3"></i>
            <h5 className="mb-0">Redactar mensaje</h5>
          </div>
        </div>
        <form
          id="formNuevo"
          onSubmit={e => enviarMensajeNuevo(e)}
          className="px-md-5 py-3"
        >
          <div className="form-row pl-md-5 align-items-center mb-1">
            <div className="col-md-2">
              <label
                htmlFor="destino"
                className="mb-md-0 mb-1 float-md-right  text-bold text-dark"
              >
                Para:
              </label>
            </div>
            <div className="col-md-10" style={{ zIndex: "9" }}>
              <MuliSelect
                dataContactosAlumnos={dataEstudiantes}
                defaultSelect={defaultSelect}
                guardarSeleccion={guardarSeleccion}
              />
            </div>
            
            <div className="col-md-1 d-none clearfix">
              <button
                type="button"
                data-toggle="modal"
                data-target=".bd-example-modal-lg.contac"
                className="btn btn-primary text-white float-right"
              >
                <FontAwesomeIcon icon={faAddressBook} />
              </button>
            </div>
          </div>
          <div className="form-row pl-md-5 align-items-center mb-3">
            <div className="col-md-2">
              <label
                htmlFor="asunto"
                className="mb-md-0 mb-1 float-md-right text-bold text-dark"
              >
                Asunto:
              </label>
            </div>
            <div className="col-md-10">
              <input
                onChange={e => setAsunto(e.target.value)}
                type="text"
                className="form-control"
                id="asunto"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-12">
              <button
                onClick={e => abrirInputFile(e)}
                className="btn btn-primary px-1 text-white float-right"
              >
                <FontAwesomeIcon icon={faPaperclip} /> Adjuntar Archivo
              </button>
            </div>
            <div className=" container-fluid w-100 my-2" id="subidaArchivo">
              <div className={`bg-white border row ${subir ? "" : "d-none"}`}>
                <div
                  className={`col-md-7 align-items-center d-flex ${
                    subir ? "d-block" : "d-none"
                  }`}
                >
                  <p className="mb-0" style={style.textArchivo}>
                  <FontAwesomeIcon icon={faPaperclip} />
                    {nombreArchivo} ({tamanoArchivo})
                  </p>
                </div>

                <div
                  className={`col-md-5 px-0 ${subir ? "d-block" : "d-none"}`}
                >
                  <div id="progress_bar" className="loading ">
                    <div
                      className="progress-bar rounded percent progress-bar-striped "
                      role="progressbar"
                      aria-valuenow="75"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      0%
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <input
              type="file"
              onChange={e => mostrarArchivo(e)}
              multiple
              name="file_mensaje"
              id="fileArchivo"
              className="form-control d-none"
            />
          </div>
          <div className="form-group">
            <Editor setMensaje={setMensaje} mensaje={mensaje}/>
          </div>
          {submit ? mostrarMensaje() : null}
          <button type="submit" className="btn btn-primary">
            <FontAwesomeIcon icon={faPaperPlane} /> Enviar
          </button>

          {MensajeExito()}
        </form>

        {/*<ModalContactos
          defaultSelect={defaultSelect}
          guardarSeleccionTabla={guardarSeleccionTabla}
          guardarSeleccion={guardarSeleccion}
        />*/}
      </div>
    );
}


const mapStateToProps = state => ({
  mensajeEstado: state.mensajes.mensajeEstado,
  usuario: state.usuario.usuario,
  contactos: state.mensajes.contactos
})
 
export default connect(mapStateToProps, { enviarMensaje,listarProfesores, removerEstadoMensaje})(NuevoMensaje);