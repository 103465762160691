import { faChevronCircleRight, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const LinkVivo = (props) => {
  const { vivo } = props;

  return (
    <div className="container-recursos videos d-flex justify-content-between border-top">
      <div className="nombre-archivo p-3">
        <a
          href={`${vivo.ruta_archivo}`}
          target="_black"
          className="mb-0 p-nombre-video"
        >
          <p className="text-info">
            <span>
              <FontAwesomeIcon
                icon={faChevronCircleRight}
                className='mr-2'
              />
            </span>
            {vivo.nombre_archivo}
          </p>
        </a>
      </div>
      <div className="icon-archivo p-3" style={{ fontSize: "20px" }}>
        <a href={`${vivo.ruta_archivo}`} target="_black">
          <FontAwesomeIcon
            icon={faPaperclip}
            className="d-block bg-danger text-white p-1 rounded"
            style={{ width: "22px" }}
          />
        </a>
      </div>
    </div>
  );
};

export default LinkVivo;
