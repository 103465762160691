import React from 'react';
import './Footer.scss';

const Footer = () => {
  return ( 
    <footer className='Footer'>
      <div className="container text-center">
        <p> © {new Date().getFullYear()} Copyright: <a href="https://inedi.edu.pe" target='_blank' rel="noopener noreferrer">INEDI &reg;</a></p>
      </div>
    </footer>
   );
}
 
export default Footer;