import React, { useEffect, useState } from "react";
import FloatingWhatsAppButton from "../../components/WhatsappButton/FloatingWhatsAppButton";
//Redux
import { connect } from "react-redux";
import { listarEmpleos } from "../../actions/cursosActions";
import Layout from "../../components/Layout/Layout";


import ListaEmpleos from "./ListaEmpleos";
import DetalleEmpleo from "../../components/DetalleEmpleo/ModalDetalleEmpleo";
//import { showEncuesta } from "../../actions/encuestaActions";
const BolsaDeTrabajo = (props) => {


  //const show = useSelector(state => state.encuesta.show);
  //state
  const [datosBolsa,setDatosBolsa] = useState()
  const { detalleNivel} = props;
  //Funciones
  const { listarEmpleos } = props;

  useEffect(()=>{
    listarEmpleos(detalleNivel[0].id_u_estudiante).then((resp)=>{
        setDatosBolsa(resp)
    })
  },[])

  return (
  
    <Layout>
      <DetalleEmpleo />
      <div className="container mt-5">

        <div className="row">
            <h2>Bolsa de trabajo</h2>
            <div className="container">
                <ListaEmpleos empleos={datosBolsa?.empleos} />
            </div>

        </div>

      </div>
      <FloatingWhatsAppButton />
    </Layout>

  );
};

const mapStateToProps = (state) => ({
  detalleNivel: state.usuario.detalleNivel,
  currentTab:state.usuario.currentTab,
  id_empleo:state.detalleEmpleo.id_empleo
});


export default connect(mapStateToProps,{listarEmpleos})(BolsaDeTrabajo);