import { alertaTypes } from './types';
import authAxios from '../config/authAxios';

export const showAlerta = () => {
  return dispatch => {
    dispatch({
      type: alertaTypes.SHOW_ALERTA
    })
  }
}
export const showMenu = () => {
  return dispatch => {
    dispatch({
      type: alertaTypes.SHOW_MENU
    })
  }
}
export const showDetalle = () => {
  return dispatch => {
    dispatch({
      type: alertaTypes.SHOW_DETALLE_EMPLEO
    })
  }
}

export const showEstado = () => {
  return dispatch => {
    dispatch({
      type: alertaTypes.SHOW_OBTENER_ESTADO
    })
  }
}



//LLAMADAS A LA API
export const obtenerPopUp = () => async dispatch => {

  try{
    let respuesta = await authAxios.get(`/admin/obtenerPopUp`)
    const showEstado = respuesta.data.data[0].HABILITADO;  
    dispatch({
      type: alertaTypes.SHOW_OBTENER_ESTADO,
      payload: showEstado  // Pasas el valor como payload
    });
    if(showEstado==1){
      dispatch(showAlerta());
    }
    return respuesta.data
  }catch(error){
    console.error("Error al obtener el estado del popup:", error);
  }
}