import { alertaTypes } from "../actions/types";

const initialState = {
  show: false,
  showEstado: 0
}

export default function (state = initialState, action) {
  switch (action.type) {
    case alertaTypes.SHOW_ALERTA:
      return {
        ...state,
        show: !state.show
      };
    case alertaTypes.SHOW_OBTENER_ESTADO:
      return {
        ...state,
        showEstado: action.payload 
      };

    default:
      return state;
  }
}