import {
    MOSTRAR_USUARIO,
    ELIMINAR_USUARIO,
    VERIFICAR_USUARIO,
    ACTUALIZAR_USUARIO_P,
    REMOVER_ESTADO_USUARIO,
    EDITAR_USUARIO,
    ACTUALIZAR_ESTATE_USUARIO,
    VERIFICAR_USUARIO_EXITO,
    VERIFICAR_USUARIO_ERROR,
    RESTABLECER_ERROR,
    GUARDAR_PUNTAJE,
    OBTENERN_NOTIFICACIONES
} from './types';
import { getJWT, setJwt} from '../components/Helpers/FunctionsHelpers';

import { showToast } from './toastActions';
import { obtenerPopUp, showAlerta } from './alertaActions';
import authAxios from '../config/authAxios';
import authToken from '../config/authToken';
import { showEncuesta } from './encuestaActions';
import Swal from 'sweetalert2';

export const mostrarUsuario = (userDeecode = {}) => async dispatch => {
    dispatch({
        type: MOSTRAR_USUARIO,
        payload: {
            respuesta: {
                usuario: userDeecode.usuario,
                detalleNivel: userDeecode.detalleNivel
            },
            estado: 4
        }
    })
    dispatch(showToast());
    //dispatch(showAlerta());
    dispatch(obtenerPopUp());

    dispatch(showEncuesta());
}

export const validacionUsuarioToken = () => {
    return async dispatch => {
        try {
            authToken(getJWT().replace(/['"]+/g, ""));
            const decode = await authAxios.get(`/decode`);
            if (decode.data) {
                const datosCo = {
                  usuario: decode.data.datos[0],
                  detalleNivel: decode.data.detalleNivel,
                };
                dispatch(mostrarUsuario(datosCo));
              }
        } catch (error) {
            console.log(error);
        }
    }
}

export const verificarUsuario = datos => async dispatch => {
    
    dispatch({
        type: VERIFICAR_USUARIO
    })
    dispatch(showEncuesta());
    try {
        const respuesta = await authAxios.post(`/loginUsuarioAuth`, datos);
        if (!respuesta.data.error) {
            setJwt(respuesta.data.encode)
            authToken(respuesta.data.encode.replace(/['"]+/g, ""));
            let usuarioTodo = {
                usuario: respuesta.data.decode.datos[0],
                detalleNivel: respuesta.data.decode.detalleNivel
            }

            dispatch({
                payload: usuarioTodo,
                type: VERIFICAR_USUARIO_EXITO
            })
           
            dispatch(showToast());

           // dispatch(showAlerta());
           dispatch(obtenerPopUp());

           
            return;
        }
        dispatch({
            type: VERIFICAR_USUARIO_ERROR,
            payload: respuesta.data.error
        })
        setTimeout(() => {
            dispatch({
                type: RESTABLECER_ERROR
            })
        }, 3000)
    } catch (error) {
        console.log(error)
    }
} 


export const eliminarDatosUsuario = () => async dispatch => {
    dispatch({
        type: ELIMINAR_USUARIO,
        payload: []
    })
}

export const removerEstadoUsuario = () => async dispatch => {
    dispatch({
        type: REMOVER_ESTADO_USUARIO,
        payload: []
    })
}

export const editarusuario = (idUsuario,dataUsuario) => async dispatch => {
    try {
        let usuarioNuevo = await authAxios.put(`/admin/editarEstudiante/${idUsuario}`, dataUsuario)
        if (usuarioNuevo.data.mensaje === "EL DNI INGRESADO YA EXITEN EN LA BASE DE DATOS") {
            return {mensaje: false, textMensaje: 'EL DNI INGRESADO YA EXITEN EN LA BASE DE DATOS'}  
        }
        setJwt(usuarioNuevo.data.encode)
        dispatch({
            payload: usuarioNuevo.data.decode.datos[0],
            type: EDITAR_USUARIO
        })
        return {mensaje: true, textMensaje: 'Actualizadio correctamente'}
    } catch (error) {
        return {mensaje: false, textMensaje:'Ocurrio un error Inesperado'}
    }
    
}

export const editarPassword = (idUsuario, dataPass) => async dispatch => {
    let respuesta = await authAxios.put(`/admin/editarPassword/${idUsuario}`, dataPass)
    

    if (respuesta.data.error) {
        return false
    }
    setJwt(respuesta.data.encode)

    dispatch({
        payload: respuesta.data.decode.datos[0],
        type: ACTUALIZAR_USUARIO_P
    })

    return true

}

export const consultarPuntos = (idUsuario) => async dispatch => {
    let respuesta = await authAxios.get(`/admin/puntaje/${idUsuario}`)
    
    if (respuesta.data.error) {
        return false
    }

    dispatch({
        payload: respuesta.data.puntaje,
        type: GUARDAR_PUNTAJE
    })

    return true

}

export const consultarPension = (id_pension,id_usuario) => async dispatch => {
    let respuesta = await authAxios.get(`/admin/matricula/pago/${id_pension}/${id_usuario}`)
    
    if (respuesta.data.error) {
        return false
    }
    return respuesta.data

}


export const registrarPensionFraccion = (datos, idMatricula) => {
    return async (dispatch, getState) => {

      delete datos.fecha_pago;
      delete datos.id_tp_medio_serie;
    
      const idModalidad = datos.modalidad === 'fraccion' ? '2' : '1';
      datos.id_tp_cuota = idModalidad;
      datos.monto_pagado = '0';
      datos.discente = datos.dni_ruc
      delete datos.dni_ruc;
      const datos_file = new FormData();
      Object.keys(datos).forEach(key => datos_file.append(key, datos[key]));
      try {

        const respuesta = await authAxios.post('/admin/matricula/pagos/registro', datos_file);
      
        if(!respuesta.data.error)
        {
            return true
        }
        else
        {
            return false
        }
    
      } catch (error) {
       // console.log(error);
        return false
      }
    }
  }
  
  export const obtenerIDBoletaSiguiente = (serie) => {
    return async dispatch => {

      try {
        if(!serie) {
          return;
        }
        const respuesta = await authAxios.get(`/admin/matricula/numero_documento/${serie}`);
        return respuesta.data
    
      } catch (error) {
        console.log('Hubo un error al traer el id del pago')
      }
    }
  }

export const consultarNotificaciones = (idUsuario) => async dispatch => {
    let respuesta = await authAxios.get(`/admin/notificaciones/${idUsuario}`)
    
    if (respuesta.data.error) {
        return false
    }

    dispatch({
        payload: respuesta.data.notificaciones,
        type: OBTENERN_NOTIFICACIONES
    })

    return true

}

export const actualizarUsuario = dataUsuario => dispatch => {
    dispatch({
        payload: dataUsuario,
        type: ACTUALIZAR_ESTATE_USUARIO
    })
}