import { faExternalLinkAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import ListaMensajes from './ListaMensajes';
import Vista from './Vista'

const style = {
    icono: {
        fontSize: "13px"
    }
}

const edtiloBoton = (title) => {

  return (title === "Papelera") ? "btn-danger" : "btn-primary"; 
}

const Entrada = (props) => {

  //State
  const {title} = props
  //Funciones
  const {eliminarMensajeCompleto, guardarMensajesSeleccionados, moverMensajePapelera, restaurarMensajesPapelera, setPanel} = props

    return (
      <div className="container-fluid px-0 mt-2 mt-md-0">
        <div className="row">
          <div className="col-md-5 p-0 bg-white">
            <div className="content-recibidos shadow-sm border">
              <h3 className="text-dark p-2 mb-0" style={{fontSize: "25px"}}>{title}</h3>
              <div className="content-icon-action p-2 border-bottom shadow-sm">
                <button
                  className={`btn btn-sm ${edtiloBoton(title)} text-white mr-1`}
                  title='Eliminar'
                  onClick={() => {
                    (title === 'Papelera') ? eliminarMensajeCompleto():  moverMensajePapelera()
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} style={style.icono} />
                </button>
                {title === 'Papelera' ? 
                
                  <button className="btn btn-sm btn-primary text-white" title='Restaurar' onClick={() => restaurarMensajesPapelera()}>
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </button>
                
                : null}
              </div>
              <ListaMensajes
                title={title}
                guardarMensajesSeleccionados={guardarMensajesSeleccionados}
              />
            </div>
          </div>
          <div className="col-md-7 mt-2 px-0 px-md-3 mt-md-0">
            <Vista setPanel={setPanel} />
          </div>
        </div>
      </div>
    );
}
 
export default Entrada;