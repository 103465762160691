import React from "react";
import * as moment from "moment";
import {
	Document,
	Page,
	View,
	Text,
	Image,
	StyleSheet,
	Font,
} from "@react-pdf/renderer";

const Contancia = ({ constancia }) => {
	const fechaActual = new Date();
	const handleRegister = () => {
		Font.register({
			family: "Blauth Light",
			src: "/fonts/Latinotype_Blauth.otf",
			fontStyle: "normal",
			fontWeight: "300",
		});

		Font.register({
			family: "Oswald",
			src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
		});

		Font.register({
			family: "segoe-light",
			src: "/fonts/segoe-ui-light-2.ttf",
		});

		Font.register({
			family: "segoe-bold",
			src: "/fonts/FontsFree-Net-Segoe-UI-Bold.ttf",
		});
	};

	handleRegister();

	if (!constancia) return <Document></Document>;

	return (
		<Document>
			<Page size="A4" style={styles.page}>
				<Image
					src={`${process.env.REACT_APP_API_URL}/${constancia.ruta_arhivo}`}
				/>
				<View style={styles.fechaSuperior}>
					<Text style={styles.fecha}>
						{" "}
						Fecha de impresión:{" "}
						{moment(fechaActual).format("DD/MM/YYYY")}
					</Text>
				</View>
				<View style={styles.contenedor}>
					<Text
						style={styles.nombre}
					>{`${constancia.nombre_usuario} ${constancia.apellido_usuario}`}</Text>
					<View style={styles.contenido}>
						<Text style={styles.descripcion}>
							Está inscrito como alumno(a) regular en el{" "}
							{constancia.nombre_proyecto}, durante un periodo de{" "}
							{constancia.cantidad_lapso}{" "}
							{constancia.nombre_tp_lapso.toLowerCase()},
							comprendido(s)
							{
								// Validar si la fecha de inicio y fin son iguales
								constancia.fecha_inicio_proyecto === constancia.fecha_fin_proyecto
									? ` el ${moment(
											constancia.fecha_inicio_proyecto
										).format("LL")}`
									: ` desde el ${moment(
											constancia.fecha_inicio_proyecto
										).format("LL")} al ${moment(
											constancia.fecha_fin_proyecto
										).format("LL")}`
							}
							. Con un total de {constancia.hrs_academicas} horas
							académicas
							{parseInt(constancia.creditos) > 1 ? ", " : " y "}
							{constancia.hrs_lectivas} horas lectivas
							{parseInt(constancia.creditos) > 1
								? `, equivalentes a ${constancia.creditos} créditos.`
								: "."}
						</Text>
						<Text style={[styles.descripcion, { marginTop: 10 }]}>
							Se emite el presente documento para los fines
							pertinentes.
						</Text>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default Contancia;

const styles = StyleSheet.create({
	page: {
		position: "relative",
	},
	contenedor: {
		position: "absolute",
		top: "35%",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	fechaSuperior: {
		position: "absolute",
		top: "25px",
		right: "35px",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		justifyContent: "flex-end",
		fontWeight: "bold",
	},
	nombre: {
		fontSize: 20,
		fontFamily: "segoe-bold",
		fontWeight: "bold",
		marginBottom: 50,
		textTransform: "capitalize",
	},
	contenido: {
		marginBottom: 50,
		width: "60%",
		marginBottom: 30,
	},
	descripcion: {
		fontSize: 10,
		fontFamily: "segoe-light",
		textAlign: "center",
	},
	fecha: {
		fontSize: 10,
		fontFamily: "segoe-bold",
		marginTop: 15,
		fontWeight: "bold",
		color: "#2B0462",
	},
});
