import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Spinner, Modal } from "react-bootstrap";
//import { updateStateModalCustom } from "../../../../actions/modalActions";
import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";
//import "./RegistroFactura.scss";

//Redux
import { useDispatch, useSelector } from "react-redux";
import TablaPensionFraccion from "./TablaPensionFraccion";
import { SettingsInputSvideoTwoTone } from "@material-ui/icons";
import { obtenerIDBoletaSiguiente } from "../../../../actions/usuarioActions";
//import { obtenerIDBoletaSiguiente } from "../../../../actions/pagosActions";

const RegistroFactura = ({handleFormSubmit,pension,fraccion=null}) => {
  const dispatch = useDispatch();
  const [idTpMedioPago, setIdTpMedioPago] = useState('');
 /* const { matriculaActiva, todo = {}, detallePago } = useSelector(
    (state) => state.pagos.datosFinanzasDiscente
  );*/
  const [detallePago,setDetallePago]=useState({
      numeroDocumento:null,
      modalidad:pension.id_tp_cuota==="2" ? 'fraccion':'cuota',
      idPensionFraccion:pension.id_tp_cuota !=="2" ? pension.id:fraccion?fraccion.id_fraccion:null,
      loading:false,
    } 
  )
  useEffect(()=>{
    setDetallePago({
      numeroDocumento:null,
      modalidad:pension.id_tp_cuota==="2" ? 'fraccion':'cuota',
      idPensionFraccion:pension.id_tp_cuota !=="2" ? pension.id:fraccion?fraccion.id_fraccion:null,
      loading:false,
    })
  },[fraccion])

  const matriculaActiva = {}
  const todo = {}
  
  const {
    numeroDocumento,
    modalidad,
    idPensionFraccion,
    loading,
  } = detallePago;

  const formik = useFormik({
    initialValues: initialValues(idPensionFraccion, null),
    validationSchema: yup.object(validationSchema(idTpMedioPago)),
    onSubmit: (valores) => {
     // console.log(valores)
      handleFormSubmit(valores, pension.id_matricula_proyecto);
    },
    validateOnBlur: true
  });

  const mostrarOptionsbancos = () => {
    return bancos.map((banco) => (
      <option key={banco.id} value={banco.id}>
        {banco.nombre}
      </option>
    ));
  };

  const {
    observaciones,
    fecha_pago,
    dni_ruc,
    id_tp_banco,
    id_tp_medio_pago,
    id_tp_area,
    nro_tarjeta,
    nro_operacion,
    id_tp_medio_serie
  } = formik.values;



  useEffect(() => {
    const fetchIdBoleta = async ()=>{
        const rpta = await dispatch(obtenerIDBoletaSiguiente("001"))
        //console.log(rpta)
        setDetallePago({
          ...detallePago,
          numeroDocumento:rpta
        })
    }

    fetchIdBoleta();
    formik.values.dni_ruc=pension.dni
  //  dispatch(obtenerIDBoletaSiguiente(id_tp_medio_serie));
  }, []);

  const mostrarCamposVariables = () => {
    if(idTpMedioPago) {
      switch (idTpMedioPago) {
        case '1':
        case '2':
          return <Col md={6}>
            <Form.Group as={Row}>
              <Form.Label column md={5} htmlFor="id_tp_banco">
                Banco
              </Form.Label>
              <Col md={7}>
                <Form.Control
                  id="id_tp_banco"
                  as="select"
                  name="id_tp_banco"
                  value={id_tp_banco}
                  onChange={formik.handleChange}
                  isInvalid={formik.errors.id_tp_banco}
                >
                  <option value="" disabled>
                    --Seleccione--
                  </option>
                  {mostrarOptionsbancos()}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.id_tp_banco}
                </Form.Control.Feedback>
              </Col>
            </Form.Group>
          </Col>
        default:
          break;
      }
    }
  }

  useEffect(() => {
    if (numeroDocumento !== "") {
      formik.values.nro_documento = numeroDocumento;
    }
  }, [numeroDocumento]);

  useEffect(() => {
    if (detallePago.modalidad !== "") {
      formik.values.modalidad = modalidad;
    }
  }, [detallePago]);

  return (
    <div className="RegistroFactura">
      <Form onSubmit={formik.handleSubmit}>
        <fieldset disabled={loading}>
          <Modal.Body>
            <Form.Group as={Row}>
              <Form.Label column md={2}>
                Producto:
              </Form.Label>
              <Col md={10}>
                <p style={{marginTop: '.7rem', marginLeft: '2.2rem'}}>
                  {pension.nombre_proyecto}
                </p>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column md={2}>
                Discente:
              </Form.Label>
              <Col md={10}>
                <p style={{marginTop: '.7rem', marginLeft: '2.2rem'}}>{`${pension.nombre_usuario} ${pension.apellido_usuario}`}</p>
              </Col>
            </Form.Group>
            <Form.Group className={`${pension.id_tp_estado_pago==="1" || pension.id_tp_estado_pago==="4" ?'d-none':''}`} as={Row}>
              <Col md={6}>
                <Form.Group as={Row}>
                  <Form.Label column md={5} htmlFor="dni">
                    DNI
                  </Form.Label>
                  <Col md={7}>
                    <Form.Control
                      id="dni"
                      defaultValue={dni_ruc}
                      type="text"
                      disabled
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column md={5} htmlFor="id_tp_medio_pago">
                    Medio de pago
                  </Form.Label>
                  <Col md={7}>
                    <Form.Control
                      id="id_tp_medio_pago"
                      as="select"
                      name="id_tp_medio_pago"
                      value={id_tp_medio_pago}
                      onChange={e => {
                        formik.setFieldValue('id_tp_medio_pago', e.target.value);
                        setIdTpMedioPago(e.target.value);
                      }}
                      isInvalid={formik.errors.id_tp_medio_pago}
                    >
                      <option value="" disabled>
                        --Seleccione--
                      </option>
                      <option value="4">Tarjeta</option>
                      <option value="1">Banco</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group as={Row} className="d-none">
                  <Form.Label column md={5} htmlFor="id_tp_medio_serie">
                    Serie
                  </Form.Label>
                  <Col md={7}>
                    <Form.Control
                      type='text'
                      id="id_tp_medio_serie"
                      name="id_tp_medio_serie"
                      defaultValue={id_tp_medio_serie}
                      
                      isInvalid={formik.errors.id_tp_medio_serie}
                      disabled
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.id_tp_medio_serie}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column md={5} htmlFor="fecha_pago">
                    Fecha de pago
                  </Form.Label>
                  <Col md={7}>
                    <Form.Control
                      type="text"
                      id="fecha_pago"
                      defaultValue={fecha_pago}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group as={Row} className="d-none">
                  <Form.Label column md={5} htmlFor="nro_documento">
                    N° Comprobante
                  </Form.Label>
                  <Col md={7}>
                    <Form.Control
                      id="nro_documento"
                      type="text"
                      name="nro_documento"
                      disabled
                      defaultValue={numeroDocumento}
                     
                      isInvalid={
                        !numeroDocumento && formik.errors.nro_documento
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.nro_documento}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                
              </Col>
            </Form.Group>
            <Form.Group className={`${pension.id_tp_estado_pago==="1" || pension.id_tp_estado_pago==="4"?'d-none':''}`} as={Row}>
              {mostrarCamposVariables()}
              { idTpMedioPago !== '' ? (
                <Col md={6}>
                  <Form.Group as={Row}>
                    <Form.Label column md={5} htmlFor="nro_operacion">
                      Nro Operación / Pedido 
                    </Form.Label>
                    <Col md={7}>
                      <Form.Control
                        type="text"
                        id="nro_operacion"
                        name="nro_operacion"
                        value={nro_operacion}
                        onChange={formik.handleChange}
                        isInvalid={formik.errors.nro_operacion}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.nro_operacion}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                </Col>
              ) : null}
              <Col md={6}>
                <Form.Group className="d-none" as={Row}>
                  <Form.Label column md={5} htmlFor="id_tp_area">
                    Área
                  </Form.Label>
                  <Col md={7}>
                    <Form.Control
                      as="select"
                      id="id_tp_area"
                      name="id_tp_area"
                      defaultValue={id_tp_area}
                      onChange={formik.handleChange}
                      //isInvalid={formik.errors.id_tp_area}
                    
                    >
                      <option value="" disabled>
                        --Seleccione--
                      </option>
                      <option value="1">Consultorias</option>
                      <option value="2">Ventas</option>
                      <option value="3">Marketing</option>
                      <option value="4">Exp. del Cliente</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.id_tp_area}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column md={5} htmlFor="observaciones">
                    Observaciones
                  </Form.Label>
                  <Col md={7}>
                    <Form.Control
                      id="observaciones"
                      type="text"
                      name="observaciones"
                      value={observaciones}
                      onChange={formik.handleChange}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row}>
                  <Form.Label column md={5} htmlFor="Voucher">
                  Voucher:
                  </Form.Label>
                  <Col md={7}>
                    <Form.Control
                      id="Voucher"
                      type="file"
                      name="archivo"
                      onChange={e => {
                        formik.setFieldValue('archivo', e.target.files[0]);}
                        }
                      isInvalid={formik.errors.archivo}
                    />
                      <Form.Control.Feedback type="invalid">
                      {formik.errors.archivo}
                    </Form.Control.Feedback>
                  </Col>
                </Form.Group>
              </Col>
            </Form.Group>
            {modalidad !== "extraordinario" && (
              <Form.Group>
                <TablaPensionFraccion pension={pension} detallePago={detallePago} />
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer className={`${pension.id_tp_estado_pago==="1" || pension.id_tp_estado_pago==="4" ? 'd-none':''}`}>
           {/* <Button
              variant="dark"
              className="btn-icon"
            >
              <FontAwesomeIcon icon={faTrash} />
              Cancelar
            </Button>*/}
            <Button type="submit" className="btn-icon" variant="info">
              {loading ? (
                <Spinner animation="border" className="m2-1" size="sm" />
              ) : (
                <FontAwesomeIcon className="mr-2" icon={faSave} />
              )}
               Guardar
            </Button>
          </Modal.Footer>
        </fieldset>
      </Form>
    </div>
  );
};

export default RegistroFactura;

const bancos = [
  {
    id: 1,
    nombre: "BCP",
  },
  {
    id: 2,
    nombre: "Scotiabank",
  },
  {
    id: 3,
    nombre: "Banco de la nación",
  },
  {
    id: 4,
    nombre: "Interbanck",
  },
  {
    id: 5,
    nombre: "Citibanck",
  },
  {
    id: 6,
    nombre: "BBVA",
  },
  {
    id: 9,
    nombre: "Caja Municipal (Ica)",
  },
];


const initialValues = (idPensionFraccion, abc) => {
  return {
    id: idPensionFraccion,
    nro_documento: "",
    observaciones: "",
    fecha_pago: moment(new Date()).format("L"),
    dni_ruc: '',
    id_tp_area: '2',
    id_tp_medio_pago: "",
    id_tp_medio_serie: "001",
    id_tp_banco: "",
    nro_operacion: '',
    archivo:{},
    modalidad:""
  }
}
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
  "application/pdf"
];
const validationSchema = (id_tp_medio_pago) => {
  let initialValidation = {
    nro_documento: yup.string().required("Este campo es requerido."),
    id_tp_medio_serie: yup.number().required("Este campo es requerido."),
    id_tp_area: yup.number().required('Este campo es requirido.'),
    id_tp_medio_pago: yup.number().required('Este campo es requerido.'),
    nro_operacion: yup.string().required('Este campo es requerido.'),
    archivo: yup
    .mixed()
    .required("A file is required")
    .test(
      "fileFormat",
      "Unsupported Format",
      value => value && SUPPORTED_FORMATS.includes(value.type)
    )
  }
  switch (id_tp_medio_pago) {
    case '1': //Banco
    case '2':
      initialValidation = {
        ...initialValidation,
        nro_operacion: yup.string().required("Este campo es requerido."),
        id_tp_banco: yup.number().required("Este campo es requerido."),
      }
    break;
    default:
      break;
  }
  return initialValidation;
}
