import React from 'react'
import '../contenidoProyecto.css'
import EventsCalendar from '../../plugins/Horario/EventsCalendar';
//Redux
import {connect} from 'react-redux'
import { useEffect } from 'react';
import { useState } from 'react';

const Horario = (props) => {

    //State
    const {proyecto} = props


    const [eventos, setEventos] = useState([])

    const llenarEventos = () => {
        let dataEventos = [];
        proyecto.Horario.map(evento => {
            dataEventos = [...dataEventos,{
                title: evento.title,
                start: new Date(evento.start),
                end: new Date(evento.end),
                url: evento.url
            }]
        })
        setEventos(dataEventos)
    }

    useEffect(() => {
    if (proyecto !== undefined) {
        if (Object.keys(proyecto).length > 0) {
            llenarEventos()
          }  
    }
      
    }, [proyecto])

    return ( 
        <React.Fragment>
            <div className="content-header header-vista-content">
                <h4 className='font-20 text-uppercase'>
                    Horario de clases
                </h4>
                
            </div>
            <div className="body-calendar my-3">
                <EventsCalendar
                    eventos={eventos}
                />
            </div>
        </React.Fragment>
     );
}

const mapStateToProps = state => ({
    proyecto: state.cursos.proyecto
})

export default connect(mapStateToProps, {})(Horario);