import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './CardContador.scss';

const CardContador = ({titulo = '', color='primary', icono = null, numero = 0}) => {
  return ( 
    <div className="CardContador">
      <div className="CardContador__title">
        {titulo}
      </div>
      <div className="CardContador__body">
        <div className={`icon-big ${color}`}>
          <FontAwesomeIcon icon={icono} />
        </div>
        <div className='numbers'>
          <h2>{numero}</h2>
        </div>
      </div>
    </div>
  );
}
 
export default CardContador;