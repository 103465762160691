import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import FilaCuota from './FilaCuota';
import RegistroFactura from './RegistroFactura';
//import './TablaCuotas.scss';

//Redux
const TablaCuotas = ({pension, handleFormSubmit}) => {
  const cuotas = pension.cuotas
  const [fraccion,setFraccion] = useState(null)
  return ( 
    <>
    <div className="TablaCuotas tableFixHead">
      <div className="box">
        <Table responsive='md'>
          <thead>
            <tr>
              <th>#</th>
              <th>Monto establecido</th>
              <th>Fecha límite</th>
              <th>Estado</th>
              <th className='text-center'>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {cuotas.length ? (
              cuotas.map((cuota, item) => (
                <FilaCuota 
                  key={cuota.id_fraccion}
                  item={item}
                  setFraccion={setFraccion}
                  cuota={cuota}
                />
              ))
            ) : <tr>
              <td colSpan='5' className='text-center'>No hay datos</td>
            </tr>}
          </tbody>
        </Table>
      </div>
    </div>
    { fraccion ? <RegistroFactura pension={pension} handleFormSubmit={handleFormSubmit} fraccion={fraccion} /> : null}
    </>
  );
}
 
export default TablaCuotas;