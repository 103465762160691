import React from 'react';
import {Button, Card} from "react-bootstrap";
import ListarOpcionesExamen from "./ListarOpcionesExamen";
const Pregunta = ({pregunta, handleChange ,opciones, num_pregunta}) => {


  return ( 
 <Card style={{marginBottom:'15px'}}>
  <Card.Body>
        <div className="row" >
          <div className="col-md-10"><h6 className="form-label">{pregunta ? num_pregunta+'.- '+ pregunta.pregunta : 'no hay pregunta'}</h6></div>
          <div className="col-md-2" style={{textAlign:"center"}}><span style={{fontSize:'10px'}}>{/*pregunta.puntaje*/}</span></div>
        </div>
        {pregunta.descripcion!=='' ? <div className="form-text" style={{padding:"5px", marginBottom:"8px", fontSize:'14px'}}>{pregunta.descripcion}</div> : '' }

        <ListarOpcionesExamen opciones={opciones.opciones_obtenidas} handleChange={handleChange} />  
  </Card.Body>
</Card>
  );
}
 
export default Pregunta;