import React from "react";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as moment from "moment";
import "./SesionItem.scss";

const SesionItem = ({ datos }) => {
	const a = moment(datos.fecha_sesion).format("YYYY-MM-DD");
	const b = moment().format("YYYY-MM-DD");
	const disabled = new Date(a) - new Date(b) !== 0 ? "disabled" : "";

	return (
		<div className="row p-3 p-md-2 m-1 sesions-container">
			<div className="col-3 col-md-4 calendar-container flex-column flex-md-row">
				<img
					src="images/icons/calendar-icon.png"
					alt="calendar"
					className="calendar-icon"
				/>
				<span>
					{" "}
					{datos.fecha_sesion}{" "}
				</span>
			</div>
			<div className="col-6 col-md-4 d-flex flex-column align-items-start p-1 details">
				<h6>Clase en vivo</h6>
				<p>{datos.nombre_sesion}</p>
			</div>
			<div className="col-3 col-md-4 sesions-actions">
				<a
					className={`btn btn-primary ${disabled}`}
					href={datos.ruta_archivo}
					target="_blank"
					rel="noopener noreferrer"
				>
					Ir a la clase
				</a>
			</div>
		</div>
	);
};

export default SesionItem;
